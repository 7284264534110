import React, { memo, PropsWithChildren } from "react";

import { IonItem, IonLabel, IonCard, IonIcon, IonNote } from "@ionic/react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { FormField } from "../../../../models/Form";
import { FormValues } from "../../../../models/FormRecord";
import useFieldTemplate from "../hooks/useFieldTemplate";
import DrawingModal from "../components/Drawings/DrawingModal";
import { IDrawingRef } from "interfaces/IDrawingRef";
import ThumbnailOpenSeaDragonViewer from "../components/Drawings/ThumbnailViewer";
import { parseDrawing } from "../components/Drawings/utils/parseDrawing";
import { mapPin } from "assets/icons";

interface IProps {
	field: FormField<IDrawingRef>;
	formMethods: UseFormReturn<FormValues>;
}

type PropsType = IProps;
const Drawings: React.FC<PropsType> = (props) => {
	const { field, formMethods } = props;
	const fieldTemplate = useFieldTemplate(field, formMethods.control);
	const [open, setOpen] = React.useState<boolean>(false);

	const rawValue = useWatch({ name: fieldTemplate.name, control: formMethods.control });
	const value = parseDrawing(rawValue);

	const drawingReference = value?.ref ?? null;
	const image = (field.choices || []).find((choice) => choice.value === drawingReference);
	const imageFilepath = ("drawings/" + image?.filepath) as string;
	const imageLabel = image?.label;

	return (
		<div
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{fieldTemplate.isRelevant() ? (
				<IonItem lines="none">
					<IonLabel className="ion-text-wrap capitalize-first-letter" position="stacked" mode="ios">
						{field.label}
						{(fieldTemplate.isRequired() || fieldTemplate.isHardRequired()) && (
							<span style={{ color: "red" }}>&nbsp;*</span>
						)}
					</IonLabel>
					<IonNote>{imageLabel}</IonNote>
					{value?.ref !== undefined && value?.ref !== null ? (
						<div>
							<IonCard
								type={"button"}
								mode="md"
								style={{
									objectFit: "contain",
									margin: "0 0 1rem 0",
									height: "15rem",
									cursor: "pointer",
									boxShadow: "none",
									border: "1px solid var(--ion-color-gray200)",
									borderRadius: "4px",
								}}
								onClick={() => {
									setOpen(true);
								}}
							>
								<ThumbnailOpenSeaDragonViewer image={imageFilepath} value={value} />
							</IonCard>
						</div>
					) : (
						<div className="gallery">
							<IonCard
								mode="md"
								className="filePromptButton"
								type={"button"}
								color={"#EFEFEF"}
								onClick={() => {
									setOpen(true);
								}}
							>
								<IonIcon
									style={{
										fontSize: "2rem",
										strokeWidth: "1.5px",
										color: "#9B9B9B",
										display: "block",
									}}
									icon={mapPin}
								/>
							</IonCard>
						</div>
					)}
					<DrawingModal
						open={open}
						setOpen={setOpen}
						field={field}
						formMethods={formMethods}
						fieldTemplate={fieldTemplate}
					/>
				</IonItem>
			) : null}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Drawings, propsAreEqual);
export default Memoized;
