import React, { useState, useEffect } from "react";

import {
	IonContent,
	IonPage,
	IonIcon,
	IonButton,
	IonList,
	IonItem,
	IonLabel,
	useIonLoading,
	IonChip,
	IonListHeader,
	IonToggle,
	useIonToast,
} from "@ionic/react";
import { StoreNames } from "idb";
import { download, cog, trash } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import Header from "../../components/common/Header";
import config from "../../config";
import { dbPromise, FormsDB } from "../../services/idb";
import { deleteOldRecords, estimateStorageQuota, QuotaInfo } from "../../utils/storageUtils";
import LoadJSONModal from "./modals/LoadJSONModal";
import ConfirmDeleteLocal from "./modals/ConfirmDeleteLocal";
import StorageDetails from "./StorageDetails";
import { handleUrlDownload } from "utils/download/handleDownload";
import { urlBase64ToUint8Array } from "utils/webpush/utils";
import { deleteSubscription, postSubscription } from "api/webpush";

const AdminView: React.FC = () => {
	const [presentLoading, dismissLoading] = useIonLoading();
	const [presentToast] = useIonToast();
	const { t, i18n } = useTranslation();
	const [showStorageDetails, setShowStorageDetails] = useState(false);
	const [showAdvanced, setShowAdvanced] = useState(false);
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [isSubscribed, setIsSubscribed] = useState(false);
	const [openLoadJsonModal, setOpenLoadJsonModal] = useState(false);
	const [quotaEstimation, setQuotaEstimation] = useState<QuotaInfo>({
		quota: undefined,
		usage: undefined,
	});
	const supportsNotifications = "serviceWorker" in navigator && "PushManager" in window;

	useEffect(() => {
		estimateStorageQuota().then(setQuotaEstimation);
		if (supportsNotifications)
			navigator.serviceWorker.ready.then((reg) => {
				reg.pushManager
					.getSubscription()
					.then((sub) => (sub ? true : false))
					.catch(() => false)
					.then(setIsSubscribed);
			});
	}, []);

	const updateServiceWorker = async () => {
		// Check if we need to login in CloudFlare Access before anything else
		fetch("/service-worker.js").then((res) => {
			console.log("DEBUGGING", res);
			if (res.redirected && res.url.startsWith("https://arup.cloudflareaccess.com")) {
				console.info("Redirecting to cloudflare to get access to the new service worker");
				// This is a bit of a hack:
				// Underscore-prefixed routes are ignored by the service worker (see service-worker.js)
				// this will force the browser to go to the source (cloudflare). Since there's no auth cookie,
				// It'll prompt the user to log in against CloudFlare Access, and get you back to
				// the origin at the "_" route (which is ignored by the router).
				// There's probably a cleaner way to achieve this but this is not too bad
				window.location.replace("/_");
			}
		});
		// Update service worker
		navigator.serviceWorker
			.register("/service-worker.js")
			.then((reg) => {
				reg
					.update()
					.then(() => {
						console.info("Service worker has been successfully updated");
					})
					.catch((err) => console.warn("Error updating worker", err));
			})
			.catch((err) => console.warn("Error registering service worker", err));
	};

	const downloadFile = async (content: string, filename: string) => {
		const blob = new Blob([content], { type: "application/json" });
		const href = await URL.createObjectURL(blob);
		handleUrlDownload(href, filename);
	};

	const dumpLocalData = async () => {
		presentLoading({
			message: i18n.format(t("saving_data"), "capitalize"),
		});
		const stores: StoreNames<FormsDB>[] = ["projects", "assets", "records", "images", "forms"];
		const timestamp = new Date().getTime();
		const data: Record<string, unknown> = {};
		for (const key of stores) {
			const dump = await (await dbPromise).getAll(key);
			data[key] = dump;
		}
		const fileName = `${timestamp}-dhub-forms.json`;
		await downloadFile(JSON.stringify(data), fileName);
		dismissLoading();
	};

	const toggleNotifications = async () => {
		const askPermission = () => {
			return new Promise(function (resolve, reject) {
				const permissionResult = Notification.requestPermission(function (result) {
					resolve(result);
				});

				if (permissionResult) {
					permissionResult.then(resolve, reject);
				}
			}).then(function (permissionResult) {
				if (permissionResult !== "granted") {
					throw new Error("We weren't granted permission.");
				}
			});
		};

		const subscribeUserToPush = () => {
			return navigator.serviceWorker
				.register("/service-worker.js")
				.then((registration) => {
					const subscribeOptions = {
						userVisibleOnly: true,
						applicationServerKey: urlBase64ToUint8Array(config.VAPID_PUBLIC_KEY),
					};

					return registration.pushManager.subscribe(subscribeOptions);
				})
				.then((pushSubscription) => {
					console.log("Received PushSubscription: ", JSON.stringify(pushSubscription));
					return pushSubscription;
				})
				.then((pushSubscription) =>
					postSubscription(JSON.parse(JSON.stringify(pushSubscription)))
						.then(() => console.log("Succesfully stored subscription in remote server"))
						.catch(() => console.error("Error storing subscription in remote server")),
				)
				.catch((err) => `Error suscribing: ${err}`);
		};

		const unsubscribeUserToPush = () => {
			return navigator.serviceWorker
				.register("/service-worker.js")
				.then((registration) => {
					return registration.pushManager.getSubscription();
				})
				.then((pushSubscription) => {
					pushSubscription?.unsubscribe().then(() => {
						if (!pushSubscription) return;
						const subscriptionId = pushSubscription.endpoint.split("/").slice(-1)[0];
						deleteSubscription(subscriptionId)
							.then(() => console.log("Succesfully deleted subscription in remote server"))
							.catch(() => console.error("Error deleting subscription in remote server"));
					});
					return pushSubscription;
				})
				.catch((err) => `Error unsubscribing: ${err}`);
		};

		await askPermission();
		if (isSubscribed) {
			unsubscribeUserToPush().then(() => setIsSubscribed(false));
		} else {
			subscribeUserToPush().then(() => setIsSubscribed(true));
		}
	};

	return (
		<IonPage>
			<Header title="Admin Panel" backUrl="/" />
			<IonContent forceOverscroll={false}>
				<IonList>
					<IonListHeader lines="full">
						<IonLabel>{i18n.format(t("about_this"), "capitalize")}</IonLabel>
					</IonListHeader>
					<IonItem lines="none">
						<IonLabel>{i18n.format(t("origin"), "capitalize")}</IonLabel>
						<IonChip>{window.location.origin}</IonChip>
					</IonItem>
					<IonItem lines="none">
						<IonLabel>{i18n.format("API", "capitalize")}</IonLabel>
						<IonChip>{config.API}</IonChip>
					</IonItem>
					<IonItem lines="none">
						<IonLabel>{i18n.format(t("version"), "capitalize")}</IonLabel>
						<IonChip>{config.RELEASE}</IonChip>
					</IonItem>
					<IonItem lines="none">
						<IonLabel>{i18n.format(t("storage_usage"), "capitalizeEveryWord")}</IonLabel>
						<IonChip>{quotaEstimation.usage ? `${(quotaEstimation.usage / 1e6).toFixed(0)}Mb` : undefined}</IonChip>
					</IonItem>
					{supportsNotifications && (
						<IonItem>
							<IonToggle onIonChange={toggleNotifications} checked={isSubscribed}>
								{i18n.format(t("toggleNotifications"), "capitalize")}
							</IonToggle>
						</IonItem>
					)}
					<IonItem>
						<IonToggle onIonChange={() => setShowStorageDetails((it) => !it)}>
							{i18n.format(t("showStorageSummary"), "capitalize")}
						</IonToggle>
					</IonItem>
					<IonItem>
						<IonToggle onIonChange={() => setShowAdvanced((it) => !it)}>
							{i18n.format(t("showAdvancedActions"), "capitalize")}
						</IonToggle>
					</IonItem>
					<IonButton onClick={updateServiceWorker} expand="block" style={{ margin: "1rem" }}>
						{i18n.format(t("trigger_update"), "capitalize")}
						<IonIcon slot="end" src={cog} />
					</IonButton>
				</IonList>
				{showStorageDetails && <StorageDetails />}
				{showAdvanced && (
					<IonList>
						<IonListHeader lines="full">
							<IonLabel>{i18n.format(t("advanced_options"), "capitalize")}</IonLabel>
						</IonListHeader>
						<IonButton onClick={() => setOpenLoadJsonModal(true)} expand="block" style={{ margin: "1rem" }}>
							{i18n.format(t("load_local_json"), "capitalize")}
							<IonIcon slot="end" src={download} style={{ transform: "rotate(180deg)" }} />
						</IonButton>
						<IonButton onClick={dumpLocalData} expand="block" style={{ margin: "1rem" }}>
							{i18n.format(t("download_local"), "capitalize")}
							<IonIcon slot="end" src={download} />
						</IonButton>
						<IonButton
							expand="block"
							onClick={() =>
								deleteOldRecords().then((msg) =>
									presentToast({ message: msg, duration: 3000, position: "top", buttons: [{ text: "Ok" }] }),
								)
							}
							style={{ margin: "1rem" }}
						>
							{i18n.format(t("delete_unused"), "capitalize")}
							<IonIcon slot="end" src={trash} />
						</IonButton>
						<IonButton expand="block" onClick={() => setIsOpenDeleteModal(true)} style={{ margin: "1rem" }}>
							{i18n.format(t("delete_local"), "capitalize")}
							<IonIcon slot="end" src={trash} />
						</IonButton>
					</IonList>
				)}
			</IonContent>
			{/* Sheet Modal */}
			<ConfirmDeleteLocal isOpen={isOpenDeleteModal} setIsOpen={setIsOpenDeleteModal} />
			<LoadJSONModal isOpen={openLoadJsonModal} setIsOpen={setOpenLoadJsonModal} />
		</IonPage>
	);
};

export default AdminView;
