import { IDrawingRef } from "interfaces/IDrawingRef";
import React, { useEffect, useRef, useState } from "react";
import { buildMapPin } from "./utils/buildMapPin";

interface IProps {
	image?: string;
	value: IDrawingRef;
}

type PropsType = IProps;
const ThumbnailViewer: React.FC<PropsType> = (props) => {
	const { image, value } = props;
	const point: [number, number] | undefined = (value as IDrawingRef)?.geojson?.geometry?.coordinates ?? undefined;
	const srcImage = window.location.hostname === "localhost" ? `${origin}/placeholder.png` : `${origin}/${image}`;
	const imgRef = useRef<HTMLImageElement>(null);
	const [aspectRatio, setAspectRatio] = useState<number | null>(null);

	const handleImageLoad = () => {
		const imgElement = imgRef.current;
		if (imgElement && imgElement.naturalWidth && imgElement.naturalHeight) {
			setAspectRatio(imgElement.naturalHeight / imgElement.naturalWidth);
		}
	};

	useEffect(() => {
		const imgElement = imgRef.current;
		if (imgElement) {
			imgElement.addEventListener("load", handleImageLoad);
			return () => imgElement.removeEventListener("load", handleImageLoad);
		}
	}, []);

	useEffect(() => {
		if (imgRef.current && point) {
			const element = buildMapPin("thumbnailPoint", "12px");
			element.style.position = "absolute";
			element.style.left = `calc(${point[0] * 100}%)`;
			element.style.top = `calc(${aspectRatio ? (point[1] * 100) / aspectRatio : 0}%)`;
			element.style.transform = "translate(-50%,-100%)";
			element.style.position = "absolute";
			document.getElementById("thumbnailPoint")?.remove();
			document.getElementById("image-container")?.appendChild(element);
		}
	}, [imgRef, point, aspectRatio]);

	return (
		<div
			id="image-container"
			style={{
				height: "100%",
				width: "100%",
			}}
		>
			<img
				id="image-viewer"
				loading="lazy"
				style={{
					height: "100%",
					width: "100%",
				}}
				ref={imgRef}
				src={srcImage}
				alt={""}
			></img>
		</div>
	);
};
export default ThumbnailViewer;
