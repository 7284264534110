import React from "react";
import { IonItem, IonLabel } from "@ionic/react";

import { FormField } from "models/Form";
import { IDataValue } from "models/FormRecord";

interface ReportTextProps {
	formField: FormField;
	value: IDataValue;
	highlight?: boolean;
}
export const ReportText: React.FC<ReportTextProps> = (props: ReportTextProps) => {
	const { value, formField, highlight = false } = props;

	const capitalizeFirstWord = (text: string) => `${text.slice(0, 1).toUpperCase()}${text.slice(1)}`;

	const getLabelFromChoices = (value: string) => {
		if (!formField.choices) return value;
		const label = formField.choices.find((ch) => ch.value === value)?.label;
		return label ?? value;
	};

	const getValueAsString = (value: IDataValue) => {
		return Array.isArray(value)
			? value
					.map((v) => v.toString())
					.map(getLabelFromChoices)
					.join(", ")
			: getLabelFromChoices(value?.toString() || "");
	};

	return (
		<div className="no-break" id={`field-${formField.name}`}>
			<IonItem lines="none">
				<IonLabel className="ion-text-wrap" position="stacked" mode="ios">
					<div style={{ color: "var(--ion-color-medium)" }}>{formField.label}</div>
					<div className={highlight ? "highlighted" : undefined}>{capitalizeFirstWord(getValueAsString(value))}</div>
				</IonLabel>
			</IonItem>
		</div>
	);
};
