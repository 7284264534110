const buildFilePath = (
	projectRef = "unknown",
	assetId = "unknown",
	recordId = "unknown",
	idx = 0,
	extension?: string,
) => {
	const prefix = [projectRef, assetId, recordId].join("/");
	const timestamp = new Date().getTime();
	const name = `${prefix}/${timestamp}${idx}`;
	return extension ? `${name}.${extension}` : prefix;
};

export default buildFilePath;
