import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import esTranslations from "assets/locales/es/translations.json";
import enTranslations from "assets/locales/en/translations.json";

i18n.use(initReactI18next).init({
	partialBundledLanguages: true,
	ns: ["translations"],
	fallbackLng: ["en", "es"].includes(navigator.language.split("-")[0]) ? navigator.language.split("-")[0] : "en",
	react: {
		useSuspense: true,
	},
	interpolation: {
		format: function (value, format) {
			if (format === "uppercase") return value.toUpperCase();
			if (format === "lowercase") return value.toLowerCase();
			if (format === "capitalize") return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
			if (format === "capitalizeEveryWord") {
				const result = value.split(" ").map((word: string) => {
					return word.substr(0, 1).toUpperCase() + word.substr(1);
				});
				return result.join(" ");
			}
			return value;
		},
	},
});

i18n.addResourceBundle("es", "translations", esTranslations);
i18n.addResourceBundle("en", "translations", enTranslations);

export default i18n;
