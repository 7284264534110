import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormValues, IDataValue } from "models/FormRecord";

export interface FilterSlice {
	status?: string[];
	tecnico?: string[];
	discardedOnly: boolean;
	dataFilters: FormValues<IDataValue>;
}

const assetFilterInitialState: FilterSlice = {
	status: undefined,
	tecnico: undefined,
	discardedOnly: false,
	dataFilters: {},
};

export const assetFilterSlice = createSlice({
	name: "assetFilter",
	initialState: assetFilterInitialState,
	reducers: {
		setDataFilters: (_, action: PayloadAction<FilterSlice>) => {
			return action.payload;
		},
		reset: () => {
			return assetFilterInitialState;
		},
	},
});

export const actions = assetFilterSlice.actions;

export default assetFilterSlice.reducer;
