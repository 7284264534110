import axios, { AxiosProgressEvent } from "axios";

import { apiClient } from "api";
import LocalFile from "models/LocalFile";

import { chunkGenerator } from "../utils/chunk";

export const uploadFiles = async (
	files: LocalFile[],
	makeProgress?: (bytes: number, total?: number, id?: string) => void,
	signal?: AbortSignal,
) => {
	const signedUrls = await getUploadSignedUrls(
		files.map((file) => ({ filepath: file.filepath, type: file.file?.type })),
	);
	for (const chunk of chunkGenerator(files, 20))
		await Promise.all(
			chunk.map((file) => {
				const signedUrl = signedUrls[file.filepath];
				// Pre-checks
				if (!signedUrl) throw new Error(`Invalid signed url ${signedUrl} for file ${file.filepath}`);
				if (!file.file) return;
				// Save blob to corresponding signed url
				const headers: Record<string, string> = { "Content-Type": file.file.type };
				return axios.put(signedUrl, file.file, {
					headers,
					signal,
					onUploadProgress: (progressEvent: AxiosProgressEvent) =>
						makeProgress && makeProgress(progressEvent.loaded, progressEvent.total, file.filepath),
				});
			}),
		);
};

interface SignedURLRequest {
	filepath: string;
	contentType?: string;
}
const getUploadSignedUrls = async (requested: SignedURLRequest[]) => {
	return await apiClient.post<{ [filepath: string]: string }>(`forms/putSignedUrls`, requested).then((res) => {
		if (Object.values(res.data).length !== requested.length) {
			throw new Error("Unexpected number of signedUrls retrieved");
		}
		return res.data;
	});
};
