import React from "react";

import { IonBadge, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";
import { UseFormReturn } from "react-hook-form";

import { chevronRight } from "assets/icons";
import { FormField } from "../../../../models/Form";
import { FormValues } from "../../../../models/FormRecord";
import { useAppDispatch } from "../../../../store";
import { actions as historyActions } from "../../../../store/slices/history";
import useFieldTemplate from "../hooks/useFieldTemplate";
import { useGroupFilledIn } from "../hooks/useGroupFilledIn";

interface Props {
	field: FormField<FormValues[]>;
	formMethods: UseFormReturn<FormValues>;
	parentInlineIds?: string[];
	siblingsAreFirstLevelGroups?: boolean;
}

const calcNumberOfRepeatables = (formMethods: UseFormReturn<FormValues>, absPath: string) => {
	const fetchValues = formMethods.getValues(absPath);
	if (fetchValues && Array.isArray(fetchValues)) {
		return (fetchValues as FormValues[]).filter((it) => it && it.id && !it._is_deleted).length;
	} else {
		return 0;
	}
};

export const RepeatableGroup: React.FC<Props> = (props: Props) => {
	const { field, formMethods, parentInlineIds = [] } = props;

	const dispatch = useAppDispatch();
	const { name, isRelevant, isDisabled } = useFieldTemplate(field, formMethods.control);

	const numberOfRepeatables = calcNumberOfRepeatables(formMethods, name);
	const allRequiredValuesFilled = useGroupFilledIn(field, formMethods);

	return isRelevant() ? (
		<React.Fragment>
			<IonItem
				className="Group"
				id={`field-${field.name}`}
				button={true}
				detail={false}
				disabled={isDisabled()}
				style={{
					backgroundColor: "var(--ion-color-secondary)",
					"--background": "var(--ion-color-secondary)",
					borderBottom: "1px solid #CBD5E0",
				}}
				lines="none"
				onClick={() => {
					if (parentInlineIds.length) {
						dispatch(historyActions.pushHistoryMany([...parentInlineIds, field.name]));
					} else dispatch(historyActions.pushHistory(field.name));
				}}
			>
				<IonLabel
					className="ion-text-wrap"
					style={{
						fontSize: "0.875rem",
						lineHeight: "1rem",
					}}
				>
					{field.label}
				</IonLabel>

				<IonBadge mode="ios" color="medium" slot="end" style={{ fontWeight: "normal", marginInline: "4px" }}>
					{numberOfRepeatables}
				</IonBadge>
				<IonIcon
					src={allRequiredValuesFilled ? checkmarkCircleOutline : ellipseOutline}
					slot="end"
					style={{
						color: allRequiredValuesFilled ? "var(--ion-color-success)" : "var(--ion-color-medium-tint)",
					}}
				/>
				<IonIcon
					src={chevronRight}
					slot="end"
					size="small"
					style={{
						color: "black",
						marginRight: "0",
						marginLeft: "10px",
					}}
				/>
			</IonItem>
		</React.Fragment>
	) : (
		<React.Fragment />
	);
};
