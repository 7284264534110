import { Auth } from "@aws-amplify/auth";
import axios, { AxiosPromise, InternalAxiosRequestConfig } from "axios";

import config from "config";

export const apiClient = axios.create({
	baseURL: config.API,
	params: {},
	headers: {},
});

const cognitoInterceptor = async (request: InternalAxiosRequestConfig) => {
	const session = await Auth.currentSession().catch(() => undefined);
	if (request.headers && session) request.headers["Authorization"] = `Bearer ${session.getIdToken().getJwtToken()}`;
	return request;
};

console.log("Adding default cognito interceptor");
apiClient.interceptors.request.use(cognitoInterceptor);

export type CustomAxiosRequest<I, O> = (args: I) => AxiosPromise<O>;
