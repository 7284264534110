const walk = (obj: object, rootKey = "") => {
	let results: Record<string, unknown> = {};
	for (const [key, value] of Object.entries(obj)) {
		const path = rootKey ? rootKey + "." + key : key;
		if (typeof value !== "object") {
			results[path] = value;
		} else if (value !== null) {
			results = { ...results, ...walk(value, path) };
		}
	}
	return results;
};

const flattenObject = (obj: object) => {
	if (!(typeof obj === "object")) return obj;
	return walk(obj);
};

export default flattenObject;
