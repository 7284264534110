import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const connectionSlice = createSlice({
	name: "connection",
	initialState: { isOnline: false },
	reducers: {
		setOnlineState: (state, action: PayloadAction<boolean>) => {
			state.isOnline = action.payload;
		},
	},
});

export const actions = connectionSlice.actions;

export default connectionSlice.reducer;
