import React, { memo, PropsWithChildren } from "react";

import { IonItem, IonLabel } from "@ionic/react";
import { UseFormReturn } from "react-hook-form";

import { IFile } from "../../../../interfaces/IFile";
import { FormField } from "../../../../models/Form";
import { FormValues } from "../../../../models/FormRecord";
import useFieldTemplate from "../hooks/useFieldTemplate";
import FilePrompt from "../components/FilePrompt/FilePrompt";
import Gallery from "../components/Gallery";

interface IProps {
	field: FormField<IFile[]>;
	formMethods: UseFormReturn<FormValues>;
}

type PropsType = IProps;
const Files: React.FC<PropsType> = (props) => {
	const { field, formMethods } = props;
	const { isRequired, isRelevant, isHardRequired, isFrozen, isDisabled } = useFieldTemplate(field, formMethods.control);

	return (
		<div
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{isRelevant() ? (
				<IonItem lines="none">
					<IonLabel
						className="ion-text-wrap capitalize-first-letter"
						position="stacked"
						mode="ios"
						style={{
							opacity: isFrozen || isDisabled() ? 0.3 : 1,
						}}
					>
						{field.label}
						{(isRequired() || isHardRequired()) && <span style={{ color: "red" }}>&nbsp;*</span>}
					</IonLabel>
					<Gallery field={field} formMethods={formMethods} variant="file">
						{!isFrozen && !isDisabled() && <FilePrompt field={field} formMethods={formMethods} />}
					</Gallery>
				</IonItem>
			) : null}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Files, propsAreEqual);
export default Memoized;
