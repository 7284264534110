import React, { useState, useEffect } from "react";

import { IonIcon, IonCard } from "@ionic/react";
import { brushOutline } from "ionicons/icons";
import { Controller, UseFormReturn } from "react-hook-form";

import { IImage } from "../../../../../interfaces/IImage";
import { FormField } from "../../../../../models/Form";
import { FormValues } from "../../../../../models/FormRecord";
import useFieldTemplate from "../../hooks/useFieldTemplate";
import SketchCanvasModal from "./SketchCanvasModal";

interface IProps {
	field: FormField<IImage[]>;
	formMethods: UseFormReturn<FormValues>;
}

const SketchCanvas: React.FC<IProps> = (props) => {
	const { field, formMethods } = props;
	const { name } = useFieldTemplate(field, formMethods.control);
	const [open, setOpen] = useState(false);
	const [newSketch, setNewSketch] = useState<IImage>();

	useEffect(() => {
		if (!newSketch) return;
		const current = formMethods.getValues()[name] as IImage[] | undefined;
		formMethods.setValue(name, [...(current || []), newSketch], {
			shouldTouch: true,
			shouldDirty: true,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newSketch?.filepath]);

	return (
		<>
			<Controller
				name={name}
				control={formMethods.control}
				rules={{}}
				render={() => {
					return (
						<IonCard
							className="cameraButton"
							type={"button"}
							color={"#EFEFEF"}
							onClick={() => {
								setOpen(true);
							}}
						>
							<IonIcon
								style={{
									fontSize: "2rem",
									color: "#9B9B9B",
									display: "block",
								}}
								icon={brushOutline}
							/>
						</IonCard>
					);
				}}
			/>
			<SketchCanvasModal open={open} setOpen={setOpen} setOnSave={setNewSketch} />
		</>
	);
};
export default SketchCanvas;
