import * as React from "react";

const SvgComponent = (props) => (
	<svg
		width={650.161}
		height={591.317}
		viewBox="0 0 172.022 156.453"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<defs>
			<linearGradient id="a">
				<stop
					style={{
						stopColor: "#d04223",
						stopOpacity: 1,
					}}
					offset={0}
				/>
				<stop
					style={{
						stopColor: "#d44a27",
						stopOpacity: 1,
					}}
					offset={1}
				/>
			</linearGradient>
			<linearGradient
				xlinkHref="#a"
				id="b"
				gradientUnits="userSpaceOnUse"
				x1={110.829}
				y1={729.648}
				x2={110.829}
				y2={596.074}
				gradientTransform="matrix(0 -.70582 .70582 0 -492.157 131.62)"
			/>
		</defs>
		<g
			transform="matrix(.70582 0 0 .70582 72.456 -381.832)"
			style={{
				stroke: "none",
				fill: "#fff",
				fillOpacity: 1,
			}}
		>
			<title>{"Rectangle.307"}</title>
			<path
				className="st1"
				style={{
					fill: "#fff",
					stroke: "none",
					strokeWidth: 0.25,
					fillOpacity: 1,
				}}
				d="M0 569.896h138.075v159.75H0z"
			/>
		</g>
		<g
			transform="matrix(.70582 0 0 .70582 63.12 -378.933)"
			style={{
				stroke: "none",
				fill: "#d24625",
				fillOpacity: 1,
			}}
		>
			<title>{"rect307.48"}</title>
			<path
				d="M9.48 560.69c-5.25 0-9.48 3.99-9.48 8.96v151.04c0 4.96 4.23 8.96 9.48 8.96h135.33c5.25 0 9.48-4 9.48-8.96V569.65c0-4.97-4.23-8.96-9.48-8.96zm-1.65 7.94h138.63v152.89l-139.17.18.29-153.59Z"
				className="st6"
				style={{
					fill: "#d24625",
					stroke: "none",
					strokeWidth: 0.683161,
					strokeLinecap: "round",
					strokeLinejoin: "round",
					fillOpacity: 1,
				}}
			/>
		</g>
		<g
			transform="matrix(.70582 0 0 .70582 77.33 -414.352)"
			style={{
				stroke: "none",
				fill: "#d24625",
				fillOpacity: 1,
			}}
		>
			<title>{"Rectangle.12"}</title>
			<path
				className="st7"
				style={{
					fill: "#d24625",
					stroke: "none",
					strokeWidth: 0.25,
					fillOpacity: 1,
				}}
				d="M0 719.538h97.416v10.108H0z"
			/>
		</g>
		<g
			transform="matrix(.70582 0 0 .70582 77.33 -397.534)"
			style={{
				stroke: "none",
				fill: "#d24625",
				fillOpacity: 1,
			}}
		>
			<title>{"Rectangle.13"}</title>
			<path
				className="st7"
				style={{
					fill: "#d24625",
					stroke: "none",
					strokeWidth: 0.25,
					fillOpacity: 1,
				}}
				d="M0 719.538h97.416v10.108H0z"
			/>
		</g>
		<g
			transform="matrix(.70582 0 0 .70582 89.06 -430.883)"
			style={{
				stroke: "none",
				fill: "#d24625",
				fillOpacity: 1,
			}}
		>
			<title>{"Sheet.60"}</title>
			<g
				transform="translate(4.642 -4.087)"
				style={{
					stroke: "none",
					fill: "#d24625",
					fillOpacity: 1,
				}}
			>
				<title>{"Pie Slice"}</title>
				<path
					d="M32.66 696.99h32.66a32.659 32.659-180 0 0-32.66-32.66Z"
					className="st7"
					style={{
						fill: "#d24625",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
				/>
			</g>
			<g
				style={{
					stroke: "none",
					fill: "#d24625",
					fillOpacity: 1,
				}}
			>
				<title>{"Pie Slice.59"}</title>
				<path
					d="M32.66 696.99v-32.66a32.659 32.659-180 1 0 32.66 32.66z"
					className="st7"
					style={{
						fill: "#d24625",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
				/>
			</g>
		</g>
		<path
			d="M22.847 131.62V-24.831L-71.437-9.19v125.164Z"
			className="st25"
			style={{
				fill: "#d24625",
				fillOpacity: 1,
				stroke: "none",
				strokeWidth: 0.176456,
			}}
			transform="translate(71.437 24.832)"
		/>
		<g
			transform="matrix(.70582 0 0 .70582 31.144 -409.65)"
			style={{
				fill: "#fff",
				fillOpacity: 1,
				stroke: "none",
			}}
		>
			<title>{"Sheet.277"}</title>
			<path
				d="M0 728.82v-74.27l25.71-1.43c8.321-.268 12.2 2.554 16.04 8.044 3.35 4.8 4.35 11.706 3.83 17.746-1.11 12.76-9 23.78-20.87 24.38H13.88v-13.718h7.22c5.175-.229 9.055-5.871 8.807-11.731-.223-6.61-3.698-10.53-8.401-10.696l-7.626.045.01 62.46z"
				className="st24"
				style={{
					fill: "#fff",
					fillOpacity: 1,
					stroke: "none",
					strokeWidth: 0.75,
				}}
			/>
		</g>
	</svg>
);

export default SvgComponent;
