import { IonIcon, IonItem, IonLabel, IonRouterLink } from "@ionic/react";
import useFieldTemplate from "components/common/Form/hooks/useFieldTemplate";
import { linkOutline } from "ionicons/icons";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import React from "react";
import { UseFormReturn } from "react-hook-form";

interface LinkProps {
	field: FormField<string>;
	formMethods: UseFormReturn<FormValues>;
}

export const Link: React.FC<LinkProps> = (props) => {
	const { field, formMethods } = props;

	const { isRelevant } = useFieldTemplate(field, formMethods.control);

	return (
		<>
			{isRelevant() && (
				<div
					className="Link"
					id={`field-${field.name}`}
					style={{
						backgroundColor: "#f9f9f9",
					}}
				>
					<IonRouterLink
						data-testid={`${field.type}:value`}
						href={field.label} // TODO: tremenda chapuza...
						target={"_blank"}
						style={{ display: "flex" }}
					>
						<IonItem lines="none">
							<IonIcon src={linkOutline} color="dark" style={{ marginRight: ".5rem" }} />
							<IonLabel class="ion-text-wrap" color="dark" style={{ fontWeight: "600", textDecoration: "underline" }}>
								{
									// TODO: tremenda chapuza...
									field.shortLabel
								}
							</IonLabel>
						</IonItem>
					</IonRouterLink>
				</div>
			)}
		</>
	);
};
