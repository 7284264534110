import { dbPromise } from "../services/idb";

export type Expression = string;

interface IRawLocalImage {
	filepath: string;
	formpath: string;
	base64?: string;
	file?: File;
	size: number;
	record_id: string;
	asset_id: string;
	project_ref: string;
}
export default class LocalImage implements IRawLocalImage {
	filepath: string;
	formpath: string;
	base64?: string;
	file?: File;
	size: number;
	record_id: string;
	asset_id: string;
	project_ref: string;

	constructor(localImage: IRawLocalImage) {
		this.filepath = localImage.filepath;
		this.formpath = localImage.formpath;
		this.base64 = localImage.base64;
		this.file = localImage.file;
		this.size = localImage.size;
		this.record_id = localImage.record_id;
		this.asset_id = localImage.asset_id;
		this.project_ref = localImage.project_ref;
	}

	static async getAllKeys() {
		return await (await dbPromise).getAllKeys("images");
	}

	static async keysByRecordId(record_id: string) {
		return await (await dbPromise).getAllKeysFromIndex("images", "record_id", record_id);
	}

	static async get(id: string) {
		return await (await dbPromise).get("images", id).then((r) => {
			if (r === undefined) throw new Error(`Key ${id} not found`);
			return new LocalImage(r);
		});
	}

	static async getMany(ids: string[]) {
		const tx = (await dbPromise).transaction("images", "readwrite");
		const store = tx.objectStore("images");
		const data = await Promise.all(ids.map((id) => store.get(id)));
		await tx.done;
		return data.filter((it): it is LocalImage => !it);
	}

	static async byRecordId(record_id: string): Promise<LocalImage[]> {
		// https://stackoverflow.com/questions/61090349/indexeddb-doesnt-get-the-blobs-correctly-if-accessing-from-an-index-webkitblob
		// const images = await (await dbPromise).getAllFromIndex("images", "record_id", record_id);
		return (await (await dbPromise).getAll("images"))
			.filter((it) => it.record_id === record_id)
			.map((ri) => new LocalImage(ri));
	}


	static async getAll() {
		return await (await dbPromise).getAll("images").then((r) => r.map((ri) => new LocalImage(ri)));
	}

	static async set(data: LocalImage) {
		await (await dbPromise).put("images", data);
		return new LocalImage(data);
	}

	static async setMany(data: LocalImage[]) {
		const tx = (await dbPromise).transaction("images", "readwrite");
		const store = tx.objectStore("images");
		await Promise.all(data.map((it) => store.put(it)));
		await tx.done;
	}

	static async delete(data: Pick<LocalImage, "filepath">) {
		return (await dbPromise).delete("images", data.filepath);
	}

	static async deleteMany(data: Pick<LocalImage, "filepath">[]) {
		return await Promise.all(data.map((img) => LocalImage.delete(img)));
	}

	delete() {
		return LocalImage.delete(this);
	}

	save() {
		return LocalImage.set(this);
	}
}
