import React, { useState, useEffect } from "react";
import { IonIcon, IonItem, IonLabel, IonList, IonRouterLink } from "@ionic/react";
import { linkOutline } from "ionicons/icons";

import { FormLinkProps, getFormLinks } from "api/links";

export const LinkList: React.FC<{
	assetId: string;
}> = (props) => {
	const { assetId } = props;

	const [links, setLinks] = useState<FormLinkProps[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await getFormLinks(assetId);
				setLinks(data);
			} catch (error) {
				setLinks([]);
				console.log(error);
			}
		};
		fetchData();
	}, [assetId]);

	return (
		<IonList>
			{links.map((it, idx) => {
				return (
					<IonRouterLink key={idx} href={it.href} target={"_blank"} style={{ display: "flex" }}>
						<IonItem lines="none">
							<IonIcon src={linkOutline} color="dark" style={{ marginRight: ".5rem" }} />
							<IonLabel class="ion-text-wrap" color="dark" style={{ fontWeight: "600", textDecoration: "underline" }}>
								{it.label}
							</IonLabel>
						</IonItem>
					</IonRouterLink>
				);
			})}
		</IonList>
	);
};
