import { apiClient } from ".";
import { IRawAsset } from "../models/Asset";

export interface GetAssetsProps {
	projectRefs?: string[];
	assetIds?: string[];
	excludeProjectRefs?: string[];
	excludeAssetIds?: string[];
	page?: number;
}

export const postAsset = async (asset: { name: string; project_ref: string }): Promise<IRawAsset> => {
	const res = await apiClient.post<IRawAsset>(`conf/assets`, { asset });
	return res.data;
};

export const duplicateAsset = async (assetId: string, name: string): Promise<IRawAsset> => {
	const res = await apiClient.post<IRawAsset>(`conf/assets/${assetId}/duplicate`, { name });
	return res.data;
};

export const deleteAsset = async (assetId: string): Promise<IRawAsset> => {
	const res = await apiClient.delete<IRawAsset>(`conf/assets/${assetId}`);
	return res.data;
};
