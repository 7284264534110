import * as React from "react";

const SvgComponent = (props) => (
	<svg
		{...props}
		width="1em"
		height="1em"
		viewBox="0 0 165.731 156.453"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
	>
		<defs>
			<linearGradient id="a">
				<stop
					style={{
						stopColor: "#1d6b40",
						stopOpacity: 1,
					}}
					offset={0}
				/>
				<stop
					style={{
						stopColor: "#217748",
						stopOpacity: 1,
					}}
					offset={1}
				/>
			</linearGradient>
			<linearGradient
				xlinkHref="#a"
				id="b"
				gradientUnits="userSpaceOnUse"
				x1={110.829}
				y1={729.648}
				x2={110.829}
				y2={596.074}
				gradientTransform="matrix(0 -.70582 .70582 0 -487.113 134.254)"
			/>
		</defs>
		<g
			transform="matrix(.70582 0 0 .70582 67.12 -377.031)"
			style={{
				fill: "#fff",
				fillOpacity: 1,
				stroke: "none",
			}}
		>
			<title>{"Rectangle.306"}</title>
			<path
				className="st1"
				style={{
					fill: "#fff",
					stroke: "none",
					strokeWidth: 0.25,
					fillOpacity: 1,
				}}
				d="M0 560.896h133.575v168.75H0z"
			/>
		</g>
		<g
			transform="matrix(.70582 0 0 .70582 56.83 -373.86)"
			style={{
				fill: "#1f7144",
				fillOpacity: 1,
				stroke: "none",
			}}
		>
			<title>{"rect307.23"}</title>
			<path
				d="M9.48 551.34c-5.25 0-9.48 4.21-9.48 9.45V720.2c0 5.23 4.23 9.45 9.48 9.45h135.33c5.25 0 9.48-4.22 9.48-9.45V560.79c0-5.24-4.23-9.45-9.48-9.45zm-1.9 7.83h139.05v162.56H7.58Z"
				className="st4"
				style={{
					fill: "#1f7144",
					stroke: "none",
					strokeWidth: 0.683161,
					strokeLinecap: "round",
					strokeLinejoin: "round",
					fillOpacity: 1,
				}}
			/>
		</g>
		<g
			transform="matrix(.70582 0 0 .70582 90.074 -390.02)"
			style={{
				fill: "#1f7144",
				fillOpacity: 1,
				stroke: "none",
			}}
		>
			<title>{"Sheet.43"}</title>
			<g
				transform="translate(45.385 -111.731)"
				style={{
					fill: "#1f7144",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle"}</title>
				<path
					className="st5"
					style={{
						fill: "#1f7144",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 709.429h37.442v20.217H0z"
				/>
			</g>
			<g
				transform="translate(0 -111.731)"
				style={{
					fill: "#1f7144",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.34"}</title>
				<path
					className="st5"
					style={{
						fill: "#1f7144",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 709.429h37.442v20.217H0z"
				/>
			</g>
			<g
				transform="translate(45.385 -84.122)"
				style={{
					fill: "#1f7144",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.35"}</title>
				<path
					className="st5"
					style={{
						fill: "#1f7144",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 709.429h37.442v20.217H0z"
				/>
			</g>
			<g
				transform="translate(0 -84.214)"
				style={{
					fill: "#1f7144",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.36"}</title>
				<path
					className="st5"
					style={{
						fill: "#1f7144",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 709.429h37.442v20.217H0z"
				/>
			</g>
			<g
				transform="translate(45.385 -56.135)"
				style={{
					fill: "#1f7144",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.37"}</title>
				<path
					className="st5"
					style={{
						fill: "#1f7144",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 709.429h37.442v20.217H0z"
				/>
			</g>
			<g
				transform="translate(0 -56.135)"
				style={{
					fill: "#1f7144",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.38"}</title>
				<path
					className="st5"
					style={{
						fill: "#1f7144",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 709.429h37.442v20.217H0z"
				/>
			</g>
			<g
				transform="translate(45.385 -27.827)"
				style={{
					fill: "#1f7144",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.39"}</title>
				<path
					className="st5"
					style={{
						fill: "#1f7144",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 709.429h37.442v20.217H0z"
				/>
			</g>
			<g
				transform="translate(0 -28.01)"
				style={{
					fill: "#1f7144",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.40"}</title>
				<path
					className="st5"
					style={{
						fill: "#1f7144",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 709.429h37.442v20.217H0z"
				/>
			</g>
			<g
				transform="translate(45.385)"
				style={{
					fill: "#1f7144",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.41"}</title>
				<path
					className="st5"
					style={{
						fill: "#1f7144",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 709.429h37.442v20.217H0z"
				/>
			</g>
			<g
				style={{
					fill: "#1f7144",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.42"}</title>
				<path
					className="st5"
					style={{
						fill: "#1f7144",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 709.429h37.442v20.217H0z"
				/>
			</g>
		</g>
		<path
			d="M27.89 134.254V-22.2L-66.394-6.558v125.164Z"
			className="st25"
			style={{
				fill: "rgb(31, 113, 68))",
				fillOpacity: 1,
				stroke: "none",
				strokeWidth: 0.176456,
			}}
			transform="translate(66.394 22.199)"
		/>
		<g
			transform="matrix(.70582 0 0 .70582 24.599 -408.198)"
			style={{
				stroke: "none",
			}}
		>
			<title>{"Sheet.46"}</title>
			<path
				d="m17.42 652.12 9.25 24.04c.667 1.42.967 2.944 1.12 3.97-.07-1.195.181-2.129.86-3.97l10.96-25.36 16.84-1a1097.458 1097.458 0 0 0-19.67 39.36l20.51 40.49-18.15-1.08-11.03-26.42c-.863-1.993-.696-2.264-.87-3.24-.08.695-.108 1.265-.57 2.52l-11.1 25.74L0 726.24l18-36.54-16.2-36.65Z"
				className="st24"
				style={{
					fill: "#fff",
					stroke: "none",
					strokeWidth: 0.75,
				}}
			/>
		</g>
	</svg>
);

export default SvgComponent;
