import React, { useMemo } from "react";

import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonIcon,
} from "@ionic/react";
import { trash } from "ionicons/icons";

import { Excel, GenericFile, Pdf, PowerPoint, Word } from "assets/icons";
import { IFile } from "interfaces/IFile";
import { IImage } from "interfaces/IImage";
import LocalFile from "models/LocalFile";
import { handleUrlDownload } from "utils/download/handleDownload";

import ImageFallback from "./ImageFallback";

interface IProps {
	file: IFile;
	onDelete?: (toDelete: string) => void;
}
type PropsType = IProps;

const FileThumbnail: React.FC<PropsType> = (props) => {
	const { file, onDelete } = props;

	const dateFormatter = Intl.DateTimeFormat(undefined, {
		year: "numeric",
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
	});

	const extension = useMemo(
		() => file.extension ?? file.filepath.split(".").slice(-1)[0],
		[file.extension, file.filepath],
	);

	const filename = useMemo(
		() => file.filename ?? file.filepath.split("/").slice(-1)[0] ?? "unnamed.jpeg",
		[file.filename, file.filepath],
	);

	const getIcon = () => {
		const properties = { style: { width: "2rem", height: "2rem" } };
		switch (extension) {
			case "xls":
			case "xlsx":
			case "xlsm":
				return <Excel {...properties} />;
			case "doc":
			case "docx":
				return <Word {...properties} />;
			case "ppt":
			case "pptx":
				return <PowerPoint {...properties} />;
			case "pdf":
				return <Pdf {...properties} />;
			case "jpeg":
			case "jpg":
			case "png":
				return <ImageFallback photo={file as IImage} style={{ width: "3rem", height: "3rem" }} />;
			default:
				return <GenericFile {...properties} />;
		}
	};

	const handleDownload = async () => {
		const localFile = await LocalFile.get(file.filepath).catch(() => undefined);
		let url = "";
		if (localFile?.file) {
			url = URL.createObjectURL(localFile.file);
		} else {
			const origin = window.location.origin === "http://localhost:3000" ? undefined : window.location.origin;
			url = `${origin}/files/${file.filepath}`;
		}
		handleUrlDownload(url, filename);
	};

	return (
		<IonCard
			mode="md"
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				margin: "0",
				minHeight: "7.5rem",
				minWidth: "7.5rem",
				cursor: "pointer",
				boxShadow: "none",
				border: "1px solid var(--ion-color-gray200)",
				borderRadius: "4px",
			}}
			onClick={handleDownload}
		>
			{/* <IonIcon /> */}
			<IonCardHeader style={{ padding: ".75rem 1rem" }}>
				<IonCardTitle style={{ fontSize: ".9rem" }}>{file.filename}</IonCardTitle>
				<IonCardSubtitle style={{ fontSize: ".7rem" }}>
					{file.date_uploaded !== undefined ? dateFormatter.format(new Date(file.date_uploaded)) : undefined}
				</IonCardSubtitle>
			</IonCardHeader>
			<IonCardContent
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					height: "100%",
					width: "100%",
					paddingBottom: ".75rem",
				}}
			>
				<div style={{ display: "flex", gap: "1rem", width: "100%", alignItems: "center", flex: 1 }}>
					{getIcon()}
					{onDelete !== undefined && (
						<IonButton
							fill="clear"
							color="dark"
							style={{ marginLeft: "auto", "--padding-start": 0, "--padding-end": 0 }}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								onDelete(file.filepath);
							}}
						>
							<IonIcon icon={trash} />
						</IonButton>
					)}
				</div>
			</IonCardContent>
		</IonCard>
	);
};

export default FileThumbnail;
