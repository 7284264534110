export const resizeImageFile = (imageFile: File, maxSize = 1024) => {
	return new Promise<File>((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = (fileReadEvent) => {
			const img = new Image();

			img.onload = () => {
				let height = maxSize;
				let width = maxSize;

				// Check if the image require resize at all
				if (img.height <= height && img.width <= width) {
					return resolve(imageFile);
				} else {
					// Make sure the width and height preserve the original aspect ratio and adjust if needed
					if (img.height > img.width) {
						width = Math.floor(height * (img.width / img.height));
					} else {
						height = Math.floor(width * (img.height / img.width));
					}

					const resizingCanvas: HTMLCanvasElement = document.createElement("canvas");
					const resizingCanvasContext = resizingCanvas.getContext("2d");
					if (!resizingCanvasContext) throw new Error("Could not retrieve 2D canvas context");

					// Start with original image size
					resizingCanvas.width = img.width;
					resizingCanvas.height = img.height;

					// Draw the original image on the (temp) resizing canvas
					resizingCanvasContext.drawImage(img, 0, 0, resizingCanvas.width, resizingCanvas.height);

					const curImageDimensions = {
						width: Math.floor(img.width),
						height: Math.floor(img.height),
					};

					const halfImageDimensions = {
						width: 0,
						height: 0,
					};

					// Quickly reduce the dize by 50% each time in few itterations until the size is less then
					// 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
					// created with direct reduction of very big image to small image
					while (curImageDimensions.width * 0.5 > width) {
						// Reduce the resizing canvas by half and refresh the image
						halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
						halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

						resizingCanvasContext.drawImage(
							resizingCanvas,
							0,
							0,
							curImageDimensions.width,
							curImageDimensions.height,
							0,
							0,
							halfImageDimensions.width,
							halfImageDimensions.height,
						);

						curImageDimensions.width = halfImageDimensions.width;
						curImageDimensions.height = halfImageDimensions.height;
					}

					// Now do final resize for the resizingCanvas to meet the dimension requirments
					// directly to the output canvas, that will output the final image
					const outputCanvas: HTMLCanvasElement = document.createElement("canvas");
					const outputCanvasContext = outputCanvas.getContext("2d");
					if (!outputCanvasContext) throw new Error("Could not retrieve 2D canvas context");

					outputCanvas.width = width;
					outputCanvas.height = height;

					outputCanvasContext.drawImage(
						resizingCanvas,
						0,
						0,
						curImageDimensions.width,
						curImageDimensions.height,
						0,
						0,
						width,
						height,
					);
					return outputCanvas.toBlob(
						(blob) => {
							if (blob) {
								resolve(new File([blob], imageFile.name, { lastModified: imageFile.lastModified }));
							} else {
								reject("Could not export canvas as blob");
							}
						},
						"jpeg",
						0.85,
					);
				}
			};
			const base64Url = fileReadEvent.target?.result;
			if (!(typeof base64Url === "string")) throw new Error("Could not image file as data url");
			img.src = base64Url;
		};
		reader.readAsDataURL(imageFile);
	});
};
