import React from "react";

import { IonHeader, IonSkeletonText, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon } from "@ionic/react";
import { printOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { arrowLeft, highlight } from "assets/icons";

interface TopbarProps {
	assetName?: string;
	formName?: string;
	dateSynced?: Date;
	comparison?: { from: string; to?: string };
	backUrl: string;
}
export const Topbar: React.FC<TopbarProps> = (props) => {
	const history = useHistory();

	const searchParams = new URLSearchParams(window.location.search);
	const compareFrom = searchParams.get("from") || undefined;

	const getTitle = () => `${props.assetName} - ${props.formName}`;

	const getSubTitle = () => {
		const formatter = Intl.DateTimeFormat(undefined, { dateStyle: "short", timeStyle: "medium" });
		return formatter.format(props.dateSynced);
	};

	return (
		<IonHeader mode="ios" className="report-header" style={{ borderBottom: "1px solid #333", background: "white" }}>
			<IonToolbar style={{ alignItems: "center", "--border-width": 0, height: "auto" }}>
				<IonButtons slot="start">
					<IonButton
						fill="clear"
						color="primary"
						className="no-print"
						onClick={() => {
							history.push(props.backUrl);
						}}
					>
						<IonIcon src={arrowLeft} size="small" />
					</IonButton>
				</IonButtons>
				<IonButtons slot="end">
					{props.comparison && (
						<IonButton
							fill={compareFrom ? "solid" : "clear"}
							color={compareFrom ? "secondary" : "primary"}
							className="no-print"
							onClick={() => {
								if (compareFrom) history.replace({ search: "" });
								else
									history.push({
										search: new URLSearchParams(props.comparison).toString(),
									});
							}}
						>
							<IonIcon src={highlight} size="small" />
						</IonButton>
					)}
					<IonButton
						fill="clear"
						color="primary"
						className="no-print"
						onClick={() => {
							window.print();
						}}
					>
						<IonIcon src={printOutline} size="small" />
					</IonButton>
				</IonButtons>
				<IonTitle style={{ margin: ".25rem" }}>
					{props.assetName && props.formName ? (
						<React.Fragment>
							<h1 style={{ fontSize: "1rem", fontWeight: "600", margin: 0, whiteSpace: "break-spaces" }}>
								{getTitle()}
							</h1>
							<p style={{ margin: 0 }}>{props.dateSynced && getSubTitle()} </p>
						</React.Fragment>
					) : (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<IonSkeletonText animated style={{ height: "75%", width: "30%" }} />
						</div>
					)}
				</IonTitle>
			</IonToolbar>
		</IonHeader>
	);
};
