import React, { useEffect } from "react";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord from "models/FormRecord";
import Project from "models/Project";
import { getSyncStatus } from "utils/sync/isSynced";
import { IState } from "./RecordsView";
import { IRecordSummary } from "api/records";

export function useRecordViewUpdateState(
	assetId: string,
	projectRef: string,
	setState: React.Dispatch<React.SetStateAction<IState>>,
	remoteSlice: IRecordSummary[],
) {
	useEffect(() => {
		let isCancelled = false;

		const initialize = async () => {
			const asset = await Asset.get(assetId);
			const project = await Project.get(projectRef);
			const forms = await Form.getMany(project.configuration.form_ids);
			const records = await FormRecord.byAssetId(assetId);
			const sortedRecords = records.sort((a, b) => {
				const formIds = project.configuration.form_ids;
				return formIds.findIndex((it) => it === a.form_id) - formIds.findIndex((it) => it === b.form_id);
			});
			if (isCancelled) return;
			setState({
				asset,
				project,
				forms,
				records: sortedRecords,
				assetSyncState: await getSyncStatus(remoteSlice, projectRef, assetId),
				loading: false,
			});
		};

		initialize();
		const initializerInterval = setInterval(initialize, 5e3);
		return () => {
			isCancelled = true;
			clearInterval(initializerInterval);
		};
	}, [projectRef, assetId, remoteSlice]);
}
