import { AssetWithSyncStatus, GroupedLocalAssets, GroupedRemoteAssets } from "../types";

export const groupLocalAssets = (assets: AssetWithSyncStatus[]) =>
	[...assets]
		.sort((a, b) => a.asset.name.localeCompare(b.asset.name))
		.reduce(
			(acc: GroupedLocalAssets, item: AssetWithSyncStatus) => {
				if (item.asset.status?.status === "discarded") {
					acc["discarded_local_assets"].push(item);
				} else {
					if (item.asset.status?.status === "rejected") {
						acc["rejected_local_assets"].push(item);
					} else if (item.asset.status?.status === "pendingValidation") {
						acc["pending_local_assets"].push(item);
					} else if (item.asset.status?.status === "validated") {
						acc["validated_local_assets"].push(item);
					} else if (item.asset.can_be_deleted) {
						acc["draft_local_assets"].push(item);
					} else {
						acc["in_progress_local_assets"].push(item);
					}
				}
				return acc;
			},
			{
				discarded_local_assets: [],
				draft_local_assets: [],
				rejected_local_assets: [],
				in_progress_local_assets: [],
				pending_local_assets: [],
				validated_local_assets: [],
			},
		);

export const groupRemoteAssets = (assets: AssetWithSyncStatus[]) =>
	[...assets].reduce(
		(acc: GroupedRemoteAssets, item: AssetWithSyncStatus) => {
			if (item.asset.status?.status === "discarded") {
				acc["discarded_remote_assets"].push(item);
			} else {
				acc["remote_assets"].push(item);
			}
			return acc;
		},
		{
			discarded_remote_assets: [],
			remote_assets: [],
		},
	);
