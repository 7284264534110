import React, { PropsWithChildren, useEffect, useState } from "react";

import { useIonToast } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { FieldError, FieldErrors, useForm } from "react-hook-form";

import FiltersModal from "components/modals/FiltersModal";
import { FormField } from "models/Form";
import FormRecord, { FormValues, IDataValue } from "models/FormRecord";
import doRecursively from "utils/recursive/doRecursively";

import FormPage from "./components/FormPage";
import Topbar from "./components/Topbar";

interface IProps extends PropsWithChildren {
	id: string;
	formFields: FormField[];
	record: FormRecord;
	onSubmit: (values: FormValues, changes: string[], history: string[], exit?: boolean, stay?: boolean) => Promise<void>;
	exitForm: () => void;
	onPrint: () => void;
}

type PropsType = IProps;

const Form: React.FC<PropsType> = (props) => {
	const { id, formFields, record, onSubmit, exitForm, onPrint } = props;

	const [present] = useIonToast();
	const { t } = useTranslation();
	const methods = useForm({
		defaultValues: record.data,
		reValidateMode: "onBlur",
	});

	const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);

	// useEffect(() => {
	// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// 	const subscription = methods.watch((value, { name, type }) => {
	// 		console.log(name, value as any);
	// 	});
	// 	return () => subscription.unsubscribe();
	// });

	const { formState } = methods;

	useEffect(() => {
		methods.reset(record.data);
	}, []);

	useEffect(() => {
		const handleValidationError = (fieldKey: string, fieldError: FieldError) => {
			const fieldLabel = doRecursively<string>({
				fields: formFields,
				action: ({ field }) => ({ label: field.label }),
				shouldAction: ({ path }) => path[path.length - 1] === fieldKey,
				shouldStop: ({ result }) => result.label !== undefined,
			}).label;
			present({
				position: "top",
				color: "dark",
				message: t("invalid_msg", { field: fieldLabel || fieldKey, reason: fieldError.type }),
				duration: 2000,
				buttons: ["Dismiss"],
			});
			const element = document.querySelector(`#field-${fieldKey}`);
			element?.scrollIntoView(true);
		};

		const recursivelyReviewErrors = (fieldErrors: FieldErrors<Record<string, IDataValue>>) => {
			for (const [key, value] of Object.entries(fieldErrors)) {
				if (value && "type" in value && "message" in value && "ref" in value) {
					handleValidationError(key, value as FieldError);
					return;
				} else {
					recursivelyReviewErrors(value as FieldErrors);
				}
			}
		};
		if (formState.errors === undefined) return;
		recursivelyReviewErrors(formState.errors);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState.isSubmitting]);

	// Get all the DrillDownGroups to render the corresponding pages
	function flattenObject(nestedObject: FormField[], groupsResult: FormField[]) {
		nestedObject.forEach((item) => {
			if (item.type === "drillDownGroup" || item.type === "repeatableGroup" || item.type === "inlineGroup") {
				groupsResult.push(item);
			}
			if (item.children) {
				flattenObject(item.children, groupsResult);
			}
		});
		return groupsResult;
	}
	const pages = flattenObject(formFields, []);

	return (
		<>
			<Topbar formFields={formFields} formMethods={methods} exitForm={exitForm} onSubmit={onSubmit} onPrint={onPrint} />

			<form id={id} style={{ position: "relative", height: "calc(100% - 3rem)" }}>
				<FormPage
					childrenFields={formFields}
					formMethods={methods}
					onSubmit={onSubmit}
					setFilterModalIsOpen={setFilterModalIsOpen}
				/>
				{pages.map((it) => (
					<FormPage
						key={it.name}
						field={it}
						childrenFields={it.children || []}
						formMethods={methods}
						onSubmit={onSubmit}
						setFilterModalIsOpen={setFilterModalIsOpen}
					/>
				))}
				{props.children}
				{/* This button's sole purpose is to disable form submit on enter, which can be annoying here */}
				<button type="submit" disabled style={{ display: "none" }} />
			</form>
			<FiltersModal
				isOpen={filterModalIsOpen}
				onClose={() => {
					setFilterModalIsOpen(false);
				}}
			/>
		</>
	);
};

export default Form;
