import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
	list: string[];
	active?: string;
	absolutePath: string;
}

const initialState: State = {
	list: [],
	active: undefined,
	absolutePath: "",
};

export const historySlice = createSlice({
	name: "history",
	initialState,
	reducers: {
		setHistory: (state, action: PayloadAction<string[]>) => {
			state.list = action.payload;
			state.active = action.payload[action.payload.length - 1];
			state.absolutePath = state.list.join(".");
		},
		clearHistory: (state) => {
			state.active = undefined;
			state.list = [];
			state.absolutePath = state.list.join(".");
		},
		goToNth: (state, action: PayloadAction<number>) => {
			state.list = state.list.slice(0, action.payload + 1);
			state.active = state.list[action.payload];
			state.absolutePath = state.list.join(".");
		},
		pushHistory: (state, action: PayloadAction<string>) => {
			state.list = [...state.list, action.payload];
			state.active = action.payload;
			state.absolutePath = state.list.join(".");
		},
		pushHistoryMany: (state, action: PayloadAction<string[]>) => {
			state.list = [...state.list, ...action.payload];
			state.active = action.payload[action.payload.length - 1];
			state.absolutePath = state.list.join(".");
		},
		popHistory: (state) => {
			const newList = [...state.list];
			newList.pop();
			state.list = newList;
			if (newList.length >= 1) {
				state.active = newList[newList.length - 1];
			} else {
				state.active = undefined;
			}
			state.absolutePath = state.list.join(".");
		},
	},
});

export const actions = historySlice.actions;

export default historySlice.reducer;
