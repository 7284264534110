export const selectStatusColor = (status?: string) => {
	switch (status) {
		case "inProgress":
			return "#a0aec0";
		case "pendingValidation":
			return "#ffd10a";
		case "validated":
			return "#68c163";
		case "rejected":
			return "#f2454d";
		default:
			return "#00000000";
	}
};
