import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const userSlice = createSlice({
	name: "user",
	initialState: { email: "" },
	reducers: {
		setEmail: (state, action: PayloadAction<string>) => {
			state.email = action.payload;
		},
	},
});

export const actions = userSlice.actions;

export default userSlice.reducer;
