import React, { useRef } from "react";

import { IonContent, IonIcon, IonLabel, IonList, IonListHeader, IonModal, useIonToast } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../store";
import { actions } from "../../../store/slices/filters";
import Filters from "./Filters";
import { FormValues, IDataValue } from "models/FormRecord";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
}
const FiltersModal: React.FC<IProps> = (props) => {
	const { isOpen, onClose } = props;
	const { t, i18n } = useTranslation();
	const filterId = useAppSelector((state) => state.history.active);

	const ref = useRef<HTMLFormElement>(null);

	const dispatch = useAppDispatch();
	const { filterValues: filterValues, filterFields } = useAppSelector((state) => state.filters);

	const [present] = useIonToast();

	if (isOpen && !filterId) {
		present({
			position: "top",
			color: "secondary",
			header: "Filters not available",
			message: "Filter id not provided",
			duration: 5000,
		});
		onClose();
		return <React.Fragment />;
	}

	const handleReset = () => {
		if (filterId) dispatch(actions.RESET_FILTERS());
		onClose();
	};
	const handleSubmit = (filters: FormValues<IDataValue>) => {
		if (filterId)
			dispatch(
				actions.SET_FILTERS({
					repeatableId: filterId,
					filterValues: filters,
				}),
			);
		onClose();
	};

	return (
		<IonModal isOpen={isOpen} canDismiss={true} onWillDismiss={onClose}>
			<IonContent forceOverscroll={false} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
				<IonList
					style={{
						overflowY: "auto",
						flex: "1",
						position: "relative",
						padding: 0,
					}}
				>
					<IonListHeader
						style={{
							height: "4rem",
							position: "sticky",
							top: "0",
							zIndex: "10",
							background: "var(--ion-background-color, #fff)",
						}}
					>
						<IonLabel style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
							{i18n.format(t("filters"), "capitalize")}
						</IonLabel>
						<IonIcon
							style={{
								height: "100%",
								width: "2rem",
								color: "black",
								alignSelf: "center",
								paddingTop: "1rem",
								paddingBottom: "0.5rem",
								cursor: "pointer",
							}}
							icon={closeOutline}
							onClick={onClose}
						/>
					</IonListHeader>
					<Filters
						ref={ref}
						filterFields={filterFields || []}
						initialFilters={filterValues || {}}
						setFilters={(filters) => handleSubmit(filters)}
						resetFilters={handleReset}
					/>
				</IonList>
			</IonContent>
		</IonModal>
	);
};

export default FiltersModal;
