import React, { useEffect, useState } from "react";

import { AuthenticationProvider } from "@arup-group/cognito-authenticator";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { IonAlert, IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";

import { CheckOnline } from "components/common/CheckOnline/CheckOnline";
import InstallPrompt from "./components/common/InstallPrompt";
import SilentDataFetcher from "./components/common/SilentDataFetcher";
import config from "./config";
import initDebugProject from "./mock/debug";
import { useAppSelector } from "./store";
import { estimateStorageQuota, getAssetAnalytics, requestStoragePersistance } from "./utils/storageUtils";
import AdminView from "./views/AdminView/AdminView";
import AssetsView from "./views/AssetsView/AssetsView";
import RecordsView from "./views/RecordsView/RecordsView";
import ProjectsView from "./views/ProjectsView/ProjectsView";
import RecordView from "./views/RecordView/RecordView";
import ReportView from "views/ReportView";

// /* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

// /* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

// /* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "./theme/colors.css";
import "./theme/overrides.css";
import { useDeviceId } from "utils/useDeviceId";
import { putAnalytics } from "api/storageAnalytics";

setupIonicReact();

interface IProps {
	match?: {
		url: string;
		[x: string]: unknown;
	};
}

const App: React.FC<IProps> = () => {
	const { serviceWorkerUpdated, serviceWorkerRegistration } = useAppSelector((state) => state.sw);

	const [pendingUpdateDismissed, setPendingUpdateDismissed] = useState(false);
	const online = useAppSelector((state) => state.connection.isOnline);
	const { t, i18n } = useTranslation();
	const deviceId = useDeviceId();

	const { route: authStatus } = useAuthenticator((context) => [context.route]);

	const updateServiceWorker = () => {
		if (serviceWorkerRegistration === null) {
			console.warn("Service worker registration unavailable in redux store");
			return;
		}
		const registrationWaiting = serviceWorkerRegistration.waiting;
		if (registrationWaiting) {
			registrationWaiting.postMessage({ type: "SKIP_WAITING" });
			registrationWaiting.addEventListener("statechange", (e) => {
				if (e.target === null) return;
				const target = e.target as unknown as ServiceWorker;
				if (target.state === "activated") {
					window.location.reload();
				}
			});
		}
	};

	useEffect(() => {
		if (authStatus === "signIn") {
			localStorage.setItem("nextUrl", window.location.href);
		} else if (authStatus === "authenticated") {
			const nextUrl = localStorage.getItem("nextUrl");
			if (nextUrl) {
				window.location.href = nextUrl;
				localStorage.removeItem("nextUrl");
			}
		}
	}, [authStatus]);

	// Inform us on storage quota
	useEffect(() => {
		requestStoragePersistance().then(async () => {
			const storageQuotaEstimate = await estimateStorageQuota();
			const assetAnalytics = await getAssetAnalytics();
			putAnalytics({
				device_id: deviceId,
				storage_estimate: storageQuotaEstimate,
				local_asset_analytics: assetAnalytics,
			}).catch(() => console.warn("Could not submit storage analytics"));
		});
	}, []);

	// Initialize debug project
	useEffect(() => {
		if (window.location.hostname === "localhost") initDebugProject();
	}, []);

	const apiKey = new URLSearchParams(window.location.search).get("api_key");

	return (
		<IonReactRouter>
			<React.StrictMode>
				<Switch>
					<Route exact path="/report/:recordId/">
						{<ReportView />}
					</Route>
					<Route path="/">
						<IonApp>
							<IonAlert
								header={i18n.format(t("please_update"), "capitalizeEveryWord")}
								message={
									i18n.format(t("new_forms_version"), "capitalize") +
									i18n.format(t("update_forms_question"), "capitalize")
								}
								isOpen={serviceWorkerUpdated && !pendingUpdateDismissed}
								backdropDismiss={false}
								buttons={[
									{
										text: i18n.format(t("cancel"), "capitalize"),
										role: "cancel",
										cssClass: "secondary",
										handler: () => {
											setPendingUpdateDismissed(true);
											console.log("SW update cancelled");
										},
									},
									{
										text: i18n.format(t("update"), "capitalize"),
										handler: updateServiceWorker,
									},
								]}
							/>
							<CheckOnline />
							{authStatus !== "authenticated" && !apiKey && online ? (
								<AuthenticationProvider
									region={config.AWS_REGION}
									userPoolId={config.AWS_COGNITO_POOL_ID}
									userPoolWebClientId={config.AWS_COGNITO_WEB_CLIENT_ID}
									domain={config.AWS_COGNITO_DOMAIN}
									allowSignUp={false}
									azureADProviderId="AzureAD"
								/>
							) : (
								<Switch>
									<Route exact path="/admin">
										<AdminView />
									</Route>

									<Route exact path="/:projectRef/:assetId/:recordId">
										<RecordView />
									</Route>
									<Route exact path="/:projectRef/:assetId">
										<RecordsView />
									</Route>
									<Route exact path="/:projectRef">
										<AssetsView />
									</Route>
									<Route exact path="/">
										<ProjectsView />
									</Route>
								</Switch>
							)}
							<SilentDataFetcher />
							<InstallPrompt />
						</IonApp>
					</Route>
				</Switch>
			</React.StrictMode>
		</IonReactRouter>
	);
};

export default App;
