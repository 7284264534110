import { FormField } from "../../../../models/Form";

export const getFilterChoices = (field: FormField) => {
	// This gets all possible choices, instead of all choices that are "selected" in the existing issues
	const choices = field.choices || [];
	const valueLabelDict: { [key: string]: string } = {};
	const choicesSet = new Set(choices.map((ch) => ch.value));
	for (const value of choicesSet) {
		const ch = choices.find((ch) => ch.value === value);
		if (ch) {
			if (value in valueLabelDict) {
				valueLabelDict[value] += ` / ${ch.label}`;
			} else {
				valueLabelDict[value] = ch.label;
			}
		}
	}
	const uniqueChoices = Object.entries(valueLabelDict).map(([k, v]) => ({
		value: k,
		label: v,
	}));
	if (field.type === "dropdown") return uniqueChoices.sort((a, b) => a.label.localeCompare(b.label));
	return uniqueChoices;
};
