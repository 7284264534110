import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import buildFieldReferenceLibrary from "components/common/Form/utils/buildReferenceLibrary";
import { FieldReferenceLibrary } from "interfaces/FieldReference";
import { FormDeliveryStatus } from "interfaces/FormDeliveryStatus";
import { FormValues } from "models/FormRecord";

import { IRawForm } from "../../../models/Form";

interface State {
	active?: IRawForm;
	projectRef?: string;
	assetId?: string;
	recordId?: string;
	fieldReferenceLibrary: FieldReferenceLibrary;
	baseContext: { [formId: string]: FormValues };
	deliveryStatus: FormDeliveryStatus;
	isFrozen: boolean;
}

const initialState: State = {
	active: undefined,
	projectRef: undefined,
	assetId: undefined,
	recordId: undefined,
	fieldReferenceLibrary: {},
	baseContext: {},
	deliveryStatus: "inProgress",
	isFrozen: false,
};

export const formSlice = createSlice({
	name: "form",
	initialState,
	reducers: {
		setActive: (
			state,
			action: PayloadAction<
				Pick<State, "active" | "assetId" | "projectRef" | "recordId" | "baseContext" | "deliveryStatus" | "isFrozen">
			>,
		) => {
			state.active = action.payload.active;
			state.fieldReferenceLibrary = action.payload.active
				? buildFieldReferenceLibrary(action.payload.active.fields)
				: {};
			state.projectRef = action.payload.projectRef;
			state.assetId = action.payload.assetId;
			state.recordId = action.payload.recordId;
			state.baseContext = action.payload.baseContext;
			state.deliveryStatus = action.payload.deliveryStatus;
			state.isFrozen = action.payload.isFrozen;
		},
		reset: () => {
			return initialState;
		},
	},
});

export const actions = formSlice.actions;

export default formSlice.reducer;
