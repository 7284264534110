import React, { memo, PropsWithChildren } from "react";

import { IonIcon, IonInput, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";
import { Controller, UseFormReturn } from "react-hook-form";

import { Divider } from "components/common/Form/components/Divider";
import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";
import { FormField } from "../../../../models/Form";
import { FormValues } from "../../../../models/FormRecord";
import useFieldTemplate from "../hooks/useFieldTemplate";

interface IProps {
	field: FormField<string>;
	formMethods: UseFormReturn<FormValues>;
}

type InputType =
	| "date"
	| "datetime-local"
	| "email"
	| "month"
	| "number"
	| "password"
	| "search"
	| "tel"
	| "text"
	| "time"
	| "url"
	| "week";

type PropsType = IProps;
const Input: React.FC<PropsType> = (props) => {
	const { field, formMethods } = props;
	const { name, alert, isRelevant, isRequired, isDisabled, isHardRequired, isFrozen } = useFieldTemplate(
		field,
		formMethods.control,
	);

	return (
		<div
			className="Input"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{isRelevant() && (
				<Controller
					name={name}
					control={formMethods.control}
					rules={{
						// validate: isValid,
						required: isHardRequired() && isRelevant(),
					}}
					defaultValue={field.defaultValue}
					render={({ field: fieldRenderProps }) => {
						return (
							<IonItem lines="none" disabled={isFrozen || isDisabled()}>
								<IonLabel id={`${field.name}-label`} className="ion-text-wrap" position="stacked" mode="ios">
									{field.label}
									{(isRequired() || isHardRequired()) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<IonInput
									aria-labelledby={`${field.name}-label`}
									data-testid={`${field.type}:input`}
									// React Hook Form Managed
									ref={fieldRenderProps.ref}
									name={fieldRenderProps.name}
									value={fieldRenderProps.value as string}
									onIonBlur={fieldRenderProps.onBlur}
									onIonChange={fieldRenderProps.onChange}
									// Other config
									type={
										[
											"number",
											"date",
											"datetime-local",
											"email",
											"month",
											"password",
											"search",
											"tel",
											"text",
											"time",
											"url",
											"week",
										].includes(field.type)
											? (field.type as InputType)
											: "text"
									}
									placeholder={field.description}
									// eslint-disable-next-line @typescript-eslint/no-empty-function
									onKeyDown={field.isInteger ? (evt) => ["."].includes(evt.key) && evt.preventDefault() : undefined}
									// required={isRequired()}
									enterkeyhint="next"
									min={field.min}
									max={field.max}
									step={field.step}
								/>
								<Divider color="var(--ion-color-medium)" />
								{alert && (
									<IonNote
										data-testid={`${field.type}:note`}
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						);
					}}
				/>
			)}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Input, propsAreEqual);
export default Memoized;
