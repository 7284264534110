import React, { useEffect } from "react";

import { useAuthenticator } from "@aws-amplify/ui-react";
import i18n from "i18n";
import { useLocation } from "react-router-dom";

import Project from "models/Project";
import { useAppDispatch, useAppSelector } from "store";
import { performBackgroundSyncThunk } from "store/slices/remote/thunks";

const SilentDataFetcher: React.FC = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const projectRef = location.pathname.split("/")[1]; // Cant use useParams if not within switch
	const { route: authStatus } = useAuthenticator((context) => [context.route]);
	const isOnline = useAppSelector((state) => state.connection.isOnline);

	// Choose the right language for the selected project
	useEffect(() => {
		if (!projectRef || projectRef === "admin" || projectRef === "report") return;
		Project.get(projectRef).then((project) => {
			console.log("setting project language:", project.language);
			i18n.changeLanguage(project.language || "en");
		});
	}, [projectRef]);

	useEffect(() => {
		let syncing = false;
		if (authStatus !== "authenticated" || !isOnline) return;
		syncing = true;
		dispatch(performBackgroundSyncThunk({})).then(() => {
			syncing = false;
		});
		const interval = setInterval(() => {
			if (syncing) return;
			dispatch(performBackgroundSyncThunk({ projectRef })).then(() => {
				syncing = false;
			});
		}, 120e3);
		return () => clearInterval(interval);
	}, [authStatus, isOnline]);

	return <React.Fragment />;
};

export default SilentDataFetcher;
