import { DataFilter, filterAssets } from "api/assetFilters";
import { getAssetsWithLatestStatus } from "api/assetStatus";
import { IRecordSummary } from "api/records";
import buildFieldReferenceLibrary from "components/common/Form/utils/buildReferenceLibrary";
import { checkRecordAgainstFilter } from "components/modals/FilterAsset/makeChecks";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord from "models/FormRecord";
import { ConfigurationFilter } from "models/Project";
import { FilterSlice } from "store/slices/assetFilter/assetFilterSlice";
import { getSyncStatus } from "utils/sync/isSynced";
import { AssetWithSyncStatus, AssetSubset } from "views/AssetsView/types";

export const getLocalAssetsToDisplay = async (
	projectRef: string,
	records: FormRecord[],
	config: ConfigurationFilter[],
	filter: FilterSlice,
	recordSummaries: IRecordSummary[],
) => {
	const allLocalAssets = await getAllLocalAssets(projectRef, recordSummaries);
	const filteredLocalAssets = filterLocalAssets(allLocalAssets, records, config, filter);
	return filteredLocalAssets;
};

export const getRemoteAssetsToDisplay = async (
	projectRef: string,
	config: ConfigurationFilter[],
	filter: FilterSlice,
	recordSummaries: IRecordSummary[],
	total?: number,
	page = 1,
) => {
	const localAssetIds = await Asset.byProjectRef(projectRef).then((assets) => assets.map((a) => a.id));
	if (Object.values(filter.dataFilters).some(Boolean) || filter.status || filter.tecnico) {
		return await filterRemoteAssets(projectRef, localAssetIds, config, filter, page).then(
			(res) =>
				({
					total: total || res.total,
					filteredTotal: res.total,
					items: res.items,
				} as AssetSubset),
		);
	}
	return await getRemoteAssets(projectRef, localAssetIds, recordSummaries, filter, page).then(
		(res) =>
			({
				total: res.total,
				filteredTotal: res.total,
				items: res.items,
			} as AssetSubset),
	);
};

const getRemoteAssets = async (
	projectRef: string,
	localAssetIds: string[],
	recordSummaries: IRecordSummary[],
	filter: FilterSlice,
	page: number,
) => {
	return await getAssetsWithLatestStatus({
		projectRefs: [projectRef],
		excludeAssetIds: localAssetIds,
		status: filter.discardedOnly ? "discarded" : undefined,
		excludeStatus: !filter.discardedOnly ? "discarded" : undefined,
		includeDeleted: false,
		page,
	}).then(async (res) => ({
		items: await Promise.all(
			res.items.map(async (asset) => ({
				asset,
				syncStatus: await getSyncStatus(recordSummaries, asset.project_ref, asset.id),
			})),
		),
		total: res.total,
	}));
};

const getAllLocalAssets = async (projectRef: string, recordSummaries: IRecordSummary[]) => {
	return await Asset.byProjectRef(projectRef).then(async (assets) => ({
		items: await Promise.all(
			assets.map(async (asset) => ({
				asset,
				syncStatus: await getSyncStatus(recordSummaries, asset.project_ref, asset.id),
			})),
		),
		total: assets.length,
	}));
};

export const filterLocalAssets = (
	assets: AssetSubset,
	records: FormRecord[],
	config: ConfigurationFilter[],
	filter: FilterSlice,
): AssetSubset => {
	const assetsWithRecords: { asset: AssetWithSyncStatus; records: FormRecord[] }[] = assets.items.map((a) => ({
		asset: a,
		records: records.filter((r) => r.asset_id === a.asset.id),
	}));
	const filteredWithDataFilters: AssetWithSyncStatus[] = assetsWithRecords
		.filter((current) => {
			for (const currConfig of config) {
				for (const record of current.records) {
					if (record.form_id !== currConfig.form_id) continue;
					if (!checkRecordAgainstFilter(currConfig, record, filter.dataFilters)) return false;
				}
			}
			return true;
		})
		.map((it) => it.asset);

	const statuses = filter.discardedOnly ? ["discarded"] : filter.status?.filter(Boolean) ?? [];
	const filtered = !statuses.length
		? filteredWithDataFilters
		: filteredWithDataFilters.filter((it) => statuses.includes(it.asset.status?.status || "inProgress"));

	return {
		items: filtered,
		total: filter.discardedOnly
			? assets.items.filter((it) => it.asset.status?.status === "discarded").length
			: assets.total,
	};
};

export const filterRemoteAssets = async (
	projectRef: string,
	localAssetIds: string[],
	config: ConfigurationFilter[],
	filter: FilterSlice,
	page = 1,
): Promise<AssetSubset> => {
	const forms = await Promise.all([...new Set(config.map((it) => it.form_id))].map((it) => Form.get(it)));
	const dataFilters: DataFilter[] = config
		.flatMap(({ form_id, fields }) => {
			const form = forms.find((f) => f.id === form_id);
			const referenceLibrary = buildFieldReferenceLibrary(form?.fields || []);
			return fields.map((path) => {
				const fieldName: string = path.split(".").slice(-1)[0];
				const fieldDetails = referenceLibrary[fieldName];
				const filterValues = filter.dataFilters[fieldName];
				if (filterValues === undefined || filterValues === null || filterValues === "") return undefined;
				return {
					formId: form_id,
					fieldPath: path,
					values: Array.isArray(filterValues) ? filterValues.map(String) : filterValues.toString(),
					isArray: fieldDetails.multiple || false,
				} as DataFilter;
			});
		})
		.filter((it): it is DataFilter => it !== undefined);

	const statuses = [...(filter.status ?? []), filter.discardedOnly ? "discarded" : undefined].filter(
		(it): it is string => it !== undefined,
	);

	const tecnicos = filter.tecnico?.length ? filter.tecnico : undefined;

	const { data: filteredAssets, pagination } = await filterAssets({
		dataFilters,
		projectRefs: [projectRef],
		excludeAssetIds: localAssetIds,
		status: statuses.length ? statuses : undefined,
		tecnico: tecnicos,
		excludeStatus: !filter.discardedOnly ? "discarded" : undefined,
		page,
	});

	return {
		items: filteredAssets.map((asset) => ({ syncStatus: "remote", asset })),
		total: pagination.total || filteredAssets.length,
	};
};
