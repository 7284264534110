import { FieldType, FormField } from "../../../../models/Form";

export const getChildrenAndGrandChildren = (children: FormField[], recursive = true) => {
	let types: FieldType[] = [];
	children?.forEach((child) => {
		types.push(child.type);
		if (child.children && recursive) {
			types = types.concat(getChildrenAndGrandChildren(child.children, false));
		}
	});
	return types;
};
