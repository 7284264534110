import { apiClient } from "api";

export interface FormLinkProps {
	href: string;
	label: string;
}

export const getFormLinks = async function (assetId: string): Promise<FormLinkProps[]> {
	return await apiClient.get<FormLinkProps[]>(`forms/links/${assetId}`).then((res) => res.data);
};
