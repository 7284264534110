import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRecordSummary } from "api/records";
import { BackgroundSyncOutput, performBackgroundSyncThunk } from "./thunks";

interface SummarySlice {
	thunkState: "idle" | "pending" | "succeeded" | "rejected";
	data: IRecordSummary[];
}

const initialState: SummarySlice = { thunkState: "idle", data: [] };

export const remoteSlice = createSlice({
	name: "remote",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(performBackgroundSyncThunk.rejected, (state) => {
			return { data: state.data, thunkState: "rejected" };
		});
		builder.addCase(performBackgroundSyncThunk.fulfilled, (state, action: PayloadAction<BackgroundSyncOutput>) => {
			const { projectRef, assetId, remoteSummaries } = action.payload;
			if (assetId) {
				const baseData = state.data.filter((it) => it.asset_id !== assetId);
				return { data: [...baseData, ...remoteSummaries], thunkState: "succeeded" };
			} else if (projectRef) {
				const baseData = state.data.filter((it) => it.project_ref !== projectRef);
				return { data: [...baseData, ...remoteSummaries], thunkState: "succeeded" };
			}
			return { data: remoteSummaries, thunkState: "succeeded" };
		});
	},
});

export const actions = remoteSlice.actions;

export default remoteSlice.reducer;
