import React from "react";

import { IonButton, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel } from "@ionic/react";
import { copyOutline, ellipseOutline, trashOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { circle } from "assets/icons";
import Asset from "models/Asset";
import { SyncStatus } from "utils/sync/isSynced";
import { useGetAssetName } from "views/AssetsView/hooks/useGetAssetName";

import { AssetWithSyncStatus } from "../types";
import SyncButton from "./SyncButton";
import { selectStatusColor } from "../utils/colors";
import { ActiveSyncProps } from "../AssetsView";

interface Props {
	label: string;
	items: AssetWithSyncStatus[];
	deliveryFeatureActive: boolean;
	isOnline: boolean;
	buildRouterLink: (asset: Asset, syncStatus?: SyncStatus) => string | undefined;
	setAssetToDuplicate: React.Dispatch<React.SetStateAction<Asset | undefined>>;
	setAssetToDelete: React.Dispatch<React.SetStateAction<string | undefined>>;
	setActiveSync: React.Dispatch<React.SetStateAction<ActiveSyncProps | undefined>>;
}
const AssetGroup: React.FC<Props> = (props) => {
	const {
		label,
		items,
		deliveryFeatureActive,
		isOnline,
		buildRouterLink,
		setAssetToDuplicate,
		setAssetToDelete,
		setActiveSync,
	} = props;
	const { t, i18n } = useTranslation();
	const { projectRef } = useParams<{ projectRef: string }>();
	const { nameDictionary } = useGetAssetName(projectRef, items.map((it) => it.asset) || []);

	const getLabelToShow = () => {
		if (!deliveryFeatureActive && label === "in_progress_local_assets") {
			return i18n.format(t("local_assets"), "capitalize");
		}
		return i18n.format(t(label), "capitalize");
	};

	return (
		<IonItemGroup>
			<IonItemDivider mode="md" key={`header_${label}`} color="secondary">
				<IonLabel
					className="ion-text-wrap"
					data-testid="assetNameLabel"
					color="primary"
					style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
				>
					{getLabelToShow()}
				</IonLabel>
			</IonItemDivider>
			{items.map((assetWithSyncStatus: AssetWithSyncStatus) => {
				const { asset, syncStatus } = assetWithSyncStatus;
				const assetDeliveryStatus = asset.status?.status ?? "inProgress";
				return (
					<IonItem
						key={`asset_${asset.id}`}
						button={syncStatus !== "remote"}
						detail={syncStatus !== "remote"}
						data-testid="assetNameItem"
						routerLink={buildRouterLink(asset, syncStatus)}
						style={{
							color: assetDeliveryStatus === "discarded" ? "black" : undefined,
							"--background": assetDeliveryStatus === "discarded" ? "#FFEEEF" : undefined,
						}}
					>
						{deliveryFeatureActive && (
							<IonIcon
								style={{
									marginRight: "1rem",
									height: "1rem",
									width: "1rem",
									color: selectStatusColor(assetDeliveryStatus),
									opacity: syncStatus === "remote" ? 0.3 : undefined,
								}}
								icon={assetDeliveryStatus === "inProgress" ? ellipseOutline : circle}
							/>
						)}
						<IonLabel
							className="ion-text-wrap"
							data-testid="assetNameLabel"
							style={{
								display: "flex",
								alignItems: "center",
								color: syncStatus === "remote" ? "gray" : undefined,
							}}
						>
							{nameDictionary[asset.id] || asset.name}
						</IonLabel>
						{projectRef === "adif-ite" && syncStatus !== "remote" && (
							<IonButton
								fill={isOnline ? "solid" : "clear"}
								disabled={!isOnline || syncStatus !== "synced"}
								color="secondary"
								data-testid="userPanelButton"
								size="default"
								style={{
									"--box-shadow": "unset",
									"--padding-start": 0,
									"--padding-end": 0,
									aspectRatio: "1 / 1",
									height: "2rem",
									margin: "0.5rem 0.4rem",
								}}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setAssetToDuplicate(asset);
								}}
							>
								<IonIcon
									src={copyOutline}
									style={{
										color: "var(--ion-color-medium-tint)",
									}}
								/>
							</IonButton>
						)}
						{asset.can_be_deleted && syncStatus !== "remote" && (
							<div>
								<IonButton
									fill={isOnline ? "solid" : "clear"}
									disabled={!isOnline}
									color="secondary"
									data-testid="userPanelButton"
									size="default"
									style={{
										"--box-shadow": "unset",
										"--padding-start": 0,
										"--padding-end": 0,
										aspectRatio: "1 / 1",
										height: "2rem",
										margin: "0.5rem 0.4rem",
									}}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setAssetToDelete(asset.id);
									}}
								>
									<IonIcon
										src={trashOutline}
										style={{
											color: "var(--ion-color-medium-tint)",
										}}
									/>
								</IonButton>
							</div>
						)}
						<SyncButton
							asset={asset}
							isOnline={isOnline}
							assetDeliveryStatus={assetDeliveryStatus}
							syncStatus={syncStatus}
							setActiveSync={setActiveSync}
						/>
					</IonItem>
				);
			})}
		</IonItemGroup>
	);
};

export default AssetGroup;
