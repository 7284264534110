import * as React from "react";

const SvgComponent = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" {...props}>
		<path
			fill="#FFF"
			d="M60.958 8H18.27A1.27 1.27 0 0 0 17 9.27v77.46c0 .701.569 1.27 1.27 1.27h59.682a1.27 1.27 0 0 0 1.27-1.27V26.264c0-.786-.312-1.54-.868-2.095L63.053 8.868A2.963 2.963 0 0 0 60.958 8z"
		/>
		<path
			fill="#979593"
			d="M79.121 24.707 61.293 6.879A2.978 2.978 0 0 0 59.172 6H19c-1.654 0-3 1.346-3 3v78c0 1.654 1.346 3 3 3h58c1.654 0 3-1.346 3-3V26.828c0-.8-.313-1.554-.879-2.121zM60 8.414 77.586 26H61a1 1 0 0 1-1-1V8.414zM77 88H19a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h39v17c0 1.654 1.346 3 3 3h17v59a1 1 0 0 1-1 1z"
		/>
		<path
			fill="#C8C6C4"
			d="M71 61H25a1 1 0 1 1 0-2h46a1 1 0 1 1 0 2zm0-6H25a1 1 0 1 1 0-2h46a1 1 0 1 1 0 2zm0-6H25a1 1 0 1 1 0-2h46a1 1 0 1 1 0 2zm0-6H25a1 1 0 1 1 0-2h46a1 1 0 1 1 0 2zm0 24H25a1 1 0 1 1 0-2h46a1 1 0 1 1 0 2z"
		/>
	</svg>
);

export default SvgComponent;
