import React, { useEffect, useState } from "react";

import { IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonSpinner } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { deleteAsset } from "api/assets";
import { alertTriangle } from "assets/icons";
import { Button } from "components/common/Button";
import Asset from "models/Asset";
import { useAppDispatch } from "store";
import { performBackgroundSyncThunk } from "store/slices/remote/thunks";

interface IProps {
	isOpen: boolean;
	projectRef: string;
	assetId: string;
	onClose: () => void;
}

export const ConfirmDeleteModal: React.FC<IProps> = (props: IProps) => {
	const { isOpen, projectRef, assetId, onClose } = props;
	const { t, i18n } = useTranslation();
	const [assetName, setAssetName] = useState("");
	const [inputValid, setInputValid] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		let isCancelled = false;
		const getAssetName = async () => {
			if (!assetId) return;
			const asset = await Asset.get(assetId);
			if (isCancelled) return;
			setAssetName(asset.name);
		};
		getAssetName();
		return () => {
			isCancelled = true;
		};
	}, [assetId]);

	return (
		<IonModal isOpen={isOpen} canDismiss={true} backdropDismiss={true}>
			<IonHeader
				style={{
					height: "3rem",
					background: "var(--ion-background-color, #fff)",
				}}
			>
				<IonItem lines="none">
					<IonLabel
						style={{
							marginLeft: "3rem",
							display: "flex",
							justifyContent: "center",
							fontSize: "0.875rem",
							fontWeight: "400",
						}}
					>
						{`Elimina ${assetName}`}
					</IonLabel>
					<IonIcon
						style={{
							height: "100%",
							width: "2rem",
							color: "black",
							alignSelf: "center",
							paddingTop: "0.5rem",
							paddingBottom: "0.5rem",
							cursor: "pointer",
						}}
						icon={closeOutline}
						onClick={onClose}
					/>
				</IonItem>
			</IonHeader>
			<IonContent forceOverscroll={false}>
				<div
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						paddingInline: "2rem",
					}}
				>
					<div>
						<IonItem
							lines="none"
							style={{
								marginTop: "2.5rem",
								marginBottom: "0.5rem",
								height: "6rem",
								width: "100%",
							}}
						>
							<div
								style={{
									marginLeft: "1rem",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									height: "5.125rem",
									width: "100%",
								}}
							>
								<IonIcon
									style={{
										height: "5.125rem",
										width: "5.125rem",
									}}
									icon={alertTriangle}
								/>
								<IonLabel style={{ height: "2rem" }}>
									{i18n.format(t("delete_no_reversible_operation"), "capitalize")}
								</IonLabel>
							</div>
						</IonItem>
						<IonItem>
							<IonInput
								label={i18n.format(t("delete_input_label"), "capitalize")}
								labelPlacement="stacked"
								onIonInput={(ev) => {
									const value = ev.detail.value;
									setInputValid(value === "eliminar" || value === "delete");
								}}
							></IonInput>
						</IonItem>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							marginInline: "1rem",
							marginBottom: "2rem",
						}}
					>
						<Button
							variant="filled"
							onClickFunction={async () => {
								setInProgress(true);
								await deleteAsset(assetId);
								await dispatch(performBackgroundSyncThunk({ projectRef }));
								setInProgress(false);
								onClose();
							}}
							disabled={!inputValid || inProgress}
						>
							{inProgress ? <IonSpinner name="crescent" /> : i18n.format(t("delete_confirmation_button"), "capitalize")}
						</Button>
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};
