import React, { useEffect, useState } from "react";

import { IonActionSheet, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import { save, trash, close, documentOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { get, UseFormReturn } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { arrowLeft, home } from "assets/icons";
import { useAssetName } from "components/common/Form/utils/useAssetName";
import { FormField } from "models/Form";
import FormRecord, { FormValues } from "models/FormRecord";
import { useAppDispatch, useAppSelector } from "store";
import { actions as historyActions } from "store/slices/history";
import doRecursively from "utils/recursive/doRecursively";
import flattenObject from "utils/recursive/flattenObject";

import bakeHistoryInFullPath from "../../utils/bakeHistoryInFullPath";

interface IProps {
	formFields?: FormField[];
	formMethods?: UseFormReturn<FormValues>;
	exitForm: () => void;
	onSubmit: (values: FormValues, changes: string[], history: string[], exit?: boolean, stay?: boolean) => Promise<void>;
	onPrint?: () => void;
}

export const Topbar: React.FC<IProps> = (props) => {
	const { formFields, formMethods, exitForm, onSubmit, onPrint } = props;

	const { projectRef } = useParams<{
		projectRef: string;
	}>();
	const { t, i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const historySlice = useAppSelector((store) => store.history);
	const { assetId, recordId, isFrozen } = useAppSelector((state) => state.form);
	const fieldLibrary = useAppSelector((store) => store.form.fieldReferenceLibrary);
	const history = useHistory();
	const location = useLocation();
	const { assetName } = useAssetName(projectRef, assetId);
	const [confirmDiscardFlag, setConfirmDiscardFlag] = useState<"exit" | "back">();

	// Parse pageId query parameter and update history accordingly
	useEffect(() => {
		const pageId = new URLSearchParams(location.search).get("pageId");
		if (!pageId) return;
		const fullPath = bakeHistoryInFullPath(fieldLibrary[pageId]?.path || [], historySlice.list) || [];
		dispatch(historyActions.setHistory(fullPath));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, location.search]);

	// Update pageId query parameter on page history change
	useEffect(() => {
		// Don't change pageId when entering repeatable items, keep parent's
		if (/^[0-9]+$/.test(historySlice.active ?? "")) return;
		// Otherwise, update pageId
		history.push({
			search: new URLSearchParams(historySlice.active && { pageId: historySlice.active }).toString(),
		});
	}, [history, historySlice.active, location.pathname]);

	// If pageId is InlineGroup, go to parent
	useEffect(() => {
		const allInlineGroupNames: string[] = Object.values(
			doRecursively<string>({
				fields: formFields || [],
				action: ({ path, field }) => ({ [path.join(".")]: field.name }),
				shouldAction: ({ field }) => field.type === "inlineGroup",
			}),
		);
		const pageId = new URLSearchParams(location.search).get("pageId");
		if (Object.values(allInlineGroupNames).includes(String(pageId))) {
			dispatch(historyActions.popHistory());
		}
	}, [dispatch, historySlice.active]);

	const goBack = () => {
		if (historySlice.list.length === 0) exitForm();
		else dispatch(historyActions.popHistory());
	};

	const handleBack = (exit: boolean) => {
		if (!formMethods || isFrozen) {
			return exit ? exitForm() : goBack();
		}
		let localDirtyFields;
		if (historySlice.absolutePath === "") {
			localDirtyFields = formMethods.formState.dirtyFields;
		} else {
			localDirtyFields = get(formMethods.formState.dirtyFields || {}, historySlice.absolutePath);
		}
		const shouldChangeDiscardFlag = Object.keys(localDirtyFields || {}).length > 0;
		if (shouldChangeDiscardFlag) {
			return setConfirmDiscardFlag(exit ? "exit" : "back");
		} else {
			return exit ? exitForm() : goBack();
		}
	};

	const handleSave = async () => {
		if (!formMethods) return;
		await formMethods.trigger(undefined, { shouldFocus: true }).then(console.log).catch(console.log);
		const changes = Object.keys(flattenObject(formMethods.formState.dirtyFields));
		await formMethods.handleSubmit(async (values) => {
			await onSubmit(values, changes, historySlice.list, confirmDiscardFlag === "exit", confirmDiscardFlag !== "back");
			formMethods.reset(values);
		}, console.error)();
	};

	const handleDiscard = async () => {
		if (!recordId) return;
		const record = await FormRecord.get(recordId);
		formMethods?.reset(record.data);
		if (confirmDiscardFlag === "exit") {
			exitForm();
		} else {
			goBack();
		}
	};

	return (
		<React.Fragment>
			<IonHeader mode="ios">
				<IonToolbar style={{ alignItems: "center", "--border-width": 0 }}>
					<IonButtons slot="start">
						<IonButton
							fill="clear"
							color="secondary"
							data-testid="formBackButton"
							style={{ marginTop: 0, marginBottom: 0 }}
							onClick={() => handleBack(false)}
						>
							<IonIcon
								icon={arrowLeft}
								size="small"
								style={{
									strokeWidth: "50%",
									color: "#718096",
									cursor: "pointer",
								}}
							/>
						</IonButton>
					</IonButtons>

					<IonTitle
						style={{
							// display: "flex",
							// justifyContent: "center",
							fontSize: "0.666rem",
							fontWeight: "400",
						}}
					>
						{assetName}
					</IonTitle>
					<IonButtons slot="end">
						{onPrint && (
							<IonButton color="secondary" fill="clear" onClick={onPrint}>
								<IonIcon
									src={documentOutline}
									size="small"
									style={{
										color: "#718096",
										cursor: "pointer",
										"--ionicon-stroke-width": "2.75rem",
									}}
								/>
							</IonButton>
						)}
						<IonButton color="secondary" fill="clear" onClick={() => handleBack(true)}>
							<IonIcon
								src={home}
								size="small"
								style={{
									color: "#718096",
									cursor: "pointer",
								}}
							/>
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonActionSheet
				isOpen={confirmDiscardFlag !== undefined}
				header={i18n.format(t("discard_changes_msg"), "capitalize")}
				buttons={[
					{
						text: i18n.format(t("save"), "capitalize"),
						icon: save,
						id: "saveChanges",
						handler: () => handleSave(),
					},
					{
						text: i18n.format(t("discard"), "capitalize"),
						icon: trash,
						id: "discardChanges",
						role: "destructive",
						handler: handleDiscard,
					},
					{
						text: i18n.format(t("cancel"), "capitalize"),
						icon: close,
						id: "cancelChanges",
						role: "cancel",
					},
				]}
				onDidDismiss={() => setConfirmDiscardFlag(undefined)}
			/>
		</React.Fragment>
	);
};

export default Topbar;
