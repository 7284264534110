export const buildMapPin = (id: string, size: string): HTMLDivElement => {
	const element = document.createElement("div");
	element.setAttribute("id", id);
	element.style.width = size;
	element.style.height = size;
	element.style.zIndex = "100";
	element.style.opacity = "1";
	element.style.display = "block";

	const urlNS = "http://www.w3.org/2000/svg";
	const svg = document.createElementNS(urlNS, "svg");
	svg.setAttributeNS(null, "width", "100%");
	svg.setAttributeNS(null, "height", "100%");
	svg.setAttributeNS(null, "viewBox", `0 0 24 24`);
	svg.setAttributeNS(null, "justify-content", `center`);
	svg.setAttributeNS(null, "display", `flex`);
	svg.setAttributeNS(null, "align-items", `center`);

	element.appendChild(svg);

	const path = document.createElementNS(urlNS, "path");
	path.setAttributeNS(null, "d", `M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z`);
	path.setAttributeNS(null, "fill", "#006935");

	const circle = document.createElementNS(urlNS, "circle");
	circle.setAttributeNS(null, "cx", `12`);
	circle.setAttributeNS(null, "cy", `10`);
	circle.setAttributeNS(null, "r", `3`);
	circle.setAttributeNS(null, "fill", "#ffff");

	svg.appendChild(path);
	svg.appendChild(circle);
	return element;
};
