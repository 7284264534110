import * as React from "react"

const SvgComponent = (props) => (
	<svg
		width={626.382}
		height={591.317}
		viewBox="0 0 165.73 156.453"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<defs>
			<linearGradient
				xlinkHref="#a"
				id="b"
				gradientUnits="userSpaceOnUse"
				x1={110.829}
				y1={729.648}
				x2={110.829}
				y2={596.074}
				gradientTransform="matrix(0 -.70582 .70582 0 -492.739 121.481)"
			/>
			<linearGradient id="a">
				<stop
					style={{
						stopColor: "#285294",
						stopOpacity: 1,
					}}
					offset={0}
				/>
				<stop
					style={{
						stopColor: "#2c5a9e",
						stopOpacity: 1,
					}}
					offset={1}
				/>
			</linearGradient>
		</defs>
		<g
			transform="matrix(.70582 0 0 .70582 69.118 -376.558)"
			style={{
				fill: "#fff",
				fillOpacity: 1,
				stroke: "none",
			}}
		>
			<title>{"Rectangle.305"}</title>
			<path
				className="st1"
				style={{
					fill: "#fff",
					stroke: "none",
					strokeWidth: 0.25,
					fillOpacity: 1,
				}}
				d="M0 560.896h133.575v168.75H0z"
			/>
		</g>
		<g
			transform="matrix(.70582 0 0 .70582 56.829 -373.86)"
			style={{
				fill: "#2a5699",
				fillOpacity: 1,
				stroke: "none",
			}}
		>
			<title>{"rect307.6"}</title>
			<path
				d="M9.48 551.34c-5.25 0-9.48 4.21-9.48 9.45V720.2c0 5.23 4.23 9.45 9.48 9.45h135.33c5.25 0 9.48-4.22 9.48-9.45V560.79c0-5.24-4.23-9.45-9.48-9.45zm-1.9 7.83h139.05v162.56H7.58Z"
				className="st2"
				style={{
					fill: "#2a5699",
					stroke: "none",
					strokeWidth: 0.683161,
					strokeLinecap: "round",
					strokeLinejoin: "round",
					fillOpacity: 1,
				}}
			/>
		</g>
		<g
			transform="matrix(.70582 0 0 .70582 80.114 -390.654)"
			style={{
				fill: "#2a5699",
				fillOpacity: 1,
				stroke: "none",
			}}
		>
			<title>{"Sheet.14"}</title>
			<g
				transform="translate(0 -119.857)"
				style={{
					fill: "#2a5699",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle"}</title>
				<path
					className="st3"
					style={{
						fill: "#2a5699",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 719.538h97.416v10.108H0z"
				/>
			</g>
			<g
				transform="translate(0 -72.203)"
				style={{
					fill: "#2a5699",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.9"}</title>
				<path
					className="st3"
					style={{
						fill: "#2a5699",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 719.538h97.416v10.108H0z"
				/>
			</g>
			<g
				transform="translate(0 -96.03)"
				style={{
					fill: "#2a5699",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.10"}</title>
				<path
					className="st3"
					style={{
						fill: "#2a5699",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 719.538h97.416v10.108H0z"
				/>
			</g>
			<g
				transform="translate(0 -48.376)"
				style={{
					fill: "#2a5699",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.11"}</title>
				<path
					className="st3"
					style={{
						fill: "#2a5699",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 719.538h97.416v10.108H0z"
				/>
			</g>
			<g
				transform="translate(0 -23.827)"
				style={{
					fill: "#2a5699",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.12"}</title>
				<path
					className="st3"
					style={{
						fill: "#2a5699",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 719.538h97.416v10.108H0z"
				/>
			</g>
			<g
				style={{
					fill: "#2a5699",
					fillOpacity: 1,
					stroke: "none",
				}}
			>
				<title>{"Rectangle.13"}</title>
				<path
					className="st3"
					style={{
						fill: "#2a5699",
						stroke: "none",
						strokeWidth: 0.25,
						fillOpacity: 1,
					}}
					d="M0 719.538h97.416v10.108H0z"
				/>
			</g>
		</g>
		<path
			d="M22.265 121.481V-34.97l-94.284 15.64v125.164Z"
			className="st25"
			style={{
				fill: "#2a5699",
				fillOpacity: 1,
				stroke: "none",
				strokeWidth: 0.176455,
			}}
			transform="translate(72.02 34.971)"
		/>
		<g
			transform="matrix(.70582 0 0 .70582 16.309 -411.504)"
			style={{
				stroke: "none",
				fill: "#fff",
				fillOpacity: 1,
			}}
		>
			<title>{"Sheet.21"}</title>
			<path
				d="m0 660.6 13 66.1c4.647.418 9.306.695 13.97.83l9.52-43.47c.395-1.38.566-2.888.73-5.78.179 2.916.293 3.965.72 5.78l9.41 44.68 15.22.91 16.89-73.78-14.9.89-8.57 48.96c-.422 1.99-.716 3.997-.72 5.77.146-1.463-.098-3.424-.72-5.77l-9.52-47.8-13.45.8-10.25 47c-.273.923-.54 2.587-.72 4.33-.07-1.498-.303-3.485-.72-5.78l-6.93-44.44z"
				className="st24"
				style={{
					fill: "#fff",
					stroke: "none",
					strokeWidth: 0.75,
					fillOpacity: 1,
				}}
			/>
		</g>
	</svg>
);

export default SvgComponent
