import React from "react";

import { IonItem, IonList, IonLabel } from "@ionic/react";
import { UseFormReturn } from "react-hook-form";

import { IImage } from "../../../../interfaces/IImage";
import { FormField } from "../../../../models/Form";
import { FormValues } from "../../../../models/FormRecord";
import Gallery from "../components/Gallery";
import SketchCanvas from "../components/SketchCanvas";
import useFieldTemplate from "../hooks/useFieldTemplate";

interface IProps {
	field: FormField<IImage[]>;
	formMethods: UseFormReturn<FormValues>;
}

const Sketches: React.FC<IProps> = (props) => {
	const { field, formMethods } = props;
	const { isRelevant, isRequired, isHardRequired, isDisabled, isFrozen } = useFieldTemplate(field, formMethods.control);

	return (
		<div
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{isRelevant() ? (
				<IonItem lines="none">
					<IonList style={{ width: "100%" }}>
						<IonLabel className="ion-text-wrap capitalize-first-letter" position="stacked" mode="ios">
							{field.label}
							{(isRequired() || isHardRequired()) && <span style={{ color: "red" }}>&nbsp;*</span>}
						</IonLabel>
						<Gallery formMethods={formMethods} field={field} variant="image">
							{!isFrozen && !isDisabled() && <SketchCanvas formMethods={formMethods} field={field} />}
						</Gallery>
					</IonList>
				</IonItem>
			) : null}
		</div>
	);
};
export default Sketches;
