import React from "react";
import { IonItem, IonLabel } from "@ionic/react";

import { IImage } from "interfaces/IImage";
import { IFile } from "interfaces/IFile";
import { FormField } from "models/Form";
import { IDataValue } from "models/FormRecord";
import ReportGallery from "./ReportGallery";

interface ReportImageProps {
	formField: FormField;
	value: IDataValue;
	highlight?: boolean;
}
export const ReportImage: React.FC<ReportImageProps> = (props: ReportImageProps) => {
	const { value, formField, highlight = false } = props;
	return (
		<div className="no-break" id={`field-${formField.name}`}>
			{value && (
				<IonItem lines="none">
					<IonLabel className="ion-text-wrap" position="stacked" mode="ios">
						<div style={{ color: "var(--ion-color-medium)" }}>{formField.label}</div>
					</IonLabel>
					<div className={highlight ? "highlighted" : undefined}>
						<ReportGallery value={value as IImage[] | IFile[]} variant="image" />
					</div>
				</IonItem>
			)}
		</div>
	);
};
