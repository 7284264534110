import React from "react";

import { useForm } from "react-hook-form";

import { Button } from "components/common/Button";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";

import Input from "../../common/Form/widgets/Input";
import RadioGroup from "../../common/Form/widgets/RadioGroup";
import Select from "../../common/Form/widgets/Select";
import { useTranslation } from "react-i18next";
import { IonList } from "@ionic/react";

interface IProps {
	filterFields: FormField[];
	initialFilters: FormValues;
	setFilters: (values: FormValues) => void;
	resetFilters: () => void;
}

const Filters = React.forwardRef<HTMLFormElement, IProps>((props, ref) => {
	const { filterFields, initialFilters, setFilters, resetFilters } = props;
	const { t, i18n } = useTranslation();

	const methods = useForm({
		defaultValues: initialFilters,
		reValidateMode: "onBlur",
	});

	const { handleSubmit } = methods;

	const checkAndTransformData = (data: FormValues): FormValues => {
		let transformedData: FormValues = {};
		for (const key in data) {
			if (data[key] !== null) {
				if (typeof data[key] === "object") {
					transformedData = {
						...transformedData,
						...getObjectInnerValue(data[key] as never),
					};
				} else {
					transformedData[key] = data[key];
				}
			}
		}
		return transformedData;
	};

	const getObjectInnerValue = (value: Record<string, never>) => {
		let objectValues: FormValues = {};
		for (const key in value) {
			if (value[key] !== null) {
				if (typeof value[key] === "object") {
					objectValues = {
						...objectValues,
						...getObjectInnerValue(value[key] as never),
					};
				} else {
					objectValues[key] = value[key];
				}
			}
		}
		return objectValues;
	};

	return (
		<form
			id="filters-form"
			ref={ref}
			onSubmit={handleSubmit(
				(data) => {
					setFilters(checkAndTransformData(data));
				},
				(errors) => {
					console.error(errors);
					throw new Error("Error submitting filters form");
				},
			)}
			style={{ height: "calc(100% - 6rem)" }}
		>
			<IonList
				style={{
					display: "flex",
					height: "100%",
					flexDirection: "column",
					justifyContent: "space-between",
					paddingTop: "0rem",
				}}
			>
				<div>
					{filterFields.map((field: FormField) => {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { required, hardRequired, ...basicField } = field;
						switch (field.type) {
							case "radio":
								return <RadioGroup key={field.name} formMethods={methods} field={basicField as FormField<string>} />;
							case "dropdown":
								return <Select key={field.name} formMethods={methods} field={basicField as FormField<string>} />;
							default:
								return <Input key={field.name} formMethods={methods} field={basicField as FormField<string>} />;
						}
					})}
				</div>
				<div
					style={{
						marginTop: "1rem",
						marginBottom: "1rem",
						display: "flex",
						flexDirection: "column",
						justifyItems: "center",
						marginInline: "1rem",
					}}
				>
					<Button onClickFunction={resetFilters} variant="empty" type="reset">
						{i18n.format(t("clear_filters"), "capitalizeEveryWord")}
					</Button>
					<Button variant="filled" type="submit">
						{i18n.format(t("apply_filters"), "capitalizeEveryWord")}
					</Button>
				</div>
			</IonList>
		</form>
	);
});
Filters.displayName = "Filters";
export default Filters;
