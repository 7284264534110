import React, { memo, PropsWithChildren } from "react";

import { IonCheckbox, IonIcon, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";
import { Controller, UseFormReturn } from "react-hook-form";

import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";

import useFieldTemplate from "../hooks/useFieldTemplate";
import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";

interface IProps {
	field: FormField<string[]>;
	formMethods: UseFormReturn<FormValues>;
}

type PropsType = IProps;
const MultiRadioGroup: React.FC<PropsType> = (props) => {
	const { field, formMethods } = props;
	const { name, alert, isValid, isRelevant, isRequired, isDisabled, isHardRequired, isFrozen } = useFieldTemplate(
		field,
		formMethods.control,
	);

	const validChoices = (field.choices || []).filter((choice) => isValid({ choice }));
	if (field.sortChoices === "ascending") validChoices.sort((a, b) => a.label.localeCompare(b.label));
	else if (field.sortChoices === "descending") validChoices.sort((a, b) => b.label.localeCompare(b.label));

	return (
		<div
			className="Input"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{isRelevant() && (
				<Controller
					name={name}
					control={formMethods.control}
					rules={{
						required: isHardRequired() && isRelevant(),
					}}
					defaultValue={((field.defaultValue as string[]) || []).map(String)}
					render={({ field: fieldRenderProps }) => {
						const currentValue = fieldRenderProps.value as string[] | undefined;
						return (
							<IonItem
								lines="none"
								style={{
									backgroundColor: "transparent",
								}}
								disabled={isFrozen || isDisabled()}
							>
								<IonLabel className="ion-text-wrap" position="stacked" mode="ios" style={{ paddingBottom: "0.1rem" }}>
									{field.label}
									{(isRequired() || isHardRequired()) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<div
									// React Hook Form Managed
									ref={fieldRenderProps.ref}
									style={{
										width: "100%",
										marginTop: "0.1rem",
									}}
								>
									{validChoices.map((choice, i) => (
										<IonItem
											key={`${field.name}-choice-${i}`}
											lines="none"
											style={
												{
													"--inner-padding-start": "0",
													"--inner-padding-end": "1rem",
												} as React.CSSProperties
											}
										>
											<IonCheckbox
												aria-label={`${field.type}:${choice.value}`}
												data-testid={`${field.type}:checkbox:${choice.value}`}
												mode="md"
												slot="start"
												color="dark"
												name={choice.label}
												value={choice.value}
												checked={currentValue && currentValue.includes(choice.value)}
												onIonChange={(e) => {
													setTimeout(() => {
														// TODO: this is to solve a race condition
														if (e.detail.checked) fieldRenderProps.onChange([...(currentValue || []), e.detail.value]);
														else fieldRenderProps.onChange((currentValue || []).filter((v) => v !== e.detail.value));
													}, 0);
												}}
												onIonBlur={fieldRenderProps.onBlur}
												labelPlacement="end"
												justify="start"
												style={{
													marginInlineEnd: ".5rem",
													width: "100%",
												}}
											>
												<span className="ion-text-wrap">{choice.label}</span>
											</IonCheckbox>
										</IonItem>
									))}
								</div>
								{alert && (
									<IonNote
										data-testid={`${field.type}:note`}
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						);
					}}
				/>
			)}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(MultiRadioGroup, propsAreEqual);
export default Memoized;
