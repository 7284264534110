export const evictUrlFromCache = async (filepath: string, authorization: string, cache: Cache) => {
	const origin =
		window.location.origin === "http://localhost:3000" ? "https://forms.dev.dhub.arup.com" : window.location.origin;
	const headers = { Authorization: `Bearer ${authorization}` };
	try {
		cache.delete(
			new Request(`${origin}/thumbnails/` + filepath, {
				headers,
			}),
		);
	} catch {
		// empty
	}
	try {
		cache.delete(
			new Request(`${origin}/thumbnails/` + filepath, {
				headers,
			}),
		);
	} catch {
		// empty
	}
};
