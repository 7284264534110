import Asset from "models/Asset";
import FormRecord from "models/FormRecord";
import { useEffect, useState } from "react";
import { get } from "react-hook-form";

export const useAssetName = (
	projectRef: string,
	assetId?: string,
): {
	assetName?: string;
} => {
	const [assetName, setAssetName] = useState<string>();

	useEffect(() => {
		if (!assetId) return;
		let isCancelled = false;

		const isAdif = projectRef === "adif-ite" || projectRef === "qa-test";
		Asset.get(assetId).then((asset) => {
			if (isCancelled) return;
			setAssetName(asset.name);
			if (isAdif) {
				FormRecord.byAssetId(assetId).then((records) => {
					const fichaInventario = records.find((it) => it.form_id.includes("inventario"));
					if (fichaInventario) {
						const nameFromRecord: string = get(
							fichaInventario.data,
							"datosGeneralesActivoFichaInventario.nombreActivo",
						);
						if (nameFromRecord && nameFromRecord !== "") {
							setAssetName(nameFromRecord);
						}
					}
				});
			}
		});
		return () => {
			isCancelled = true;
		};
	}, []);

	return { assetName };
};
