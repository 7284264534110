import React from "react";

import { IonButton, IonIcon, IonSpinner } from "@ionic/react";

import Asset from "models/Asset";
import { SyncStatus } from "utils/sync/isSynced";
import { getSyncIconDetails } from "utils/sync/getSyncIconDetails";
import { ActiveSyncProps } from "../AssetsView";
import { useAppSelector } from "store";

interface IProps {
	asset: Asset;
	isOnline: boolean;
	assetDeliveryStatus: string;
	syncStatus: SyncStatus | undefined;
	setActiveSync: React.Dispatch<React.SetStateAction<ActiveSyncProps | undefined>>;
}

const SyncButton: React.FC<IProps> = (props: IProps) => {
	const { asset, isOnline, assetDeliveryStatus, syncStatus, setActiveSync } = props;
	const remoteSliceState = useAppSelector((state) => state.remote.thunkState);
	const syncIconDetails = getSyncIconDetails(syncStatus, isOnline);

	const onClick = async () => {
		setActiveSync({
			id: asset.id,
			title: asset.name,
			skipBackgroundSync: syncStatus && ["remote", "download"].includes(syncStatus),
		});
	};

	return (
		<IonButton
			slot="end"
			color={assetDeliveryStatus === "discarded" ? "none" : "secondary"}
			data-testid="syncButton"
			size="default"
			fill={isOnline ? "solid" : "clear"}
			disabled={!isOnline}
			style={{
				"--box-shadow": "unset",
				"--padding-start": 0,
				"--padding-end": 0,
				aspectRatio: "1 / 1",
				height: "2rem",
				margin: "0.5rem 0.4rem",
				marginRight: syncStatus === "remote" ? "2.25rem" : "1rem",
				backgroundColor: assetDeliveryStatus === "discarded" ? "#f5eef2" : "var(--ion-color-secondary)",
			}}
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
				onClick();
			}}
		>
			{remoteSliceState !== "idle" || !syncIconDetails.icon || !isOnline ? (
				<IonIcon
					style={{
						color: "var(--ion-color-medium-tint)",
					}}
					icon={syncIconDetails.icon}
				/>
			) : (
				<IonSpinner name="lines-sharp" color="medium" style={{ margin: "20%" }} />
			)}
		</IonButton>
	);
};

export default SyncButton;
