import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import formReducer from "./slices/form";
import historyReducer from "./slices/history";
import issueFilterReducer from "./slices/filters";
import remoteReducer from "./slices/remote";
import swReducer from "./slices/sw";
import connectionReducer from "./slices/connection";
import userFilterReducer from "./slices/user";
import assetFilterReducer from "store/slices/assetFilter/assetFilterSlice";

const store = configureStore({
	reducer: {
		sw: swReducer,
		filters: issueFilterReducer,
		history: historyReducer,
		form: formReducer,
		remote: remoteReducer,
		connection: connectionReducer,
		assetFilter: assetFilterReducer,
		bim: assetFilterReducer,
		user: userFilterReducer,
	},
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
