import React from "react";

import { IFile } from "interfaces/IFile";
import { IImage } from "interfaces/IImage";
import ImageFallback from "components/common/Form/components/Gallery/ImageFallback";
import FileThumbnail from "components/common/Form/components/Gallery/FileThumbnail";

import "components/common/Form/components/Gallery/Gallery.styles.css";

interface IProps {
	variant: "image" | "file";
	value: IImage[] | IFile[];
}

type PropsType = IProps;
const Gallery: React.FC<PropsType> = (props) => {
	const { value, variant } = props;
	const available = (value || []).filter((it) => !it._is_deleted);

	return (
		<>
			<div className="gallery">
				{available.map((it) =>
					variant === "image" ? (
						<ImageFallback
							key={it.filepath}
							style={{
								cursor: "pointer",
								objectFit: "contain",
							}}
							photo={it}
						/>
					) : (
						<FileThumbnail key={it.filepath} file={it as IFile} />
					),
				)}
			</div>
		</>
	);
};
export default Gallery;
