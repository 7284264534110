import { apiClient } from "api";

interface LocalAssetAnalytics {
	total: number;
	bySyncStatus: {
		synced: number;
		toUpload: number;
	};
	byDeliveryStatus: Record<string, number>;
	byLastSynced: Record<`${number}d`, number>;
}

interface StorageAnalyticsAPIInput {
	device_id: string;
	storage_estimate?: StorageEstimate;
	local_asset_analytics?: LocalAssetAnalytics;
}

export const putAnalytics = async (analytics: StorageAnalyticsAPIInput) => {
	return apiClient.put("forms/storageAnalytics", analytics);
};
