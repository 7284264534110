import Form, { FormField } from "models/Form";
import { IDataValue } from "models/FormRecord";
import doRecursively from "utils/recursive/doRecursively";

export async function findFields(formId: string, fields: string[]): Promise<FormField<IDataValue>[]> {
	const form = await Form.get(formId);

	const foundFields = doRecursively({
		fields: form.fields,
		action: (it) => ({
			[it.path.join(".")]: it.field,
		}),
		shouldAction: (it) => {
			return fields.includes(it.path.join("."));
		},
		shouldDive: ({ field }) => field.type !== "repeatableGroup",
	});

	return Object.values(foundFields);
}
