import React, { memo, PropsWithChildren, useEffect, useMemo } from "react";

import { UseFormReturn } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { FormField } from "models/Form";
import { FormValues, IDataValue } from "models/FormRecord";
import { useAppDispatch, useAppSelector } from "store";
import { actions as historyActions } from "store/slices/history";
import flattenObject from "utils/recursive/flattenObject";

import FormPageHeader from "./FormPageHeader";
import FormPageContent from "./FormPageContent";
import FormPageRepeatable from "./FormPageRepeatable";
import { getEmail } from "../../../../../utils/getEmail";
import { updateRepeatableAdditionalData as addRepeatableUpdatedAtUpdatedBy } from "./addRepeatableUpdatedAtUpdatedBy";

interface IProps {
	field?: FormField;
	childrenFields: FormField[];
	formMethods: UseFormReturn<FormValues>;
	onSubmit: (values: FormValues, changes: string[], history: string[], exit?: boolean, stay?: boolean) => Promise<void>;
	setFilterModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isRepeatableItemPage?: boolean;
}
type PropsType = IProps;

const FormPage: React.FC<PropsType> = (props) => {
	const { field, childrenFields, formMethods, onSubmit, setFilterModalIsOpen, isRepeatableItemPage = false } = props;

	const dispatch = useAppDispatch();
	const historySlice = useAppSelector((store) => store.history);
	const isFrozen = useAppSelector((store) => store.form.isFrozen);
	const fieldReferenceLibrary = useAppSelector((store) => store.form.fieldReferenceLibrary);

	const siblingTypes = useMemo(() => {
		if (field) {
			return field.children?.map((it) => it.type) || [];
		} else {
			return childrenFields.map((it) => it.type);
		}
	}, [field, childrenFields]);

	const isRepeatableTable = field && field.type === "repeatableGroup";

	const isActiveRepeatableItem =
		field && field.type === "repeatableGroup" && field.name === historySlice.list[historySlice.list.length - 2]; // the parent of the active element in history is the one specified

	const isInlineGroup = field && field.type === "inlineGroup";

	useEffect(() => {
		if (isInlineGroup && historySlice.active === field.name) {
			dispatch(historyActions.popHistory());
		}
	}, [dispatch, historySlice]);

	const handleSave = async (exit = false, stay = true) => {
		const user = await getEmail();

		await formMethods.trigger(undefined, { shouldFocus: true }).then(console.log).catch(console.log);
		const changes = Object.keys(flattenObject(formMethods.formState.dirtyFields));

		addRepeatableUpdatedAtUpdatedBy(changes, fieldReferenceLibrary, formMethods, user);

		await formMethods.handleSubmit(async (values) => {
			formMethods.reset(values);
			await onSubmit(values, changes, historySlice.list, exit, stay);
		}, console.error)();
	};

	const handleAddItem = async () => {
		const user = await getEmail();
		const fullPathName = historySlice.list.join(".");
		const items = (formMethods.getValues(fullPathName) || []) as FormValues[];
		const now = new Date().toISOString();

		if (user) {
			formMethods.setValue(fullPathName, [...items, { id: uuid(), _date_created: now, _created_by: user }]);
		} else {
			formMethods.setValue(fullPathName, [...items, { id: uuid(), _date_created: now }]);
		}

		addRepeatableUpdatedAtUpdatedBy([`${fullPathName}.${items.length}`], fieldReferenceLibrary, formMethods, user);
		await handleSave();
		await dispatch(historyActions.pushHistory(`${items.length}`));
	};

	const handleDuplicateItem = async (e: React.MouseEvent, id: string) => {
		e.stopPropagation();
		const user = await getEmail();

		const absolutePath = historySlice.absolutePath;
		const items = (formMethods.getValues(absolutePath) || []) as FormValues[];
		const currentItem = items.find((it) => it.id === id);
		if (currentItem) {
			const now = new Date().toISOString();
			const currentItemDuplicated = field?.children?.reduce((acc, currentField) => {
				if (currentField.type === "images" || currentField.type === "files") {
					return acc;
				}
				acc[currentField.name] = currentItem[currentField.name];
				return acc;
			}, {} as Record<string, IDataValue>);
			const additionalData: Record<string, string> = { id: uuid(), _date_created: now };
			if (user) {
				additionalData._created_by = user;
			}

			formMethods.setValue(absolutePath, [
				...items,
				{
					...currentItemDuplicated,
					...additionalData,
				},
			]);
			addRepeatableUpdatedAtUpdatedBy(
				[`${absolutePath}.${items.length}`],
				fieldReferenceLibrary,
				formMethods,
				user,
			);
			await handleSave();
			dispatch(historyActions.pushHistory(`${items.length}`));
		}
	};

	const shouldRender = () => {
		// Root page
		if (!field && historySlice.active === undefined) return true;
		// Active page
		if (field && historySlice.active === field.name) return true;
		// Active repeatable item
		if (isActiveRepeatableItem) return true;
		// Otherwise
		return false;
	};

	if (!shouldRender()) return <React.Fragment />;
	return (
		<>
			<div
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					overflowY: "auto",
					overscrollBehavior: "none",
				}}
			>
				{!isRepeatableTable && <FormPageHeader field={field} siblingTypes={siblingTypes} />}
				{isRepeatableTable && !isRepeatableItemPage ? (
					<FormPageRepeatable
						field={field}
						formMethods={formMethods}
						onSubmit={onSubmit}
						setFilterModalIsOpen={setFilterModalIsOpen}
						isFrozen={isFrozen}
						handleDuplicateItem={handleDuplicateItem}
						handleAddItem={handleAddItem}
						handleSave={handleSave}
					/>
				) : (
					<FormPageContent
						childrenFields={childrenFields}
						formMethods={formMethods}
						isFrozen={isFrozen}
						siblingTypes={siblingTypes}
						handleSave={handleSave}
					/>
				)}
			</div>
			{!isRepeatableItemPage && isActiveRepeatableItem && (
				<FormPage
					field={field}
					childrenFields={childrenFields}
					formMethods={formMethods}
					onSubmit={onSubmit}
					setFilterModalIsOpen={setFilterModalIsOpen}
					isRepeatableItemPage={true}
				/>
			)}
		</>
	);
};

const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field?.name === nextProps.field?.name;
const Memoized = memo(FormPage, propsAreEqual);
export default Memoized;
