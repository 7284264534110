import { apiClient } from "api";

import IWithPagination from "../interfaces/IWithPagination";
import Form, { IRawForm } from "../models/Form";

const getPageByPage = async function* (projectRefs?: string[]) {
	let responseLength = 0;
	let perPage = 0;
	const params = { ...(projectRefs && { projectRef: projectRefs.join(",") }), page: 1 };

	while (perPage === responseLength) {
		const res = await apiClient
			.get<IWithPagination<IRawForm>>(`forms/forms`, {
				params,
			})
			.then((res) => res.data);
		yield res.data;
		perPage = res.pagination.perPage;
		responseLength = res.data.length;
		params.page += 1;
	}
	return;
};

export const getForms = async (projectRefs?: string[]) => {
	const data: IRawForm[] = [];
	for await (const page of getPageByPage(projectRefs)) {
		data.push(...page);
	}
	return data.map((f) => new Form(f));
};
