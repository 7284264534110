import { FormField } from "models/Form";

const doRecursively = <T = void>(
	fields: FormField[],
	action: (props: { field: FormField; path: string[]; result: Record<string, T> }) => Record<string, T>,
	shouldAction?: (props: { field: FormField; path: string[]; result: Record<string, T> }) => boolean,
	shouldDive?: (props: { field: FormField; path: string[]; result: Record<string, T> }) => boolean,
	shouldStop?: (props: { field: FormField; path: string[]; result: Record<string, T> }) => boolean,
	currPath: string[] = [],
): Record<string, T> => {
	let result: Record<string, T> = {};
	for (const field of fields) {
		const path = [...currPath, field.name];
		if (field.children && (shouldDive === undefined || shouldDive({ field, path, result }))) {
			result = {
				...result,
				...doRecursively(
					field.children,
					action,
					shouldAction,
					shouldDive,
					shouldStop,
					field.type === "repeatableGroup" ? [...path, "_"] : path,
				),
			};
		}
		if (shouldAction === undefined || shouldAction({ field, path, result })) {
			Object.assign(result, action({ field, path, result }));
		}
		if (shouldStop !== undefined && shouldStop({ field, path, result })) return result;
	}
	return result;
};

const exported = <T = void>(props: {
	fields: FormField[];
	action: (props: { field: FormField; path: string[] }) => Record<string, T>;
	shouldAction?: (props: { field: FormField; path: string[] }) => boolean;
	shouldDive?: (props: { field: FormField; path: string[] }) => boolean;
	shouldStop?: (props: { field: FormField; path: string[]; result: Record<string, T> }) => boolean;
}) => doRecursively(props.fields, props.action, props.shouldAction, props.shouldDive);

export default exported;
