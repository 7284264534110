import { IRecordSummary } from "api/records";

import FormRecord from "../../models/FormRecord";

export type SyncStatus = "upload" | "download" | "merge" | "synced" | "remote";

export const getSyncStatus = async (
	remoteSummary: IRecordSummary[],
	projectRef?: string,
	assetId?: string,
	ignoreRemoteOnly?: boolean,
): Promise<SyncStatus> => {
	const allLocalRecords = await (assetId
		? FormRecord.byAssetId(assetId, { includeDeleted: false, includeInactive: false }) // Ignore deleted or inactive stuff
		: projectRef
		? FormRecord.byProjectRef(projectRef, { includeDeleted: false, includeInactive: false }) // Ignore deleted or inactive stuff
		: FormRecord.getAll({ includeDeleted: false, includeInactive: false }));
	const activeLocalIds = allLocalRecords.map((r) => r.id);
	const activeRemoteIds = remoteSummary
		.filter((s) => projectRef && s.project_ref === projectRef && (!assetId || s.asset_id === assetId))
		.filter((r) => !r.is_deleted || activeLocalIds.includes(r.id))
		.map((r) => r.id);

	const localOnlyIds = activeLocalIds.filter((lid) => !activeRemoteIds.includes(lid));
	const remoteOnlyIds = activeRemoteIds.filter((lid) => !activeLocalIds.includes(lid));
	const commonIds = activeLocalIds.filter((lid) => activeRemoteIds.includes(lid));

	if (activeLocalIds.length === 0 && activeRemoteIds.length >= 0) return "remote";

	const requiresUpload =
		localOnlyIds.length > 0 ||
		commonIds.some((recordId) => {
			const local = allLocalRecords.find((rec) => rec.id === recordId);
			if (!local) return false;
			if (!local.date_synced || !local.date_updated) return true;
			const isOutdated = local.date_updated.getTime() > local.date_synced.getTime();
			return isOutdated;
		});

	const requiresDownload =
		(!ignoreRemoteOnly && remoteOnlyIds.length > 0) ||
		commonIds.some((recordId) => {
			const local = allLocalRecords.find((rec) => rec.id === recordId);
			const remote = remoteSummary.find((it) => it.id === recordId);
			if (!local) return true;
			if (!local.date_synced) return true;
			if (!remote) return false;
			const isOutdated = local.date_synced.getTime() < new Date(remote.date_synced).getTime();
			return isOutdated;
		});

	if (requiresUpload && requiresDownload) return "merge";
	else if (requiresUpload) return "upload";
	else if (requiresDownload) return "download";
	return "synced";
};
