import Asset from "models/Asset";
import FormRecord from "models/FormRecord";
import { evictUrlFromCache } from "utils/imageCache/evictUrlFromCache";
import { getAllLocalAndRemotePhotosInRecord } from "utils/photo/photoUtils";

export const cleanup = async (authorization: string, projectRef: string, assetId?: string) => {
	const records = await (assetId
		? FormRecord.byAssetId(assetId, { includeDeleted: true, includeInactive: true })
		: FormRecord.byProjectRef(projectRef, { includeDeleted: true, includeInactive: true }));
	const images = (await Promise.all(records.map(getAllLocalAndRemotePhotosInRecord))).flat();

	// Clean cache
	const cache = await caches.open("dhub-forms-images");
	await Promise.all(images.map((img) => evictUrlFromCache(img.filepath, authorization, cache)));
	// Delete records
	await Promise.all(records.map((rec) => rec.hardDelete()));
	// Delete assets
	if (assetId) {
		await Asset.get(assetId, true).then((asset) => asset.hardDelete());
	} else {
		await Asset.byProjectRef(projectRef).then((assets) => Promise.all(assets.map((asset) => asset.hardDelete())));
	}
};
