import React from "react";

import { IonButton } from "@ionic/react";
interface IProps {
	variant: "filled" | "empty";
	onClickFunction?: (e?: React.MouseEvent) => void;
	children?: React.ReactNode;
	type?: "button" | "submit" | "reset";
	disabled?: boolean;
}
export const Button: React.FC<IProps> = (props) => {
	const { onClickFunction, variant, children, type, disabled } = props;

	let styles = {};
	switch (variant) {
		case "filled":
			styles = {
				backgroundColor: "#4A5568",
				color: "#ffffff",
			};
			break;
		case "empty":
			styles = { color: "#718096" };
			break;
	}
	return (
		<IonButton
			color="#718096"
			fill={variant === "empty" ? "outline" : "solid"}
			disabled={disabled}
			style={{
				...{
					height: "2rem",
				},
				...styles,
			}}
			onClick={onClickFunction}
			type={type || "button"}
		>
			{children}
		</IonButton>
	);
};
