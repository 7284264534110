import config from "../config";

export const checkOnlineStatus = async (): Promise<boolean> => {
	return await fetch(`${config.API}`)
		.then(({ status }) => status >= 200 && status < 300)
		.catch((error) => {
			console.warn(error);
			return false;
		});
};
