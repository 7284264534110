import { useCallback } from "react";

import { FormValues, IDataValue } from "models/FormRecord";
import { evaluateExpression } from "components/common/Form/utils/evaluateExpression";
import { FormField } from "models/Form";
import { buildContext, getDependencies } from "components/common/Form/hooks/useFieldContext";
import { FieldReferenceLibrary } from "interfaces/FieldReference";
import get from "lodash.get";

export const buildIsRelevant =
	(field: FormField, context: Record<string, IDataValue>) =>
	(extraContext: Record<string, IDataValue> = {}) => {
		if (typeof field.relevant === "boolean") return field.relevant;
		if (typeof field.relevant === "string") {
			return evaluateExpression(field.relevant, { ...context, ...extraContext }, true);
		}
		return true;
	};

const useFieldTemplate = (
	field: FormField,
	data: FormValues,
	fieldReferenceLibrary: FieldReferenceLibrary,
	ancestorHistory: string[],
	baseContext: Record<string, IDataValue>,
) => {
	const { dependencies, rawDependencies } = getDependencies(field, fieldReferenceLibrary, ancestorHistory);
	const name = dependencies[0].join(".") || field.name;

	const rawContext = dependencies
		.filter((dep) => !dep.includes("_"))
		.map((dep) => dep.join("."))
		.map((dep) => get(data, dep));

	const context = buildContext(dependencies, rawDependencies, rawContext, {
		external: baseContext || {},
		...field.baseContext,
	});

	// Parse and add context to isRelevant
	const isRelevant = useCallback(buildIsRelevant(field, context), [field.relevant, context]);

	return { name, isRelevant };
};

export default useFieldTemplate;
