import { IonItem, IonLabel } from "@ionic/react";
import useFieldTemplate from "components/common/Form/hooks/useFieldTemplate";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import React from "react";
import { UseFormReturn } from "react-hook-form";

interface DisclaimerProps {
	field: FormField<string>;
	formMethods: UseFormReturn<FormValues>;
}

export const Disclaimer: React.FC<DisclaimerProps> = (props) => {
	const { field, formMethods } = props;

	const { isRelevant } = useFieldTemplate(field, formMethods.control);

	return (
		<>
			{isRelevant() && (
				<div
					className="Disclaimer"
					id={`field-${field.name}`}
					style={{
						backgroundColor: "#f9f9f9",
					}}
				>
					<IonItem lines="none">
						<IonLabel
							class="ion-text-wrap"
							data-testid={`${field.type}.value`}
							style={{ fontStyle: "italic", color: "var(--ion-color-medium)" }}
						>
							{field.label}
						</IonLabel>
					</IonItem>
				</div>
			)}
		</>
	);
};
