import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getFilterChoices } from "../../../components/modals/FiltersModal/utils/getFilterChoices";
import { FormValues } from "../../../models/FormRecord";
import { FormFieldsWithPath } from "../../../utils/searchFilterFields";

interface SWState {
	projectRef: string | undefined;
	assetId: string | undefined;
	recordId: string | undefined;
	repeatableName: string | undefined;
	filterValues: FormValues;
	filterFields: FormFieldsWithPath[];
}

const initialState: SWState = {
	projectRef: undefined,
	assetId: undefined,
	recordId: undefined,
	repeatableName: undefined,
	filterValues: {},
	filterFields: [],
};

export const filtersSlice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		INIT_FILTERS: (
			state,
			action: PayloadAction<{
				projectRef: string;
				assetId: string;
				recordId: string;
				repeatableName: string;
				fields: FormFieldsWithPath[];
			}>,
		) => {
			if (
				state.projectRef === action.payload.projectRef &&
				state.assetId === action.payload.assetId &&
				state.recordId === action.payload.recordId &&
				state.repeatableName === action.payload.repeatableName
			)
				return;
			state.projectRef = action.payload.projectRef;
			state.assetId = action.payload.assetId;
			state.recordId = action.payload.recordId;
			state.repeatableName = action.payload.repeatableName;
			const filterFields = (action.payload.fields || [])
				.map((f) => ({
					...f,
					description: undefined,
					defaultValue: undefined,
					required: false,
					calculation: undefined,
					relevant: true,
					multiple: true,
					validate: undefined,
					choices: f.choices ? getFilterChoices(f) : undefined,
					dependsOn: [],
				}))
				.filter((f) => f.filter);

			state.filterFields = filterFields;
			state.filterValues = Object.fromEntries(filterFields.map((f) => [f.name, null]));
		},
		SET_FILTERS: (
			state,
			action: PayloadAction<{
				repeatableId: string;
				filterValues: FormValues;
			}>,
		) => {
			state.filterValues = action.payload.filterValues;
			state.filterFields = state.filterFields.map((it) => ({
				...it,
				defaultValue: action.payload.filterValues[it.name],
			}));
		},
		RESET_FILTERS: (state) => {
			state.filterValues = Object.fromEntries(state.filterFields?.map((f) => [f.name, null]));
			state.filterFields = state.filterFields.map((it) => ({
				...it,
				defaultValue: undefined,
			}));
		},
	},
});

export const actions = filtersSlice.actions;

export default filtersSlice.reducer;
