import React, { useEffect, useState } from "react";

import { AuthenticationProvider, AxiosInterceptor } from "@arup-group/cognito-authenticator";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonModal,
	IonSpinner,
	IonTitle,
	IonToast,
	IonToolbar,
} from "@ionic/react";
import { AxiosRequestConfig } from "axios";

import { close } from "assets/icons";
import { Button } from "components/common/Button";
import config from "config";
import Asset from "models/Asset";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { duplicateAsset } from "api/assets";
import syncFunction from "utils/sync";
import { performBackgroundSyncThunk } from "store/slices/remote/thunks";
import { useAppDispatch } from "store";
import { useGetAssetName } from "views/AssetsView/hooks/useGetAssetName";

interface IProps {
	isOpen: boolean;
	projectRef: string;
	asset: Asset;
	onClose: () => void;
}

const rules = { required: true };

export const DuplicateAssetModal: React.FC<IProps> = (props: IProps) => {
	const { isOpen, onClose, asset } = props;
	const assetId = asset.id;
	const { t, i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAuthenticator((context) => [context.user]);
	const { projectRef } = useParams<{ projectRef: string }>();
	const [showErrorToast, setShowErrorToast] = useState(false);
	const [token, setToken] = useState<string>();
	const { nameDictionary } = useGetAssetName(projectRef, [asset]);

	const { handleSubmit, formState, control, reset, watch } = useForm({
		defaultValues: { name: nameDictionary[asset.id] || asset.name + " - Copia" },
		reValidateMode: "onBlur",
	});

	useEffect(() => {
		if (!user) return;
		const session = user.getSignInUserSession();
		const idToken = session?.getIdToken();
		if (idToken) {
			setToken(idToken.getJwtToken());
		}
	}, [user]);

	const duplicateNewAsset = async (name: string): Promise<{ newAssetId: string; projectRef: string }> => {
		const assetResponse = await duplicateAsset(assetId, name);
		await Asset.set(new Asset(assetResponse));
		return { newAssetId: assetResponse.id, projectRef: assetResponse.project_ref };
	};

	const onSubmit = (data: { name: string }) => {
		return duplicateNewAsset(data.name)
			.then(async (res) => {
				if (!token) {
					throw new Error("Token could not be retrieved to perform sync after asset creation");
				}
				const projectRef = res.projectRef;
				await syncFunction(token, [], [], [], projectRef, res.newAssetId);
				await dispatch(performBackgroundSyncThunk({ projectRef }));
				reset();
				onClose();
			})
			.catch((err) => {
				console.error(err);
				setShowErrorToast(true);
			});
	};

	return (
		<IonModal
			isOpen={isOpen}
			canDismiss={true}
			backdropDismiss={true}
			onDidDismiss={() => {
				onClose();
			}}
		>
			<AuthenticationProvider
				region={config.AWS_REGION}
				userPoolId={config.AWS_COGNITO_POOL_ID}
				userPoolWebClientId={config.AWS_COGNITO_WEB_CLIENT_ID}
				domain={config.AWS_COGNITO_DOMAIN}
				allowSignUp={false}
				azureADProviderId="AzureAD"
			>
				<AxiosInterceptor
					requestMatcher={(r: AxiosRequestConfig) =>
						r.url?.includes("dhub.arup") || r.url?.includes("localhost") || false
					}
				/>
				<IonHeader mode="ios">
					<IonToolbar style={{ alignItems: "center", "--border-width": 0 }}>
						<IonTitle>{i18n.format(t("duplicate_asset_header"), "capitalize")}</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={onClose}>
								<IonIcon src={close} size="small" style={{ strokeWidth: 1 }} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent forceOverscroll={false}>
					<form
						id="create-asset-modal-form"
						onSubmit={handleSubmit(onSubmit, (errors) => console.warn(errors))}
						style={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							padding: "0rem 1.5rem",
							paddingTop: "1rem",
						}}
					>
						<div>
							<Controller
								name="name"
								control={control}
								render={({ field, formState }) => {
									const label = [
										i18n.format(t("duplicate_asset_name"), "capitalize"),
										...(formState.errors.name?.type === "required"
											? [i18n.format(t("duplicate_asset_required_warning"), "capitalize")]
											: []),
									].join(" ");
									return (
										<IonItem>
											<IonInput
												{...field}
												label={label}
												labelPlacement="stacked"
												onIonBlur={field.onBlur}
												onIonInput={field.onChange}
											/>
										</IonItem>
									);
								}}
								rules={rules}
							></Controller>
						</div>
						<div style={{ display: "flex", flexDirection: "column", marginInline: "1rem", marginBottom: "2rem" }}>
							<Button
								variant="filled"
								type="submit"
								disabled={
									watch("name").trim() === "" || formState.isSubmitting || Object.keys(formState.errors).length > 0
								}
							>
								{formState.isSubmitting ? (
									<IonSpinner name="crescent" />
								) : (
									i18n.format(t("duplicate_asset_submit_button"), "capitalizeEveryWord")
								)}
							</Button>
						</div>
					</form>
					<IonToast
						isOpen={showErrorToast}
						onDidDismiss={() => setShowErrorToast(false)}
						message={i18n.format(t("duplicate_asset_error"), "capitalize")}
						duration={1500}
					/>
				</IonContent>
			</AuthenticationProvider>
		</IonModal>
	);
};

const propsAreEqual = (p1: IProps, p2: IProps) =>
	p1.asset === p2.asset && p1.isOpen === p2.isOpen && p1.projectRef === p2.projectRef;
export default React.memo(DuplicateAssetModal, propsAreEqual);
