import React, { useEffect, useState } from "react";

import {
	IonActionSheet,
	IonButton,
	IonChip,
	IonIcon,
	IonItem,
	IonItemDivider,
	IonItemGroup,
	IonLabel,
	IonList,
	IonListHeader,
	IonNote,
} from "@ionic/react";

import LocalFile from "models/LocalFile";
import LocalImage from "models/LocalImage";
import { download, trash, close } from "ionicons/icons";
import { handleUrlDownload } from "utils/download/handleDownload";
import { useTranslation } from "react-i18next";

interface State {
	images: LocalImage[];
	files: LocalFile[];
}
const StorageDetails: React.FC = () => {
	const { t, i18n } = useTranslation();
	const [state, setState] = useState<State>({ images: [], files: [] });
	const [detailId, setDetailId] = useState<string>();
	const [toBeDeletedId, setToBeDeletedId] = useState<string>();

	useEffect(() => {
		const initialize = async () => {
			const images = await LocalImage.getAll();
			const files = await LocalFile.getAll();
			setState({ images, files });
		};
		initialize();
	}, [toBeDeletedId]);

	const handleDownload = (item: LocalImage | LocalFile) => {
		if (!item.file) return;
		const url = URL.createObjectURL(item.file);
		handleUrlDownload(url, "filename" in item ? item.filename : item.filepath);
	};

	const handleDelete = () => {
		const toDelete = [...state.files, ...state.images].find((it) => it.filepath === toBeDeletedId);
		if (!toDelete) throw new Error(`Could not find file to delete ${toBeDeletedId}`);
		toDelete.delete();
		setToBeDeletedId(undefined);
	};

	return (
		<>
			<IonList>
				<IonListHeader lines="full">
					<IonLabel>Storage summary</IonLabel>
				</IonListHeader>
				{state.images.length + state.files.length === 0 ? (
					<IonItem>
						<IonLabel>Local storage is currently empty</IonLabel>
					</IonItem>
				) : (
					<>
						<IonItemGroup>
							<IonItemDivider>
								<IonLabel>Images</IonLabel>
							</IonItemDivider>
							{state.images.map((it) => (
								<React.Fragment key={it.filepath}>
									<IonItem
										onClick={() => {
											setDetailId((curr) => (curr === it.filepath ? undefined : it.filepath));
										}}
									>
										<IonLabel style={{ direction: "rtl", textAlign: "end", marginRight: "1rem" }}>
											{it.formpath}
										</IonLabel>
										<IonChip>{`${(it.size / 1024 / 1024).toFixed(2)}Mb`}</IonChip>
									</IonItem>
									{detailId === it.filepath && (
										<div style={{ display: "flex", flexDirection: "column", paddingLeft: "2rem" }}>
											<IonNote>
												<label>Filepath:</label>
												{it.filepath}
											</IonNote>
											<IonNote>
												<label>Formpath:</label>
												{it.formpath}
											</IonNote>
											<IonNote>
												<label>Record ID:</label> {it.record_id}
											</IonNote>
											<div style={{ display: "flex", justifyContent: "end" }}>
												<IonButton fill="clear" onClick={() => setToBeDeletedId(it.filepath)}>
													<IonIcon src={trash} />
												</IonButton>
												<IonButton fill="clear" onClick={() => handleDownload(it)}>
													<IonIcon src={download} />
												</IonButton>
											</div>
										</div>
									)}
								</React.Fragment>
							))}
						</IonItemGroup>
						<IonItemGroup>
							<IonItemDivider>
								<IonLabel>Files</IonLabel>
							</IonItemDivider>
							{state.files.map((it) => (
								<React.Fragment key={it.filepath}>
									<IonItem
										onClick={() => {
											setDetailId((curr) => (curr === it.filepath ? undefined : it.filepath));
										}}
									>
										<IonLabel style={{ direction: "rtl", textAlign: "end", marginRight: "1rem" }}>
											{it.formpath}
										</IonLabel>
										<IonChip>{`${(it.size / 1024 / 1024).toFixed(2)}Mb`}</IonChip>
									</IonItem>
									{detailId === it.filepath && (
										<div style={{ display: "flex", flexDirection: "column", paddingLeft: "2rem" }}>
											<IonNote>
												<label>Filepath:</label>
												{it.filepath}
											</IonNote>
											<IonNote>
												<label>Formpath:</label>
												{it.formpath}
											</IonNote>
											<IonNote>
												<label>Record ID:</label> {it.record_id}
											</IonNote>
											<div style={{ display: "flex", justifyContent: "end" }}>
												<IonButton fill="clear" onClick={() => setToBeDeletedId(it.filepath)}>
													<IonIcon src={trash} />
												</IonButton>
												<IonButton fill="clear" onClick={() => handleDownload(it)}>
													<IonIcon src={download} />
												</IonButton>
											</div>
										</div>
									)}
								</React.Fragment>
							))}
						</IonItemGroup>
					</>
				)}
			</IonList>
			<IonActionSheet
				isOpen={toBeDeletedId !== undefined}
				header={i18n.format(t("delete_file_msg"), "capitalize") + i18n.format(t("delete_file_msg"), "capitalize")}
				buttons={[
					{
						text: i18n.format(t("delete"), "capitalize"),
						role: "destructive",
						icon: trash,
						handler: handleDelete,
					},
					{
						text: i18n.format(t("cancel"), "capitalize"),
						icon: close,
						role: "cancel",
					},
				]}
				onDidDismiss={() => setToBeDeletedId(undefined)}
			/>
		</>
	);
};

export default StorageDetails;
