import { FormField } from "models/Form";
import doRecursively from "utils/recursive/doRecursively";

const buildFieldReferenceLibrary = (fields: FormField[]) =>
	doRecursively({
		fields,
		action: ({ field, path }) => {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { children, ...fieldExceptChildren } = field;
			return { [field.name]: { ...fieldExceptChildren, path } };
		},
	});

export default buildFieldReferenceLibrary;
