import React from "react";

import { IonToast } from "@ionic/react";
import { closeOutline, shareOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

const InstallPrompt: React.FC = () => {
	const { t, i18n } = useTranslation();

	const isIos = () => {
		const userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone/.test(userAgent);
	};
	const isIPad = () => {
		const userAgent = window.navigator.userAgent.toLowerCase();
		return /ipad|ipod/.test(userAgent);
	};
	const isInstalled = window.matchMedia("(display-mode: standalone)").matches;
	if (isIos() && !isInstalled) {
		return (
			<IonToast
				isOpen={true}
				header={i18n.format(t("install_app_msg", { sth: "iPhone" }), "capitalize")}
				message={i18n.format(t("tap_share_msg"), "capitalize")}
				position="bottom"
				icon={shareOutline}
				buttons={[{ icon: closeOutline, role: "cancel" }]}
			/>
		);
	} else if (isIPad() && !isInstalled) {
		return (
			<IonToast
				isOpen={true}
				header={i18n.format(t("install_app_msg", { sth: "iPad!" }), "capitalize")}
				message={i18n.format(t("tap_share_msg"), "capitalize")}
				position="bottom"
				icon={shareOutline}
				buttons={[{ icon: closeOutline, role: "cancel" }]}
			/>
		);
	}
	return <></>;
};

export default InstallPrompt;
