import {
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonSpinner,
	IonText,
} from "@ionic/react";
import { FormLinkProps, getFormLinks } from "api/links";
import { alertTriangle } from "assets/icons";
import { Button } from "components/common/Button";
import { Note } from "components/common/Note/Note";
import { closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { LinkList } from "views/RecordsView/LinkList";

interface IProps {
	isOpen: boolean;
	bimModelDeliverMessage: string | undefined;
	onButtonClick: () => Promise<void>;
	onClose: () => void;
}

export const ConfirmBIMModelDeliveryModal: React.FC<IProps> = (props: IProps) => {
	const { isOpen, bimModelDeliverMessage, onButtonClick, onClose } = props;
	const { t, i18n } = useTranslation();
	const { assetId } = useParams<{ assetId: string }>();
	const [inputValid, setInputValid] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const [links, setLinks] = useState<FormLinkProps[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLinks(await getFormLinks(assetId));
			} catch (error) {
				setLinks([]);
				console.log(error);
			}
		};
		fetchData();
	}, [assetId]);

	return (
		<IonModal isOpen={isOpen} canDismiss={true} backdropDismiss={true}>
			<IonHeader
				style={{
					height: "3rem",
					background: "var(--ion-background-color, #fff)",
				}}
			>
				<IonItem lines="none">
					<IonLabel
						style={{
							marginLeft: "3rem",
							display: "flex",
							justifyContent: "center",
							fontSize: "0.875rem",
							fontWeight: "400",
						}}
					>
						{i18n.format(t("delivery_bim_model"), "capitalize")}
					</IonLabel>
					<IonIcon
						style={{
							height: "100%",
							width: "2rem",
							color: "black",
							alignSelf: "center",
							paddingTop: "0.5rem",
							paddingBottom: "0.5rem",
							cursor: "pointer",
						}}
						icon={closeOutline}
						onClick={onClose}
					/>
				</IonItem>
			</IonHeader>

			<IonContent forceOverscroll={false}>
				<div
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						gap: "3rem",
						padding: "2rem",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							gap: "0.5rem",
							width: "100%",
						}}
					>
						{bimModelDeliverMessage && <Note variant="red">{bimModelDeliverMessage}</Note>}
						<IonIcon style={{ fontSize: "5rem" }} icon={alertTriangle} />

						<div style={{ display: "flex", flexDirection: "column", gap: "0.25rem", alignItems: "center" }}>
							<IonText style={{ textAlign: "justify" }}>
								{links.length
									? i18n.format(t("delivery_bim_model_confirmation"), "capitalize")
									: i18n.format(t("delivery_bim_model_unavailable"), "capitalize")}
							</IonText>
							<LinkList assetId={assetId} />
						</div>
					</div>

					{links.length > 0 && (
						<IonItem>
							<IonInput
								label={i18n.format(t("delivery_input_label"), "capitalize")}
								labelPlacement="stacked"
								onIonInput={(ev) => {
									const value = ev.detail.value;
									setInputValid(value === "entregar" || value === "delivery");
								}}
							/>
						</IonItem>
					)}

					<div style={{ marginTop: "auto", display: "flex", flexDirection: "column" }}>
						<Button
							variant="filled"
							onClickFunction={() => {
								setInProgress(true);
								onButtonClick().then(() => setInProgress(false));
							}}
							disabled={!inputValid || inProgress || !links.length}
						>
							{inProgress ? <IonSpinner name="crescent" /> : i18n.format(t("deliver_bim_model"), "capitalize")}
						</Button>
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};
