import React, { useEffect, useState } from "react";

import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonIcon,
	IonList,
	IonModal,
	IonSpinner,
	IonTitle,
	IonToolbar,
} from "@ionic/react";

import { close } from "assets/icons";
import { Button } from "components/common/Button";
import { FormFieldFactory } from "components/common/Form/utils/FormFieldFactory";
import { FormField } from "models/Form";
import { FormValues, IDataValue } from "models/FormRecord";
import Project from "models/Project";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "store";
import { assetFilterSlice } from "store/slices/assetFilter/assetFilterSlice";
import { findFields } from "./findFields";
import { formSlice } from "store/slices/form";
import { actions as userActions } from "store/slices/user";
import RadioGroup from "components/common/Form/widgets/RadioGroup";
import { getEmail } from "utils/getEmail";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	deliveryFeatureActive: boolean;
}

export const FilterAssetModal: React.FC<IProps> = (props: IProps) => {
	const { t, i18n } = useTranslation();
	const { projectRef } = useParams<{ projectRef: string }>();
	const { isOpen, onClose, deliveryFeatureActive } = props;
	const remoteState = useAppSelector((state) => state.remote.thunkState);
	const dispatch = useAppDispatch();
	// const filterState = useAppSelector((state) => state.assetFilter);
	const [configFields, setConfigFields] = useState<FormField[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const methods = useForm<FormValues<IDataValue>, any>({
		defaultValues: {},
		reValidateMode: "onBlur",
	});

	const handleFiltering = () => {
		const { discardedOnly, status, tecnico, ...dataFilters } = methods.getValues();
		dispatch(
			assetFilterSlice.actions.setDataFilters({
				discardedOnly: discardedOnly === "true",
				status: status as string[],
				tecnico: tecnico as string[],
				dataFilters,
			}),
		);
	};

	useEffect(() => {
		getEmail().then((email) => {
			email && dispatch(userActions.setEmail(email));
		});
	}, []);

	useEffect(() => {
		dispatch(formSlice.actions.reset());
		const search = async () => {
			if (remoteState !== "succeeded") return;
			const project = await Project.get(projectRef);
			const fields = await Promise.all(
				project.configuration.configuration_filter.map((it) => findFields(it.form_id, it.fields)),
			);
			const byIteStatus: FormField = {
				name: "status",
				type: "dropdown",
				label: "Estado ITE en curso",
				multiple: true,
				choices: [
					{ label: "En proceso", value: "inProgress" },
					{ label: "Entregado", value: "pendingValidation" },
					{ label: "Registrado", value: "validated" },
					{ label: "Rechazado", value: "rejected" },
				],
			};
			const byTecnico: FormField = {
				name: "tecnico",
				type: "dropdown",
				label: "Tecnico Responsable",
				multiple: true,
				choices: project.tecnicos.filter((it) => it && it.includes("@")).map((it) => ({ label: it, value: it })),
			};
			const cfields = fields.flatMap((it) => it);
			if (cfields.length) {
				cfields.splice(1, 0, byIteStatus, byTecnico);
			}
			setConfigFields(cfields);
		};
		search();
	}, [projectRef, remoteState]);

	const { handleSubmit, reset } = methods;
	return (
		<IonModal
			isOpen={isOpen}
			canDismiss={true}
			backdropDismiss={true}
			onDidDismiss={() => {
				onClose();
			}}
		>
			<IonHeader mode="ios">
				<IonToolbar style={{ alignItems: "center", "--border-width": 0 }}>
					<IonTitle>{i18n.format(t("filter_asset_header"), "capitalize")}</IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={onClose}>
							<IonIcon src={close} size="small" style={{ strokeWidth: 1 }} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent forceOverscroll={false}>
				<form
					id="create-asset-modal-form"
					onSubmit={handleSubmit(
						() => {
							handleFiltering();
						},
						(errors) => console.warn(errors),
					)}
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						padding: "0rem 1.5rem",
					}}
				>
					{configFields.length ? (
						<React.Fragment>
							<IonList
								style={{
									overflowY: "auto",
									flex: "1",
									position: "relative",
									padding: 0,
								}}
							>
								{configFields.map((it) => {
									return <FormFieldFactory key={it.name} field={{ ...it, required: false }} methods={methods} />;
								})}
								{deliveryFeatureActive && configFields.length && (
									<RadioGroup
										key="discardedOnly"
										formMethods={methods}
										field={
											{
												type: "radio",
												name: "discardedOnly",
												label: i18n.format(t("show_based_on_asset_status"), "capitalize"),
												choices: [
													{ value: "false", label: i18n.format(t("show_discarded_assets_false_label"), "capitalize") },
													{ value: "true", label: i18n.format(t("show_discarded_assets_true_label"), "capitalize") },
												],
												defaultValue: "false",
											} as FormField<string>
										}
									/>
								)}
							</IonList>
							<div style={{ marginBottom: "2rem", display: "flex", flexDirection: "column", marginInline: "1rem" }}>
								<Button variant="filled" type="submit" onClickFunction={() => onClose()}>
									{i18n.format(t("filter_asset_apply"), "capitalizeEveryWord")}
								</Button>
								<Button
									variant="empty"
									onClickFunction={async () => {
										await dispatch(assetFilterSlice.actions.reset());
										reset(Object.fromEntries(configFields.map((field) => [field.name, null])));
									}}
								>
									{i18n.format(t("filter_asset_reset"), "capitalizeEveryWord")}
								</Button>
							</div>
						</React.Fragment>
					) : (
						<div
							style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
						>
							<IonSpinner />
						</div>
					)}
				</form>
			</IonContent>
		</IonModal>
	);
};

const arePropsEqual = (p1: IProps, p2: IProps) => p1.isOpen === p2.isOpen;
export default React.memo(FilterAssetModal, arePropsEqual);
