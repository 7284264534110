import React, { useState, useEffect } from "react";
import { IonIcon, IonSpinner } from "@ionic/react";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";

import { recursivelyCheckFilledIn } from "components/common/Form/hooks/useGroupFilledIn";
import { buildBaseContext } from "components/common/Form/utils/buildBaseContext";
import buildFieldReferenceLibrary from "components/common/Form/utils/buildReferenceLibrary";
import Form from "models/Form";
import FormRecord from "models/FormRecord";

export const CompletitionStatusIcon: React.FC<{
	record?: FormRecord;
	style?: Record<string, string>;
	form: Form;
	assetId: string;
}> = (props) => {
	const { record, form, style, assetId } = props;

	const [status, setStatus] = useState<boolean>();

	useEffect(() => {
		const fieldLibrary = buildFieldReferenceLibrary(form.fields || []);
		buildBaseContext(form, assetId).then((baseContext) => {
			const isComplete = recursivelyCheckFilledIn({
				fields: form.fields || [],
				data: record?.data || {},
				fieldLibrary,
				baseContext,
			});
			setStatus(isComplete);
		});
	}, [form?.id, assetId, record?.date_synced?.toISOString(), record?.date_updated?.toISOString()]);

	if (status === undefined)
		return (
			<IonSpinner
				name="lines-sharp-small"
				color="medium"
				style={{
					height: "1.25rem",
					width: "1.25rem",
					...style,
				}}
			/>
		);
	const fillColor = status ? "var(--ion-color-success)" : "#CBD5E0";
	const svg = status ? checkmarkCircleOutline : ellipseOutline;
	return (
		<IonIcon
			slot="end"
			size="small"
			style={{
				color: fillColor,
				height: "1.25rem",
				width: "1.25rem",
				...style,
			}}
			icon={svg}
		/>
	);
};
