import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SWState {
	serviceWorkerInitialized: boolean;
	serviceWorkerUpdated: boolean;
	serviceWorkerRegistration: ServiceWorkerRegistration | null;
}

const initialState: SWState = {
	serviceWorkerInitialized: false,
	serviceWorkerUpdated: false,
	serviceWorkerRegistration: null,
};

export const swSlice = createSlice({
	name: "sw",
	initialState,
	reducers: {
		SW_INIT: (state) => {
			state.serviceWorkerInitialized = true;
		},
		SW_UPDATE: (state, action: PayloadAction<ServiceWorkerRegistration>) => {
			state.serviceWorkerUpdated = true;
			state.serviceWorkerRegistration = action.payload;
		},
	},
});

export const actions = swSlice.actions;

export default swSlice.reducer;
