const hostname = window.location.hostname;

const apiRouteMapper: Record<string, string> = {
	"forms.dev.aite.dhub.arup.com": "https://api.dev.aite.dhub.arup.com",
	"forms.aite.dhub.arup.com": "https://api.aite.dhub.arup.com",
};

const vapidMapper: Record<string, string> = {
	"forms.dev.aite.dhub.arup.com":
		"BFIyWhXZqSFGo0tEStrnk6LBdGx0t1eW3IcZULtwggf-BfaYsqnqsiZLf9rdG8E9csxAcbTU-aVN0wzLKf53wo0",
	"forms.aite.dhub.arup.com": "BIiKQnozcZYRX3-9lWROy7ZPkMpQZ8ZohOJBkXS-bkRm6DOpefJZ9xHWBoDk6zh0Xuxla1Rt5I5g-QkqIic7aJY",
};

const config = {
	API: apiRouteMapper[hostname],
	S3_IMAGES: "",
	AWS_REGION: "eu-west-1",
	AWS_COGNITO_DOMAIN: "adifiteauth.auth.eu-west-1.amazoncognito.com",
	AWS_COGNITO_POOL_ID: "eu-west-1_6zrfPeXrO",
	AWS_COGNITO_WEB_CLIENT_ID: "6dgpaqnhv9n45dtcn9n17amohc",
	RELEASE: process.env.REACT_APP_GIT_SHA?.slice(0, 7) || "development",
	VAPID_PUBLIC_KEY: vapidMapper[hostname],
};

export default config;
