import { apiClient } from "api";
import { PushSubscription } from "web-push";

export const postSubscription = async (subscription: PushSubscription) => {
	return apiClient.post("conf/webpush", subscription);
};

export const deleteSubscription = async (subscriptionId: string) => {
	return apiClient.delete(`conf/webpush/${subscriptionId}`);
};
