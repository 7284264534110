import { IonButton, IonContent, IonHeader, IonModal, IonTitle, IonToolbar, useIonLoading } from "@ionic/react";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord from "models/FormRecord";
import LocalImage from "models/LocalImage";
import Project from "models/Project";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface IProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}
type PropsType = IProps;

const LoadJSONModal: React.FC<PropsType> = (props) => {
	const { isOpen, setIsOpen } = props;
	const { t, i18n } = useTranslation();
	const [selectedFile, setSelectedFile] = useState<File>();
	const [present, dismiss] = useIonLoading();
	const history = useHistory();

	const handleJSONLoad = async (file?: File) => {
		if (!file) return;
		present({
			message: "Loading...",
		});
		try {
			const { projects, assets, records, images, forms } = JSON.parse(await file.text());

			if (projects && Array.isArray(projects)) {
				console.log("Loading projects:", projects);
				await Project.setMany(projects);
			}
			if (assets && Array.isArray(assets)) {
				console.log("Loading assets:", assets);
				await Asset.setMany(assets);
			}
			if (forms && Array.isArray(forms)) {
				console.log("Loading forms:", forms);
				await Form.setMany(forms);
			}
			if (records && Array.isArray(records)) {
				console.log("Loading records:", records);
				await FormRecord.setMany(records);
			}
			if (images && Array.isArray(images)) {
				console.log("Loading images:", images);
				await LocalImage.setMany(images);
			}
		} catch (e) {
			console.error(e);
		} finally {
			await dismiss();
			setIsOpen(false);
			history.push("/");
		}
	};

	return (
		<IonModal isOpen={isOpen} canDismiss={true} onWillDismiss={() => setIsOpen(false)}>
			<IonHeader>
				<IonToolbar>
					<IonTitle size="large">{i18n.format(t("load_local_json"), "capitalize")}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent  forceOverscroll={false}>
				<div
					style={{
						display: "flex",
						paddingTop: "5rem",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "2rem",
					}}
				>
					<input
						type="file"
						accept="application/json"
						onChange={(e) => {
							const files = e.target.files;
							if (files && files[0]) setSelectedFile(files[0]);
						}}
					/>
					<div>
						<IonButton disabled={!selectedFile} onClick={() => handleJSONLoad(selectedFile)}>
							{i18n.format(t("load_local_json"), "capitalize")}
						</IonButton>
						<IonButton
							onClick={() => {
								setIsOpen(false);
							}}
						>
							{i18n.format(t("cancel"), "capitalize")}
						</IonButton>
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};

export default LoadJSONModal;
