import React, { useState, ReactEventHandler } from "react";

import { getSignedUrls } from "api/signedUrl";

import { IImage } from "../../../../../interfaces/IImage";
import LocalImage from "../../../../../models/LocalImage";

interface IProps {
	style: React.CSSProperties | undefined;
	photo: IImage;
	thumbnail?: boolean;
	loading?: "eager" | "lazy";
	alt?: string;
	onClick?: (value: React.SetStateAction<IImage>) => void;
}

type LoadStrategy =
	| "thumbnail"
	| "fullsize"
	| "localImage"
	| "signedUrlThumbnail"
	| "signedUrlFullsize"
	| "placeholder"
	| "none";

interface LoadConfig {
	url: string;
	strategy: LoadStrategy;
	localImage?: LocalImage;
}

const ImageFallback: React.FC<IProps> = (props: IProps) => {
	const {
		style,
		photo,
		onClick,
		alt = props.photo.filepath.split("/").slice(-1)[0],
		loading = "eager",
		thumbnail = true,
	} = props;

	const defaultLoadStrategies: LoadStrategy[] = thumbnail
		? ["thumbnail", "localImage", "fullsize", "signedUrlThumbnail", "signedUrlFullsize", "placeholder", "none"]
		: ["fullsize", "localImage", "thumbnail", "signedUrlFullsize", "signedUrlThumbnail", "placeholder", "none"];

	const isLocalhost = window.location.origin.includes("localhost");
	const origin = isLocalhost ? "https://forms.dev.aite.dhub.arup.com" : window.location.origin;

	const thumbnailConfig = (): LoadConfig => ({
		url: `${origin}/thumbnails/${photo.filepath}`,
		strategy: "thumbnail",
	});

	const fullSizeConfig = (): LoadConfig => ({ url: `${origin}/images/${photo.filepath}`, strategy: "fullsize" });

	const localImageConfig = () =>
		LocalImage.get(photo.filepath)
			.then(readLocalImageAsUrl)
			.then((url): LoadConfig => ({ url, strategy: "localImage" }))
			.catch(() => getNextConfig("localImage"));

	const signedUrlThumbnailConfig = () =>
		getSignedUrls([{ filepath: photo.filepath, type: "image", thumbnail: true }])
			.then((urls) => Object.values(urls)[0])
			.then((url): LoadConfig => ({ url, strategy: "signedUrlThumbnail" }))
			.catch(() => getNextConfig("signedUrlThumbnail"));

	const signedUrlFullsizeConfig = () =>
		getSignedUrls([{ filepath: photo.filepath, type: "image", thumbnail: false }])
			.then((urls) => Object.values(urls)[0])
			.then((url): LoadConfig => ({ url, strategy: "signedUrlFullsize" }))
			.catch(() => getNextConfig("signedUrlFullsize"));

	const placeholderConfig = (): LoadConfig => ({ url: "/placeholder.png", strategy: "placeholder" });

	const [loadConfig, setLoadConfig] = useState<LoadConfig>(thumbnail ? thumbnailConfig() : fullSizeConfig());

	const getNextConfig = async (currStrategy: LoadStrategy): Promise<LoadConfig> => {
		const currentStrategyIdx = defaultLoadStrategies.findIndex((s) => s === currStrategy);
		const nextStrategy = defaultLoadStrategies[currentStrategyIdx + 1];
		// console.log(currStrategy, "->", nextStrategy);
		switch (nextStrategy) {
			case "fullsize":
				return fullSizeConfig();
			case "thumbnail":
				return thumbnailConfig();
			case "localImage":
				return await localImageConfig();
			case "signedUrlFullsize":
				return await signedUrlFullsizeConfig();
			case "signedUrlThumbnail":
				return await signedUrlThumbnailConfig();
			case "placeholder":
				return placeholderConfig();
			default:
				return { url: "/placeholder.png", strategy: "none" };
		}
	};

	const errorHandler: ReactEventHandler<HTMLImageElement> = async () => {
		getNextConfig(loadConfig.strategy).then((next) => next && setLoadConfig(next));
	};

	return (
		<img
			src={loadConfig.url}
			alt={alt}
			loading={loading}
			style={style}
			onClick={() => onClick && onClick(loadConfig.localImage || photo)}
			onError={errorHandler}
		/>
	);
};

export default ImageFallback;

const readLocalImageAsUrl = (localImage: LocalImage) => {
	const promise = new Promise<string>((resolve, reject) => {
		if (localImage.base64) {
			resolve(`data:image/jpeg;charset=utf-8;base64,${localImage.base64}`);
		} else if (localImage.file && FileReader) {
			const reader = new FileReader();
			reader.onload = (e) => {
				if (typeof e.target?.result === "string") {
					resolve(e.target?.result);
				} else {
					reject(`Error reading file as data URL: ${localImage.filepath}`);
				}
			};
			reader.readAsDataURL(localImage.file);
		} else {
			reject("Binary data or file reader unavailable");
		}
	});
	return promise;
};
