import { useCallback, useMemo } from "react";

import { Control } from "react-hook-form";

import { IDataValue } from "models/FormRecord";

import { FormField } from "../../../../models/Form";
import { evaluateExpression } from "../utils/evaluateExpression";
import useFieldContext from "./useFieldContext";

export const buildIsRelevant =
	(field: FormField, context: Record<string, IDataValue>) =>
	(extraContext: Record<string, IDataValue> = {}) => {
		if (typeof field.relevant === "boolean") return field.relevant;
		if (typeof field.relevant === "string") {
			return evaluateExpression(field.relevant, { ...context, ...extraContext }, true);
		}
		return true;
	};

export const buildIsValid =
	(field: FormField, context: Record<string, IDataValue>) => (extraContext: Record<string, IDataValue>) => {
		if (typeof field.validate === "boolean") return field.validate;
		if (typeof field.validate === "string") {
			return evaluateExpression(field.validate, { ...context, ...extraContext }, true);
		}
		return true;
	};

export const buildIsRequired =
	(field: FormField, context: Record<string, IDataValue>) =>
	(extraContext: Record<string, IDataValue> = {}) => {
		if (typeof field.required === "boolean") return field.required;
		if (typeof field.required === "string")
			return evaluateExpression(field.required, { ...context, ...extraContext }, true);
		return false;
	};

export const buildIsHardRequired =
	(field: FormField, context: Record<string, IDataValue>) =>
	(extraContext: Record<string, IDataValue> = {}) => {
		if (typeof field.hardRequired === "boolean") return field.hardRequired;
		if (typeof field.hardRequired === "string")
			return evaluateExpression(field.hardRequired, { ...context, ...extraContext }, true);
		return false;
	};

export const buildIsDisabled =
	(field: FormField, context: Record<string, IDataValue>) =>
	(extraContext: Record<string, IDataValue> = {}) => {
		if (typeof field.disabled === "boolean") return field.disabled;
		if (typeof field.disabled === "string")
			return evaluateExpression(field.disabled, { ...context, ...extraContext }, false);
		return false;
	};

const useFieldTemplate = (field: FormField, control: Control) => {
	const { name, context, isFrozen } = useFieldContext(field, control);

	// Derive calculated value
	const valueCalculated = useMemo(() => {
		if (typeof field.calculation === "boolean") return field.calculation;
		if (typeof field.calculation === "string") {
			return evaluateExpression(field.calculation, { ...context }, "");
		}
		return "";
	}, [field.calculation, context]);

	// Derive alert
	const alert = useMemo(() => {
		if (typeof field.alert === "string") {
			return evaluateExpression(field.alert, { ...context }, "");
		}
		return "";
	}, [field.calculation, context]);

	// Parse and add context to isRelevant
	const isRelevant = useCallback(buildIsRelevant(field, context), [field.relevant, context]);

	// Parse and add context to isValid
	const isValid = useCallback(buildIsValid(field, context), [field.validate, context]);

	// Parse and add context to isRequired
	const isRequired = useCallback(buildIsRequired(field, context), [(field.required, context)]);

	// Parse and add context to isHardRequired
	const isHardRequired = useCallback(buildIsHardRequired(field, context), [(field.hardRequired, context)]);

	// Parse and add context to isDisabled
	// TODO: should field.required be field.disabled???
	const isDisabled = useCallback(buildIsDisabled(field, context), [(field.required, context)]);

	return { name, valueCalculated, alert, isValid, isRequired, isHardRequired, isRelevant, isDisabled, isFrozen };
};

export default useFieldTemplate;
