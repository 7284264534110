import { openDB, IDBPDatabase, IDBPTransaction } from "idb";

import FormRecord from "../models/FormRecord";
import LocalImage from "../models/LocalImage";
import FormsDB from "./interfaces/current";
import LegacyFormsDB from "./interfaces/legacy";

export const dbPromise = openDB<FormsDB>("dhub-forms", 11, {
	async upgrade(db, oldVersion, newVersion, transaction) {
		const formerDb = db as unknown as IDBPDatabase<LegacyFormsDB>;
		const formerTransaction = transaction as unknown as IDBPTransaction<
			LegacyFormsDB,
			("forms" | "projects" | "assets" | "issues" | "images" | "permissions")[],
			"versionchange"
		>;
		if (oldVersion < 1) {
			formerDb.createObjectStore("forms", { keyPath: "id" });
			formerDb.createObjectStore("projects", { keyPath: "ref" });
			const assets = formerDb.createObjectStore("assets", { keyPath: "id" });
			assets.createIndex("project_ref", "project_ref");
			const issues = formerDb.createObjectStore("issues", { keyPath: "id" });
			issues.createIndex("project_ref", "project_ref");
			issues.createIndex("asset_id", "asset_id");
			issues.createIndex("date_synced", "date_synced");
		}
		if (oldVersion < 2) {
			const images = formerDb.createObjectStore("images", {
				keyPath: "filepath",
			});
			images.createIndex("project_ref", "project_ref");
			images.createIndex("asset_id", "asset_id");
			images.createIndex("issue_id", "issue_id");
		}
		if (oldVersion === 2) {
			window.alert(
				"As part of the local database upgrade progress, we need to make breaking changes. Since you were using a pre - release database version, we are just going to proceed to delete most local data. Just sync again. Sorry!",
			);
			const forms = formerTransaction.objectStore("forms");
			forms.clear();
			const issues = formerTransaction.objectStore("issues");
			issues.clear();
		}
		if (oldVersion < 4) {
			formerDb.createObjectStore("permissions", { keyPath: "key" });
		}
		if (oldVersion < 7) {
			// Big change here. We are changing from LegacyFormsDB to FormsDB which involves
			// removing certain "issue" named keys here and there and replacing issues with records.

			// Create new records store
			const records = db.createObjectStore("records", { keyPath: "id" });
			records.createIndex("project_ref", "project_ref");
			records.createIndex("asset_id", "asset_id");
			records.createIndex("date_synced", "date_synced");

			// Migrate all data from issues to records
			const issueStore = formerTransaction.objectStore("issues");
			const recordStore = transaction.objectStore("records");
			await Promise.all(
				await issueStore.getAll().then((allIssues) => allIssues.map((issue) => recordStore.put(issue as FormRecord))),
			);

			// We are done with issues. Clear and delete store
			issueStore.clear();
			formerDb.deleteObjectStore("issues");

			// Images used to have an issue_id index which we are going to migrate to record_id
			const formerImagesStore = formerTransaction.objectStore("images");
			const imagesStore = transaction.objectStore("images");

			interface FormerImage extends LocalImage {
				issue_id: "string";
			}
			await Promise.all(
				await formerImagesStore.getAll().then((allImages) =>
					allImages.map((img) =>
						imagesStore.put(
							new LocalImage({
								...img,
								record_id: (img as unknown as FormerImage).issue_id,
							}),
						),
					),
				),
			);

			imagesStore.deleteIndex("issue_id");
			imagesStore.createIndex("record_id", "record_id");
		}
		if (oldVersion < 8) {
			const files = db.createObjectStore("files", {
				keyPath: "filepath",
			});
			files.createIndex("project_ref", "project_ref");
			files.createIndex("asset_id", "asset_id");
			files.createIndex("record_id", "record_id");
		}
		if (oldVersion < 10) {
			const recordStore = transaction.objectStore("records");
			const records = await recordStore.getAll();
			await Promise.all(records.map((rec) => recordStore.delete(rec.id)));
		}
		if (oldVersion < 11) {
			// from now on, we don't want to eagerly save all assets.
			// instead, only synced assets (the ones that have records)
			// should be in the IDB
			const recordStore = transaction.objectStore("records");
			const allRecords = await recordStore.getAll();
			const assetIdsFromRecords = new Set(allRecords.map((it) => it.asset_id));
			const assetStore = transaction.objectStore("assets");
			const allAssetIds = await assetStore.getAllKeys();
			const assetIdsToDelete = allAssetIds.filter((assetId) => !assetIdsFromRecords.has(assetId));
			await Promise.all(assetIdsToDelete.map((assetId) => assetStore.delete(assetId)));
		}
	},
	blocked() {
		window.alert(
			"There is a new dHub Forms version available!. Please close all tabs or intances currently running (including this one) in order to apply this update.",
		);
		console.warn("idb blocked");
	},
	blocking() {
		window.alert(
			"There is a new dHub Forms version available!. Please close all tabs or intances currently running (including this one) in order to apply this update.",
		);
		console.log("idb blocking");
	},
	terminated() {
		console.warn(`Unexpectedly terminated database connection`);
	},
});

export type { FormsDB };
