import config from "config";

interface GetSignedUrlItem {
	filepath: string;
	type: "image" | "drawing" | "file";
	thumbnail: boolean;
}

export const getSignedUrl = async (
	items: GetSignedUrlItem[],
	authorization: string,
): Promise<Record<string, string | null>> => {
	const res = await fetch(`${config.API}/forms/getSignedUrls`, {
		method: "post",
		body: JSON.stringify(items),
		headers: { Authorization: "Bearer " + authorization, "Content-Type": "application/json" },
	});
	return res.json();
};
