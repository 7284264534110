import React, { PropsWithChildren } from "react";

interface DividerProps extends PropsWithChildren {
	color?: string;
}
export const Divider: React.FC<DividerProps> = (props) => {
	return (
		<div style={{ position: "relative", borderBottom: `1px solid ${props.color || "black"}`, width: "100%" }}>
			{props.children}
		</div>
	);
};
