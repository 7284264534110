import get from "lodash.get";
import { UseFormReturn, useWatch } from "react-hook-form";

import { FieldReferenceLibrary } from "interfaces/FieldReference";
import { FormField } from "models/Form";
import { FormValues, IDataValue } from "models/FormRecord";
import { useAppSelector } from "store";
import doRecursively from "utils/recursive/doRecursively";

import bakeHistoryInFullPath from "../utils/bakeHistoryInFullPath";
import { buildContext, getDependencies } from "./useFieldContext";
import { buildIsDisabled, buildIsRelevant, buildIsRequired } from "./useFieldTemplate";
import { IImage } from "interfaces/IImage";

export const useGroupFilledIn = (field: FormField, formMethods: UseFormReturn<FormValues>, itemIdx?: number) => {
	const fieldLibrary = useAppSelector((state) => state.form.fieldReferenceLibrary);
	const baseContext = useAppSelector((state) => state.form.baseContext);
	const history = useAppSelector((state) => state.history.list);

	const rawAbsSelfPath: string[] = fieldLibrary[field.name].path || field.name;
	const absSelfPath = bakeHistoryInFullPath(rawAbsSelfPath, history) || [];

	const dependencies = [...new Set(Object.values(getDependenciesToWatch([field])).flat())];

	useWatch({
		name: dependencies,
		control: formMethods.control,
	});

	const data = formMethods.getValues();

	if (!field.children) return true;

	if (field.type === "repeatableGroup" && itemIdx === undefined) {
		return recursivelyCheckFilledIn({
			fields: [field],
			data,
			fieldLibrary,
			baseContext,
			currPath: absSelfPath.slice(0, -1),
		});
	}

	return recursivelyCheckFilledIn({
		fields: field.children || [],
		data,
		fieldLibrary,
		baseContext,
		currPath: itemIdx !== undefined ? [...absSelfPath, itemIdx.toString()] : absSelfPath,
	});
};

export const getDependenciesToWatch = (childrenFields: FormField[]) => {
	// Idea here is that we only need to keep track of dependencies of fields that have
	// expressions for "relevant" and / or "required" and which might change
	// while our component is on screen. This means that we only need to look recursively
	// within inline groups, as other types of groups are not rendered.
	return doRecursively({
		fields: childrenFields,
		action: ({ path, field }) => ({ [path.join(".")]: field.dependsOn ?? [] }),
		shouldAction: ({ field }) => typeof field.relevant === "string" || typeof field.required === "string",
		shouldDive: ({ field }) => field.type === "inlineGroup" && !!(field.relevant ?? true),
	});
};

interface RecursivelyCheckFulfilledProps {
	fields: FormField[];
	data: FormValues;
	currPath?: string[];
	fieldLibrary: FieldReferenceLibrary;
	baseContext?: FormValues;
}
export const recursivelyCheckFilledIn = (props: RecursivelyCheckFulfilledProps): boolean => {
	const { fields, data, fieldLibrary, baseContext = {}, currPath = [] } = props;

	for (const field of fields) {
		const path = [...currPath, field.name];
		const { isRelevant, isDisabled, isRequired } = evaluateFieldExpressions(
			field,
			data,
			fieldLibrary,
			baseContext,
			path,
		);
		if (!isRelevant || isDisabled) continue;

		// Repeatables
		if (field.children?.length && field.type === "repeatableGroup") {
			// TODO: For some unknown reason it's possible to find nulls in the repeatable item lists
			const items: (FormValues | null)[] = get(data, path, []);
			for (const [idx, item] of items.entries()) {
				if (item && item._is_deleted) continue;
				const fullfilled = recursivelyCheckFilledIn({
					fields: field.children,
					data,
					currPath: [...path, idx.toString()],
					fieldLibrary,
					baseContext,
				});
				if (!fullfilled) {
					// console.log("Group not filled-in. Found missing required field:", field.name);
					return false;
				}
			}
			// Group fields
		} else if (field.children?.length) {
			const fullfilled = recursivelyCheckFilledIn({
				fields: field.children,
				data,
				currPath: path,
				fieldLibrary,
				baseContext,
			});
			if (!fullfilled) {
				// console.log("Group not filled-in. Found missing required field:", field.name);
				return false;
			}
			// We are ok with simple fields that are irrelevant or definitely not required
		} else {
			const value: IDataValue = get(data, path);
			const isEmpty =
				value === null ||
				value === undefined ||
				value === "" ||
				(field.type === "dropdown" && field?.multiple && Array.isArray(value) && !value.length) ||
				(field.type === "images" &&
					Array.isArray(value) &&
					!(value as IImage[])?.filter((it) => !it["_is_deleted"]).length);

			const fulfilled = !isRelevant || isDisabled || !isRequired || !isEmpty;
			if (!fulfilled) {
				// console.log("Group not filled-in. Found missing required field:", field.name);
				return false;
			}
		}
	}
	return true;
};

const evaluateFieldExpressions = (
	field: FormField,
	data: FormValues,
	fieldLibrary: FieldReferenceLibrary,
	externalContext: FormValues,
	path: string[],
) => {
	// We are ok with simple fields that are irrelevant or definitely not required
	const relevant = field.relevant ?? true;
	const required = field.required ?? false;
	const disabled = field.disabled ?? false;

	// Early scape if we are just dealing with booleans and we don't need any evaluations
	if (typeof relevant === "boolean" && typeof required === "boolean" && typeof disabled === "boolean")
		return { isRelevant: relevant, isRequired: required, isDisabled: disabled };

	// Perform expression evaluation as required
	const { dependencies, rawDependencies } = getDependencies(field, fieldLibrary, path);
	const rawContext = dependencies.map((depPath) => get(data, depPath) as IDataValue);
	const context = buildContext(dependencies, rawDependencies, rawContext, {
		external: externalContext,
		...field.baseContext,
	});

	const isRelevant = typeof relevant === "string" ? buildIsRelevant(field, context)() : relevant;
	const isRequired = typeof required === "string" ? buildIsRequired(field, context)() : required;
	const isDisabled = typeof disabled === "string" ? buildIsDisabled(field, context)() : disabled;

	return { isRelevant, isRequired, isDisabled };
};
