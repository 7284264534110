import { cloudCheck, downloadCloud, uploadCloud, uploadDownloadCloud } from "assets/icons";
import { cloudOfflineOutline } from "ionicons/icons";

import { SyncStatus } from "./isSynced";

export const getSyncIconDetails = (
	status: SyncStatus | undefined,
	isOnline: boolean,
): { icon?: string; mode?: "ios" | "md" } => {
	if (!isOnline) return { icon: cloudOfflineOutline };
	switch (status) {
		case "merge":
			return { icon: uploadDownloadCloud };
		case "remote":
		case "download":
			return { icon: downloadCloud };
		case "upload":
			return { icon: uploadCloud };
		case "synced":
			return { icon: cloudCheck };
		default:
			return { icon: undefined };
	}
};
