import { FormDeliveryStatus } from "interfaces/FormDeliveryStatus";
import { IFile } from "interfaces/IFile";
import isEqual from "lodash.isequal";
import { IImage } from "../interfaces/IImage";
import { IDrawingRef } from "../interfaces/IDrawingRef";
import { dbPromise } from "../services/idb";

export type IDataValue =
	| null // This is react-hook-forms way of letting us know it's undefined
	| boolean // Regular input
	| number // Regular input
	| string // Regular input
	| boolean[] // Input with multiple=true
	| number[] // Input with multiple=true
	| string[] // Input with multiple=true
	| IImage[] // Images or sketches. These are ultimately lists of objects
	| IFile[] // Uploaded files other than images. These are ultimately lists of objects as well
	| IDrawingRef // Drawing reference
	| { [fieldId: string]: IDataValue } // repeatables are nested FormValues
	| { [fieldId: string]: IDataValue }[]; // repeatables are arrays of more FormValues

export type FormValues<T = IDataValue> = { [fieldId: string]: T };

export interface IRawFormRecord {
	id: string;
	name: string;
	type?: "default";
	active?: boolean;
	is_deleted?: boolean;
	date_created?: Date | string;
	date_updated?: Date | string;
	date_synced?: Date | string;
	synced_by?: string;
	form_id: string;
	asset_id: string;
	project_ref: string;
	data: FormValues;
	changes: string[];
	delivery_status: FormDeliveryStatus;
}

export default class FormRecord implements IRawFormRecord {
	id: string;
	name: string;
	type: "default";
	active: boolean;
	is_deleted: boolean;
	date_created: Date;
	date_updated: Date;
	date_synced?: Date;
	synced_by?: string;
	form_id: string;
	asset_id: string;
	project_ref: string;
	data: FormValues;
	changes: string[];
	delivery_status: FormDeliveryStatus;

	constructor(record: IRawFormRecord) {
		this.id = record.id;
		this.name = record.name;
		this.type = "default";
		this.active = record.active ?? true;
		this.is_deleted = record.is_deleted ?? false;
		this.date_created = record.date_created ? new Date(record.date_created) : new Date();
		this.date_updated = record.date_updated ? new Date(record.date_updated) : new Date();
		this.date_synced = record.date_synced ? new Date(record.date_synced) : undefined;
		this.synced_by = record.synced_by;
		this.form_id = record.form_id;
		this.asset_id = record.asset_id;
		this.project_ref = record.project_ref;
		this.data = record.data;
		this.changes = record.changes ?? [];
		this.delivery_status = record.delivery_status;
	}

	static async getAllKeys() {
		return await (await dbPromise).getAllKeys("records");
	}

	static filter(data: FormRecord[], props?: { includeDeleted: boolean; includeInactive: boolean }) {
		const { includeDeleted = false, includeInactive = false } = props || {};
		if (includeDeleted && includeInactive) return data;
		if (!includeDeleted) data = data.filter((it) => !it.is_deleted);
		if (!includeInactive) data = data.filter((it) => !!it.active);
		return data;
	}

	static async get(id: string, props?: { includeDeleted: boolean; includeInactive: boolean }) {
		const { includeDeleted = true, includeInactive = true } = props || {};
		return await (await dbPromise).get("records", id).then((r) => {
			if (r === undefined) throw new Error(`Key ${id} not found`);
			if (r.is_deleted && !includeDeleted)
				throw new Error(`Key ${id} is archived -marked as deleted- and thus, not readily retrievable`);

			if (!r.active && !includeInactive)
				throw new Error(`Key ${id} is archived -marked as inactive- and thus, not readily retrievable`);
			return new FormRecord(r);
		});
	}

	static async byProjectRef(project_ref: string, filterProps?: { includeDeleted: boolean; includeInactive: boolean }) {
		const data: FormRecord[] = await (await dbPromise)
			.getAllFromIndex("records", "project_ref", project_ref)
			.then((r) => r.map((ri) => new FormRecord(ri)));
		return FormRecord.filter(data, filterProps);
	}

	static async byAssetId(asset_id: string, filterProps?: { includeDeleted: boolean; includeInactive: boolean }) {
		const data: FormRecord[] = await (await dbPromise)
			.getAllFromIndex("records", "asset_id", asset_id)
			.then((r) => r.map((ri) => new FormRecord(ri)));
		return FormRecord.filter(data, filterProps);
	}

	static async getMany(ids: string[], filterProps?: { includeDeleted: boolean; includeInactive: boolean }) {
		const tx = (await dbPromise).transaction("records", "readwrite");
		const store = tx.objectStore("records");
		const data = await Promise.all(ids.map((id) => store.get(id)));
		await tx.done;
		return FormRecord.filter(data.filter(Boolean) as FormRecord[], filterProps);
	}

	static async getAll(filterProps?: { includeDeleted: boolean; includeInactive: boolean }) {
		const data = await (await dbPromise).getAll("records").then((r) => r.map((ri) => new FormRecord(ri)));
		return FormRecord.filter(data, filterProps);
	}

	static async set(data: FormRecord) {
		await (await dbPromise).put("records", data);
		return new FormRecord(data);
	}

	static async setMany(data: FormRecord[]) {
		const tx = (await dbPromise).transaction("records", "readwrite");
		const store = tx.objectStore("records");
		await Promise.all(data.map((it) => store.put(it)));
		await tx.done;
	}

	async save() {
		const former = await FormRecord.get(this.id).catch(() => undefined);
		if (isEqual(former, this)) return;
		this.date_created = this.date_created ?? new Date();
		this.date_updated = new Date();
		return FormRecord.set(this);
	}

	delete() {
		return FormRecord.set(new FormRecord({ ...this, is_deleted: true }));
	}

	static async deactivateMany(ids: string[]) {
		const data = await FormRecord.getMany(ids, { includeDeleted: true, includeInactive: true });
		await FormRecord.setMany(data.map((it) => new FormRecord({ ...it, active: false })));
	}

	static async softDeleteMany(ids: string[]) {
		const data = await FormRecord.getMany(ids, { includeDeleted: true, includeInactive: true });
		await FormRecord.setMany(data.map((it) => new FormRecord({ ...it, is_deleted: true })));
	}

	static async restoreMany(ids: string[]) {
		const data = await FormRecord.getMany(ids, { includeDeleted: true, includeInactive: true });
		await FormRecord.setMany(data.map((it) => new FormRecord({ ...it, is_deleted: false })));
	}

	async hardDelete() {
		return (await dbPromise).delete("records", this.id);
	}
}
