import React, { useMemo } from "react";

import { IonAccordion, IonAccordionGroup, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { UseFormReturn } from "react-hook-form";

import { groupStyles } from "components/common/Form/groupStyles";
import { useGroupFilledIn } from "components/common/Form/hooks/useGroupFilledIn";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";

import { getChildrenTypes } from "../utils/getChildrenTypes";
import { FormFieldFactory } from "../utils/FormFieldFactory";
import useFieldTemplate from "../hooks/useFieldTemplate";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";

interface IProps {
	field: FormField<FormValues>;
	formMethods: UseFormReturn<FormValues>;
	level: number;
	variant: "dark" | "gray" | "white";
	parentInlineIds?: string[];
}
type PropsType = IProps;

const InlineGroup: React.FC<PropsType> = (props) => {
	const { field, formMethods, level, variant, parentInlineIds = [] } = props;

	const siblingTypes = useMemo(() => field.children?.map((it) => it.type), [field]);

	const allRequiredValuesFilled = useGroupFilledIn(field, formMethods);
	const { isRelevant, isDisabled } = useFieldTemplate(field, formMethods.control);

	return isRelevant() ? (
		<IonAccordionGroup id={`field-${field.name}`}>
			<IonAccordion disabled={isDisabled()}>
				{/* Different style depending on level = 1 or level = 2 */}
				<IonItem
					data-testid={`${field.type}.button`}
					slot="header"
					className="Group"
					button={true}
					detail={false}
					lines="none"
					style={{
						...groupStyles(variant).itemStyle,
						...{
							alignItems: "center",
							minHeight: "3rem",
							borderBottom: "1px solid #CBD5E0",
							width: "100%",
							cursor: "pointer",
						},
					}}
				>
					<IonLabel
						className="ion-text-wrap"
						style={{
							fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Roboto, sans-serif",
							textOverflow: "ellipsis",
							overflow: "hidden",
							fontSize: "0.875rem",
							lineHeight: "1rem",
						}}
					>
						{field.label}
					</IonLabel>
					<IonIcon
						src={allRequiredValuesFilled ? checkmarkCircleOutline : ellipseOutline}
						slot="end"
						style={{
							color: allRequiredValuesFilled ? "var(--ion-color-success)" : groupStyles(variant).statusIconStyle.color,
						}}
					/>
				</IonItem>
				{/* Render children */}
				<div slot="content">
					{(field.children || []).map((child) => (
						<FormFieldFactory
							key={child.name}
							field={child}
							methods={formMethods}
							parentType={field.type}
							level={level}
							siblingTypes={siblingTypes}
							childrenTypes={getChildrenTypes(child)}
							parentInlineIds={[...parentInlineIds, field.name]}
						/>
					))}
				</div>
			</IonAccordion>
		</IonAccordionGroup>
	) : (
		<React.Fragment />
	);
};

export default InlineGroup;
