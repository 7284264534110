import { IonIcon, IonList } from "@ionic/react";
import { Button } from "components/common/Button";
import { add, arrowBack } from "ionicons/icons";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import RepeatableTable from "../Repeatable/RepeatableTable";

interface IProps {
	field?: FormField;
	formMethods: UseFormReturn<FormValues>;
	onSubmit: (values: FormValues, changes: string[], history: string[], exit?: boolean, stay?: boolean) => Promise<void>;
	setFilterModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	handleDuplicateItem: (e: React.MouseEvent, id: string) => Promise<void>;
	handleAddItem: () => void;
	handleSave: (exit?: boolean, stay?: boolean) => void;
	isFrozen: boolean;
}

type PropsType = IProps;

const FormPageRepeatable: React.FC<PropsType> = (props) => {
	const {
		field,
		formMethods,
		onSubmit,
		setFilterModalIsOpen,
		handleDuplicateItem,
		handleAddItem,
		handleSave,
		isFrozen,
	} = props;
	const { t, i18n } = useTranslation();

	return (
		<IonList
			style={{
				display: "flex",
				flex: "1",
				flexDirection: "column",
				justifyContent: "space-between",
				paddingTop: "0rem",
			}}
		>
			{field && (
				<RepeatableTable
					field={field}
					formMethods={formMethods}
					setFilterModalIsOpen={setFilterModalIsOpen}
					onSubmit={onSubmit}
					handleDuplicateItem={handleDuplicateItem}
				/>
			)}
			<div style={{ display: "flex", flexDirection: "column", marginBottom: "2rem", marginInline: "1rem" }} data-testid="saveAndCloseDivRepeatable">
				<Button disabled={isFrozen} onClickFunction={handleAddItem} variant="empty">
					<IonIcon
						style={{
							color: "#718096",
							marginRight: ".5rem",
						}}
						icon={add}
						size="small"
					/>
					{i18n.format(t("add_item"), "capitalize")}
				</Button>
				{
					<Button disabled={isFrozen} onClickFunction={() => handleSave(false, false)} variant="filled">
						<IonIcon
							style={{
								color: "white",
								marginRight: ".5rem",
							}}
							icon={arrowBack}
							size="small"
						/>
						{i18n.format(t("close_save"), "capitalize")}
					</Button>
				}
			</div>
		</IonList>
	);
};

export default FormPageRepeatable;
