import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { IonButton, IonLabel } from "@ionic/react";
import { useAppSelector } from "store";

interface IProps {
	navigate: (url: string) => void;
	onHide: () => void;
}
type PropsType = IProps;

const UserPopover: React.FC<PropsType> = (props) => {
	const { t, i18n } = useTranslation();
	const { user, signOut } = useAuthenticator((context) => [context.user]);
	const isOnline = useAppSelector((state) => state.connection.isOnline);

	const session = isOnline ? user.getSignInUserSession() : undefined;

	const LoggedInFragment = (
		<Fragment>
			<IonLabel>{i18n.format(t("logged_in"), "capitalize")}</IonLabel>
			<IonButton
				style={{ width: "100%" }}
				expand="full"
				data-testid="settingsButton"
				onClick={() => {
					props.navigate("/admin");
					props.onHide();
				}}
			>
				{i18n.format(t("settings"), "capitalizeEveryWord")}
			</IonButton>
			<IonButton
				style={{ width: "100%" }}
				expand="full"
				data-testid="signOutButton"
				onClick={() => {
					signOut();
					props.onHide();
				}}
			>
				{i18n.format(t("logout"), "capitalizeEveryWord")}
			</IonButton>
		</Fragment>
	);

	const ExpiredSessionfFragment = (
		<Fragment>
			<IonLabel>{i18n.format(t("session_expired"), "capitalize")}</IonLabel>
			<IonButton
				onClick={() => {
					window.location.reload();
				}}
			>
				{i18n.format(t("refresh_session"), "capitalize")}
			</IonButton>
		</Fragment>
	);
	const OfflineFragment = (
		<Fragment>
			<IonLabel>{i18n.format(t("network_unavailable"), "capitalize")}</IonLabel>
			<IonButton
				style={{ width: "100%" }}
				expand="full"
				onClick={() => {
					props.navigate("/admin");
					props.onHide();
				}}
			>
				{i18n.format(t("settings"), "capitalizeEveryWord")}
			</IonButton>
		</Fragment>
	);

	const renderContent = () => {
		if (!isOnline) {
			return OfflineFragment;
		}
		if (session) {
			return LoggedInFragment;
		}
		return ExpiredSessionfFragment;
	};
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				padding: "1rem",
			}}
		>
			{renderContent()}
		</div>
	);
};

export default UserPopover;
