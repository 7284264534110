import React from "react";
import { IonItem, IonLabel } from "@ionic/react";

import { FormField } from "models/Form";
import { FormValues, IDataValue } from "models/FormRecord";
import { WidgetFactory } from "views/ReportView/components/WidgetFactory";

interface ReportGroupProps {
	formField: FormField;
	value: IDataValue;
	ancestorHistory: string[];
	data: FormValues;
}

export const ReportGroup: React.FC<ReportGroupProps> = (props: ReportGroupProps) => {
	const { value, formField, ancestorHistory, data } = props;
	const shouldRenderChildren = Object.values(value || {}).some(Boolean);

	return (
		<React.Fragment>
			<IonItem
				lines="none"
				style={{
					flex: "auto",
				}}
			>
				<IonLabel
					className="ion-text-wrap"
					style={{
						fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Roboto, sans-serif",
						fontSize: "0.875rem",
						lineHeight: "1rem",
						fontWeight: "bold",
					}}
				>
					{formField.label}
				</IonLabel>
			</IonItem>
			{shouldRenderChildren && formField.children && formField.children.length > 0 && (
				<div
					style={{
						display: "grid",
						borderLeft: "0.25rem solid var(--ion-color-gray200)",
						paddingLeft: "1rem",
						flex: "auto",
					}}
				>
					{formField.children.map((child) => (
						<WidgetFactory
							key={child.name}
							formField={child}
							dataToRender={value as FormValues}
							allData={data}
							ancestorHistory={[...ancestorHistory, formField.name]}
						/>
					))}
				</div>
			)}
		</React.Fragment>
	);
};
