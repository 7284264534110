import { apiClient } from "api";
import { DeliveryStatusOutput } from "interfaces/AssetStatus";
import IWithPagination from "interfaces/IWithPagination";
import Asset, { IRawAsset } from "models/Asset";

export interface GetAssetStatusProps {
	projectRefs?: string[];
	assetIds?: string[];
	excludeProjectRefs?: string[];
	excludeAssetIds?: string[];
	pages?: number;
}

export const getAssetStatus = async function (assetId: string): Promise<DeliveryStatusOutput[]> {
	return await apiClient
		.get<DeliveryStatusOutput[]>(`forms/assetStatus`, {
			params: { assetId },
		})
		.then((res) => res.data);
};

export interface GetAssetStatusProps {
	projectRefs?: string[];
	assetIds?: string[];
	status?: string;
	excludeProjectRefs?: string[];
	excludeAssetIds?: string[];
	excludeStatus?: string;
	includeDeleted?: boolean;
	page?: number;
}

const recursiveGetAssetsWithLatestStatus = async function* (props: GetAssetStatusProps) {
	const {
		projectRefs,
		assetIds,
		status,
		excludeProjectRefs,
		excludeAssetIds,
		excludeStatus,
		includeDeleted = false,
		page = 1,
	} = props;

	let responseLength = 0;
	let perPage = 0;
	const params = {
		...(projectRefs && { projectRef: projectRefs.join(",") }),
		...(assetIds && { assetId: assetIds.join(",") }),
		...(status && { status }),
		...(excludeProjectRefs && { excludeProjectRef: excludeProjectRefs.join(",") }),
		...(excludeAssetIds && { excludeAssetId: excludeAssetIds.join(",") }),
		...(excludeStatus && { excludeStatus }),
		page,
		includeDeleted,
		order: "name.asc",
	};
	while (perPage === responseLength) {
		const { data, pagination } = await apiClient
			.get<IWithPagination<IRawAsset>>(`forms/assetStatus/latest`, {
				params,
			})
			.then((res) => res.data);
		yield { items: data.map((it) => new Asset(it)), total: pagination.total };
		perPage = pagination.perPage;
		responseLength = data.length;
		params.page += 1;
	}
	return;
};

export const getAssetsWithLatestStatus = async function (props: GetAssetStatusProps) {
	const items: Asset[] = [];
	let total: number | undefined;
	for await (const page of recursiveGetAssetsWithLatestStatus(props)) {
		items.push(...page.items);
		total = page.total;
	}
	return { items, total };
};

export const getAssetStatusDiff = async (assetId: string, formId: string, from: string, to?: string) =>
	await apiClient.get(`forms/records/diff/${assetId}/${formId}`, { params: { from, to } }).then((res) => res.data);
