import { adifLocalConfig } from "config/adifLocal";
import adifConfig from "./adif";
import baseConfig from "./base";
import localConfig from "./local";

let config = baseConfig;

if (window.location.hostname === "localhost" && process.env.REACT_APP_LOCAL_API === "adif") config = adifLocalConfig;
else if (window.location.hostname === "localhost") config = localConfig;
else if (window.location.hostname.includes("aite")) config = adifConfig;

export default config;
