import React, { memo, PropsWithChildren } from "react";

import { IonItem, IonLabel } from "@ionic/react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { IImage } from "../../../../interfaces/IImage";
import { FormField } from "../../../../models/Form";
import { FormValues } from "../../../../models/FormRecord";
import Camera from "../components/Camera/Camera";
import Gallery from "../components/Gallery";
import useFieldTemplate from "../hooks/useFieldTemplate";

interface IProps {
	field: FormField<IImage[]>;
	formMethods: UseFormReturn<FormValues>;
}

type PropsType = IProps;
const Images: React.FC<PropsType> = (props) => {
	const { field, formMethods } = props;
	const { isRequired, isRelevant, isHardRequired, isDisabled, isFrozen, name } = useFieldTemplate(
		field,
		formMethods.control,
	);

	useWatch({ name: field.name, control: formMethods.control });

	const numberImages = ((formMethods.getValues(name) as IImage[]) || []).filter((it) => !it._is_deleted).length;

	return (
		<div
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{isRelevant() ? (
				<IonItem lines="none">
					<IonLabel
						className="ion-text-wrap capitalize-first-letter"
						position="stacked"
						mode="ios"
						style={{
							opacity: isFrozen || isDisabled() ? 0.3 : 1,
						}}
					>
						{field.label} {field.max ? ` (${numberImages}/${field.max})` : ""}
						{(isRequired() || isHardRequired()) && <span style={{ color: "red" }}>&nbsp;*</span>}
					</IonLabel>
					<Gallery formMethods={formMethods} field={field} variant="image">
						{!isFrozen && !isDisabled() && <Camera formMethods={formMethods} field={field} numberImages={numberImages} />}
					</Gallery>
				</IonItem>
			) : null}
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Images, propsAreEqual);
export default Memoized;
