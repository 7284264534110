import React from "react";

import { IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import * as iconLibrary from "ionicons/icons";
import { UseFormReturn } from "react-hook-form";

import { chevronRight } from "assets/icons";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import { useAppDispatch, useAppSelector } from "store";
import { actions } from "store/slices/history";

import { useGroupFilledIn } from "../../hooks/useGroupFilledIn";

interface IProps {
	id: string;
	idx: number;
	title: string;
	subtitle?: string;
	iconColor: string;
	iconSrc: string;
	field: FormField;
	isFrozen: boolean;
	formMethods: UseFormReturn<FormValues>;
	setDeleteOpenModalId: (id: string | number) => void;
	handleDuplicateItem: (e: React.MouseEvent, id: string) => Promise<void>;
}

const RepeatableItem = (props: IProps) => {
	const { id, idx, title, subtitle, iconColor, iconSrc, field, isFrozen, formMethods, setDeleteOpenModalId } = props;

	const dispatch = useAppDispatch();
	const isOnline = useAppSelector((state) => state.connection.isOnline);
	const allRequiredValuesFilled = useGroupFilledIn(field, formMethods, idx);

	return (
		<IonItem
			button={true}
			detail={false}
			lines="none"
			style={{
				backgroundColor: "var(--ion-background-color)",
				"--background": "var(--ion-background-color)",
				borderBottom: "1px solid #CBD5E0",
				fontSize: "0.875rem",
			}}
			onClick={() => {
				dispatch(actions.pushHistory(`${idx}`));
			}}
		>
			<IonLabel className="ion-text-wrap" style={{ width: "100%" }}>
				<IonLabel className="capitalize-first-letter">{title}</IonLabel>
				{subtitle ? (
					<IonLabel
						className="capitalize-first-letter"
						style={{ color: "var(--ion-color-medium-tint)", fontSize: "0.65rem" }}
					>
						{subtitle}
					</IonLabel>
				) : (
					<React.Fragment />
				)}
			</IonLabel>

			{field.itemIconConfig ? (
				<IonIcon
					slot="end"
					size="small"
					style={{
						fontSize: "1.25rem",
						color: iconColor,
						width: "3rem",
						margin: 0,
					}}
					icon={iconLibrary[iconSrc as unknown as keyof typeof iconLibrary]}
					title={field.itemIconConfig.label}
				/>
			) : (
				<IonIcon
					src={allRequiredValuesFilled ? iconLibrary.checkmarkCircleOutline : iconLibrary.ellipseOutline}
					slot="end"
					style={{
						color: allRequiredValuesFilled ? "var(--ion-color-success)" : "var(--ion-color-medium-tint)",
						width: "3rem",
						margin: 0,
					}}
					title={"status"}
				/>
			)}
			<IonButton
				slot="end"
				color="secondary"
				size="default"
				fill={isOnline ? "solid" : "clear"}
				disabled={!isOnline || isFrozen}
				style={{
					"--box-shadow": "unset",
					"--padding-start": 0,
					"--padding-end": 0,
					aspectRatio: "1 / 1",
					height: "2rem",
					margin: "0.5rem 0.5rem",
				}}
				onClick={(e) => props.handleDuplicateItem(e, id)}
			>
				<IonIcon size="small" style={{ color: "#718096", zIndex: 10 }} src={iconLibrary.duplicateOutline} />
			</IonButton>
			<IonButton
				slot="end"
				color="secondary"
				size="default"
				fill={isOnline ? "solid" : "clear"}
				disabled={!isOnline || isFrozen}
				style={{
					"--box-shadow": "unset",
					"--padding-start": 0,
					"--padding-end": 0,
					aspectRatio: "1 / 1",
					height: "2rem",
					margin: "0.5rem 0.5rem",
				}}
				onClick={(e) => {
					e.stopPropagation();
					setDeleteOpenModalId(id || idx);
				}}
			>
				<IonIcon size="small" style={{ color: "#718096", zIndex: 10 }} src={iconLibrary.trashOutline} />
			</IonButton>
			<IonIcon
				src={chevronRight}
				slot="end"
				size="small"
				style={{
					color: "#4A5568",
					marginRight: "0",
					marginLeft: "0",
				}}
			/>
		</IonItem>
	);
};

export default RepeatableItem;
