import React from "react";

import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { UseFormReturn } from "react-hook-form";

import { groupStyles } from "components/common/Form/groupStyles";
import { useGroupFilledIn } from "components/common/Form/hooks/useGroupFilledIn";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import { useAppDispatch } from "store";
import { actions as historyActions } from "store/slices/history";
import useFieldTemplate from "../hooks/useFieldTemplate";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";
import { chevronRight } from "assets/icons";

interface IProps {
	field: FormField<FormValues>;
	formMethods: UseFormReturn<FormValues>;
	variant: "dark" | "gray" | "white";
	parentInlineIds?: string[];
}
type PropsType = IProps;

export const DrillDownGroup: React.FC<PropsType> = (props) => {
	const { field, formMethods, variant, parentInlineIds = [] } = props;
	const dispatch = useAppDispatch();

	const { isRelevant, isDisabled } = useFieldTemplate(field, formMethods.control);
	const allRequiredValuesFilled = useGroupFilledIn(field, formMethods);

	return isRelevant() ? (
		<IonItem
			data-testid={`${field.type}.button`}
			className="Group"
			id={`field-${field.name}`}
			onClick={() => {
				if (parentInlineIds.length) {
					dispatch(historyActions.pushHistoryMany([...parentInlineIds, field.name]));
				} else dispatch(historyActions.pushHistory(field.name));
			}}
			button={true}
			detail={false}
			disabled={isDisabled()}
			lines="none"
			style={{
				...groupStyles(variant).itemStyle,
				...{
					alignItems: "center",
					minHeight: "3rem",
					borderBottom: "1px solid #CBD5E0",
					width: "100%",
					cursor: "pointer",
				},
			}}
		>
			<IonLabel
				className="ion-text-wrap"
				style={{
					fontFamily: "-apple-system, BlinkMacSystemFont, 'Helvetica Neue', Roboto, sans-serif",
					fontSize: "0.875rem",
					lineHeight: "1rem",
				}}
			>
				{field.label}
			</IonLabel>

			<IonIcon
				src={allRequiredValuesFilled ? checkmarkCircleOutline : ellipseOutline}
				slot="end"
				style={{
					color: allRequiredValuesFilled ? "var(--ion-color-success)" : groupStyles(variant).statusIconStyle.color,
				}}
			/>

			<IonIcon
				src={chevronRight}
				slot="end"
				size="small"
				style={{
					color: `${groupStyles(variant).chevronIconStyle.color}`,
					marginRight: "0",
					marginLeft: "10px",
				}}
			/>
		</IonItem>
	) : (
		<React.Fragment />
	);
};
