import { checkOnlineStatus } from "api/checkOnlineStatus";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { actions } from "store/slices/connection";

export const CheckOnline: React.FC = () => {
	const dispatch = useAppDispatch();
	const onlineStatus = useAppSelector((state) => state.connection.isOnline);

	useEffect(() => {
		const ping = async () => {
			const pingResult = await checkOnlineStatus();
			if (pingResult !== onlineStatus) dispatch(actions.setOnlineState(pingResult));
		};
		ping();
		const interval = setInterval(ping, 40e3);
		return () => clearInterval(interval);
	}, [dispatch, onlineStatus]);

	return <></>;
};
