import React, { createContext } from "react";

import buildFieldReferenceLibrary from "components/common/Form/utils/buildReferenceLibrary";
import { FieldReferenceLibrary } from "interfaces/FieldReference";
import { FormField } from "models/Form";
import FormRecord, { FormValues, IDataValue } from "models/FormRecord";
import { WidgetFactory } from "views/ReportView/components/WidgetFactory";

interface ReportContextInterface {
	fieldReferenceLibrary: FieldReferenceLibrary;
	changes: string[] | undefined;
	baseContext: Record<string, IDataValue>;
}
export const ReportContext = createContext<ReportContextInterface>({
	fieldReferenceLibrary: {},
	changes: undefined,
	baseContext: {},
});

interface ReportRendererInterface {
	formFields: FormField[];
	record: FormRecord;
	changes: string[] | undefined;
	baseContext: { [formId: string]: FormValues };
}

export const ReportRenderer: React.FC<ReportRendererInterface> = (props: ReportRendererInterface) => {
	const { formFields, record, changes, baseContext } = props;
	const fieldReferenceLibrary = buildFieldReferenceLibrary(formFields);

	return (
		<ReportContext.Provider
			value={{
				fieldReferenceLibrary,
				changes,
				baseContext,
			}}
		>
			<div
				style={{
					width: "100%",
					marginLeft: "1.5rem",
					background: "var(--ion-background-color)",
				}}
			>
				{formFields.map((field) => (
					<WidgetFactory
						key={field.name}
						formField={field}
						allData={record.data}
						dataToRender={record.data}
						ancestorHistory={[]}
					/>
				))}
			</div>
		</ReportContext.Provider>
	);
};
