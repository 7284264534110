export const adifLocalConfig = {
	API: "https://api.dev.aite.dhub.arup.com",
	S3_IMAGES: "",
	AWS_REGION: "eu-west-1",
	AWS_COGNITO_DOMAIN: "adifiteauth.auth.eu-west-1.amazoncognito.com",
	AWS_COGNITO_POOL_ID: "eu-west-1_6zrfPeXrO",
	AWS_COGNITO_WEB_CLIENT_ID: "6dgpaqnhv9n45dtcn9n17amohc",
	RELEASE: process.env.REACT_APP_GIT_SHA?.slice(0, 7) || "development",
	VAPID_PUBLIC_KEY: "BNyH2hFG1vGOr1lOEjKiSZq9nMF4TJGbUXJSb4A5mWLpOehY0Ip_8ZeEjRNVCREZNI0GdCCFHPD6hXOLVNDo4zA",
};
