import { apiClient } from "api";

export const getBIMModelDelivered = async (assetId: string) =>
	await apiClient.get(`/ite/ite/bim/${assetId}`).then((res) => res.data);

export interface EntregarModeloBIMProps {
	assetId: string;
	delivered: boolean;
}

export const putBIMModelDelivered = async (params: EntregarModeloBIMProps) =>
	await apiClient.put(`/ite/ite/bim`, params).then((res) => res.data);
