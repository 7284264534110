import React, { useState, useEffect, useCallback, PropsWithChildren, memo } from "react";

import { AuthenticationProvider, AxiosInterceptor } from "@arup-group/cognito-authenticator";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
	IonModal,
	IonContent,
	IonIcon,
	IonButton,
	IonItem,
	IonList,
	IonText,
	IonCheckbox,
	IonListHeader,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonSpinner,
	IonProgressBar,
} from "@ionic/react";
import { AxiosRequestConfig } from "axios";
import { useTranslation } from "react-i18next";

import { Button } from "components/common/Button";
import config from "config";
import { IImage } from "interfaces/IImage";
import FormRecord from "models/FormRecord";
import LocalFile from "models/LocalFile";
import LocalImage from "models/LocalImage";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { cleanup } from "utils/cleanup";
import syncFunction from "utils/sync";
import { getSyncStatus, SyncStatus } from "utils/sync/isSynced";
import { getAllPendingDownload } from "utils/sync/pendingDownload";
import { getAllPendingUpload } from "utils/sync/pendingUpload";
import { getCacheSize, getIdbSize } from "utils/sync/size";
import { close, downloadCloud, uploadCloud, cloudCheck } from "assets/icons";
import { BackgroundSyncOutput, performBackgroundSyncThunk } from "store/slices/remote/thunks";
import { IRecordSummary } from "api/records";
import { PayloadAction } from "@reduxjs/toolkit";

interface IProps {
	isOpen: boolean;
	projectRef: string;
	assetId?: string;
	title: string;
	onClose: () => void;
	skipBackgroundSync?: boolean;
}

interface SizeEstimation {
	data: number;
	photos: number;
	files: number;
}
interface PendingState {
	status: SyncStatus;
	upload: {
		records: FormRecord[];
		photos: LocalImage[];
		files: LocalFile[];
		sizes: SizeEstimation;
	};
	download: {
		records: FormRecord[];
		photos: IImage[];
		sizes: SizeEstimation;
	};
	local: {
		size: number;
	};
}

const SyncModal: React.FC<IProps> = (props: IProps) => {
	const { isOpen, projectRef, assetId, title, onClose, skipBackgroundSync } = props;
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const remoteSlice = useAppSelector((store) => store.remote);

	const { user } = useAuthenticator((context) => [context.user]);

	const [token, setToken] = useState("");
	const [statusData, setStatusData] = useState<PendingState>();
	const [syncing, setSyncing] = useState(false);
	const [cleaning, setCleaning] = useState(false);
	const [progress, setProgress] = useState(0);
	const [timeToRetry, setTimeToRetry] = useState(1000);
	const [fetchImages, setFetchImages] = useState(false);

	const initialize = useCallback(async () => {
		let isCancelled = false;
		console.log("Refreshing sync modal state");
		// Reset
		setStatusData(undefined);

		// Background sync should be performed first of all, unless explicitely skipped
		// This is to ensure that we are not performing any operations without being sure
		// about the state we are in. This may be skipped in some cases, such as downloading
		// fully remote assets.
		let remoteSummaries: IRecordSummary[] = remoteSlice.data;
		if (!skipBackgroundSync) {
			const action = (await dispatch(
				performBackgroundSyncThunk({ projectRef, assetId }),
			)) as PayloadAction<BackgroundSyncOutput>;
			remoteSummaries = action.payload.remoteSummaries;
		}

		// Check on download pending stuff
		const {
			records: recordsDownload,
			photos: photosDownload,
			sizes: downloadSizes,
		} = await getAllPendingDownload(projectRef, assetId);

		// Check on upload pending stuff
		const {
			records: recordsUpload,
			photos: photosUpload,
			files: filesUpload,
			sizes: uploadSizes,
		} = await getAllPendingUpload(remoteSummaries, projectRef, assetId);

		// Check on consumed storage
		const idbSize = await getIdbSize(projectRef, assetId);
		const cacheSize = await getCacheSize(projectRef, assetId);

		if (isCancelled) return;
		const syncStatus = await getSyncStatus(remoteSummaries, projectRef, assetId);
		setStatusData({
			status: syncStatus,
			upload: {
				records: recordsUpload,
				photos: photosUpload,
				files: filesUpload,
				sizes: uploadSizes,
			},
			download: {
				records: recordsDownload,
				photos: photosDownload,
				sizes: downloadSizes,
			},
			local: {
				size: idbSize + cacheSize,
			},
		});
		return () => {
			isCancelled = true;
		};
	}, [projectRef, assetId]);

	// Normal initialization
	useEffect(() => {
		if (!user || !isOpen) return;

		let isCancelled = false;
		const session = user.getSignInUserSession();
		const idToken = session?.getIdToken();
		if (idToken) {
			setToken(idToken.getJwtToken());
		}
		initialize()
			.then(() => {
				if (isCancelled) return;
				setTimeToRetry(1000);
			}) // If it manages to initialize, reset the timeToRetry
			.catch(async (err) => {
				// If there is an error, wait timeToRetry miliseconds and try again
				// We set a new timeToRetry following exponential backoff
				console.warn(err);
				if (isCancelled) return;
				setTimeout(() => setTimeToRetry((t) => 2 * t), timeToRetry);
			});
		return () => {
			isCancelled = true;
		};
	}, [initialize, timeToRetry, user, isOpen, title]);

	// Performs cleaning
	useEffect(() => {
		if (!isOpen || !cleaning) return;
		let isCancelled = false;
		cleanup(token, projectRef, assetId).then(async () => {
			if (isCancelled) return;
			await dispatch(performBackgroundSyncThunk({ projectRef }));
			if (isCancelled) return;
			setCleaning(false);
			onClose();
			history.push(`/${projectRef}`);
		});
		return () => {
			isCancelled = true;
		};
	}, [cleaning]);

	// Retries sync with exponential backoff
	useEffect(() => {
		let isCancelled = false;
		if (!syncing || !statusData || !isOpen) return;
		const sizeEstimation =
			statusData.upload.sizes.data +
			statusData.upload.sizes.photos +
			statusData.upload.sizes.files +
			statusData.download.sizes.data +
			(fetchImages ? statusData.download.sizes.photos : 0);
		syncFunction(
			token,
			statusData.upload.records,
			statusData.upload.photos,
			statusData.upload.files,
			projectRef,
			assetId,
			fetchImages,
			sizeEstimation,
			setProgress,
		)
			.then(async () => {
				if (isCancelled) return;
				console.log("Updating state after sync");
				return dispatch(performBackgroundSyncThunk({ projectRef, assetId }));
			})
			.then(() => {
				console.log("Async operation is finished");
				if (isCancelled) return;
				setSyncing(false);
				onClose();
				// window.location.reload();
			});
		return () => {
			isCancelled = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [syncing]);

	const formatAsSize = (bytes: number, roundStrategy: "ceil" | "floor") => {
		const mb = bytes / 1024 / 1024;
		const round = roundStrategy === "ceil" ? Math.ceil(mb) : Math.floor(mb);
		const formatter = Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });
		return `${formatter.format(round)}Mb`;
	};

	const isPendingDownload =
		statusData?.status === "download" || statusData?.status === "merge" || statusData?.status === "remote";
	const isPendingUpload = statusData?.status === "upload" || statusData?.status === "merge";

	return (
		<IonModal
			isOpen={isOpen}
			canDismiss={true}
			backdropDismiss={false}
			onDidDismiss={() => {
				onClose();
				setTimeToRetry(1000);
				setSyncing(false);
				setCleaning(false);
				setFetchImages(false);
				setProgress(0);
			}}
		>
			<AuthenticationProvider
				region={config.AWS_REGION}
				userPoolId={config.AWS_COGNITO_POOL_ID}
				userPoolWebClientId={config.AWS_COGNITO_WEB_CLIENT_ID}
				domain={config.AWS_COGNITO_DOMAIN}
				allowSignUp={false}
				azureADProviderId="AzureAD"
			>
				<AxiosInterceptor
					requestMatcher={(r: AxiosRequestConfig) =>
						r.url?.includes("dhub.arup") || r.url?.includes("localhost") || false
					}
				/>
				<IonHeader mode="ios">
					<IonToolbar style={{ alignItems: "center", "--border-width": 0 }}>
						<IonTitle>{title}</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={onClose}>
								<IonIcon icon={close} size="small" style={{ strokeWidth: 1 }} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
					{syncing && <IonProgressBar color="gray500" value={progress} />}
				</IonHeader>
				<IonContent forceOverscroll={false}>
					<div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
						<IonList lines="none" style={{ paddingTop: "0rem", paddingBottom: "0rem" }}>
							<IonListHeader lines="full" style={{ marginTop: 0, borderBottom: "2px solid gray500" }}>
								<IonText>{i18n.format(t("synchronization"), "capitalize")}</IonText>
							</IonListHeader>
							<IonItem
								color="secondary"
								style={{ "--min-height": "4.5rem" }}
								lines={!isPendingDownload ? "full" : "none"}
							>
								<IonText className="ion-text-wrap" style={{ marginRight: "1.5rem" }}>
									{isPendingDownload
										? i18n.format(t("dataToDownloadMsg"), "capitalize")
										: i18n.format(t("noDataToDownloadMsg"), "capitalize")}
								</IonText>
								<IonIcon
									size="large"
									src={isPendingDownload ? downloadCloud : cloudCheck}
									color={isPendingDownload ? "danger" : "success"}
								/>
							</IonItem>
							{isPendingDownload ? (
								<React.Fragment>
									<IonItem lines="inset" style={{ margin: "0 .5rem" }}>
										<IonText className="ion-text-wrap" style={{ fontSize: "14px" }}>
											{i18n.format(t("dataToDownload"), "capitalize")}
										</IonText>
										<IonText slot="end">
											{statusData ? (
												formatAsSize(statusData?.download.sizes.data || 0, "ceil")
											) : (
												<IonSpinner name="lines-sharp-small" color="gray500" />
											)}
										</IonText>
									</IonItem>
								</React.Fragment>
							) : (
								<React.Fragment />
							)}
							<IonItem lines="inset" style={{ margin: "0 .5rem", "--color": "gray500" }}>
								<IonCheckbox
									id="fechaAllImagesCheckbox"
									aria-label="fetch all images"
									style={{ marginRight: "1rem", width: "auto" }}
									color="secondary"
									defaultChecked={false}
									disabled={!statusData || statusData.download.sizes.photos === 0}
									onIonChange={(e) => setFetchImages(e.detail.checked)}
								/>
								<IonText className="ion-text-wrap" color={fetchImages ? "default" : "gray500"}>
									{i18n.format(t("downloadImages"), "capitalize")}
								</IonText>
								<IonText slot="end" color={fetchImages ? "default" : "gray500"}>
									{statusData ? (
										formatAsSize(statusData?.download.sizes.photos || 0, "ceil")
									) : (
										<IonSpinner name="lines-sharp-small" color="gray500" />
									)}
								</IonText>
							</IonItem>
							<IonItem color="secondary" style={{ "--min-height": "4.5rem" }}>
								<IonText className="ion-text-wrap" style={{ marginRight: "1.5rem" }}>
									{isPendingUpload
										? i18n.format(t("dataToUploadMsg"), "capitalize")
										: i18n.format(t("noDataToUploadMsg"), "capitalize")}
								</IonText>
								<IonIcon
									size="large"
									src={isPendingUpload ? uploadCloud : cloudCheck}
									color={isPendingUpload ? "danger" : "success"}
								/>
							</IonItem>
							<IonItem lines="inset" style={{ margin: "0 .5rem" }}>
								<IonText className="ion-text-wrap" style={{ fontSize: "14px" }}>
									{i18n.format(t("localData"), "capitalize")}
								</IonText>
								<IonText slot="end">
									{statusData ? (
										formatAsSize(statusData?.local.size || 0, "ceil")
									) : (
										<IonSpinner name="lines-sharp-small" color="gray500" />
									)}
								</IonText>
							</IonItem>
							{isPendingUpload ? (
								<React.Fragment>
									<IonItem lines="inset" style={{ margin: "0 .5rem" }}>
										<IonText className="ion-text-wrap" style={{ fontSize: "14px" }}>
											{i18n.format(t("dataToUpload"), "capitalize")}
										</IonText>
										<IonText slot="end">
											{statusData ? (
												formatAsSize(
													(statusData?.upload.sizes.photos || 0) +
														(statusData?.upload.sizes.files || 0) +
														(statusData?.upload.sizes.data || 0),
													"ceil",
												)
											) : (
												<IonSpinner name="lines-sharp-small" color="gray500" />
											)}
										</IonText>
									</IonItem>
								</React.Fragment>
							) : (
								<React.Fragment />
							)}
						</IonList>

						<div
							style={{
								display: "flex",
								flexDirection: "column",
								marginBottom: "2rem",
								marginInline: "1rem",
							}}
						>
							<Button
								variant="filled"
								onClickFunction={() => setCleaning(true)}
								disabled={
									!statusData ||
									cleaning ||
									statusData.status === "upload" ||
									statusData.status === "merge" ||
									statusData.status === "remote"
								}
							>
								<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
									<IonText>{i18n.format(t("desync"), "capitalize")}</IonText>
								</div>
							</Button>

							<Button
								variant="filled"
								onClickFunction={() => setSyncing(true)}
								disabled={!statusData || (statusData.status === "synced" && !fetchImages) || syncing}
							>
								{syncing ? (
									<IonSpinner name="lines-sharp-small" color="white" />
								) : (
									<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
										<IonText>{i18n.format(t("sync"), "capitalize")}</IonText>
									</div>
								)}
							</Button>
						</div>
					</div>
				</IonContent>
			</AuthenticationProvider>
		</IonModal>
	);
};

const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) =>
	prevProps.projectRef === nextProps.projectRef &&
	prevProps.assetId === nextProps.assetId &&
	prevProps.isOpen === nextProps.isOpen &&
	prevProps.title === nextProps.title;

const Memoized = memo(SyncModal, propsAreEqual);
export default Memoized;
