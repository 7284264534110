import { FormFeatureRecord } from "interfaces/FormFeatureRecord";
import { dbPromise } from "../services/idb";

export interface IConfiguration {
	id: string;
	project_ref: string;
	default_form_id: string;
	form_ids: string[];
	features: FormFeatureRecord[];
	configuration_filter: ConfigurationFilter[];
}

export interface ConfigurationFilter {
	form_id: string;
	fields: string[];
}

export interface IRawProject {
	ref: string;
	name: string;
	language?: string;
	image?: string;
	location?: string;
	created_at: Date | string;
	configuration: IConfiguration;
	is_deleted?: boolean;
	features?: string[];
	tecnicos: string[] | undefined;
}

export default class Project implements IRawProject {
	ref: string;
	name: string;
	language?: string;
	image?: string;
	location?: string;
	created_at: Date;
	configuration: IConfiguration;
	is_deleted: boolean;
	features: string[];
	tecnicos: string[];

	constructor(project: IRawProject) {
		this.ref = project.ref;
		this.name = project.name;
		this.language = project.language;
		this.image = project.image;
		this.location = project.location;
		this.created_at = new Date(project.created_at);
		this.configuration = project.configuration;
		this.is_deleted = project.is_deleted ?? false;
		this.features = project.features ?? [];
		this.tecnicos = project.tecnicos ?? [];
	}

	static async getAllKeys() {
		return await (await dbPromise).getAllKeys("projects");
	}

	static async get(id: string, includeDeleted = false) {
		return await (await dbPromise).get("projects", id).then((r) => {
			if (r === undefined) throw new Error(`Key ${id} not found`);
			if (r.is_deleted && !includeDeleted)
				throw new Error(`Key ${id} is archived -marked as deleted- and thus, not readily retrievable`);
			return new Project(r);
		});
	}

	static async getMany(ids: string[], includeDeleted = false) {
		const tx = (await dbPromise).transaction("projects", "readwrite");
		const store = tx.objectStore("projects");
		const data = await Promise.all(ids.map((id) => store.get(id)));
		await tx.done;
		if (includeDeleted) return data.filter((it): it is Project => !!it);
		return data.filter((it): it is Project => !!it && !it.is_deleted);
	}

	static async getAll(includeDeleted = false) {
		const data = await (await dbPromise).getAll("projects").then((r) => r.map((ri) => new Project(ri)));
		if (includeDeleted) return data;
		return data.filter((it) => !it.is_deleted);
	}

	static async set(data: Project) {
		await (await dbPromise).put("projects", data);
		return new Project(data);
	}

	static async setMany(data: Project[]) {
		const tx = (await dbPromise).transaction("projects", "readwrite");
		const store = tx.objectStore("projects");
		await Promise.all(data.map((it) => store.put(it)));
		await tx.done;
	}

	static async deleteMany(ids: string[]) {
		const data = await Project.getMany(ids);
		await Project.setMany(data.map((it) => new Project({ ...it, is_deleted: true })));
	}

	static async hasFeature(projectRef: string, feature: string): Promise<boolean> {
		const project = await Project.get(projectRef);
		const features: string[] = [...project.features, ...project.configuration.features.map((it) => it.ref)];
		return features.includes(feature);
	}

	delete() {
		return Project.set(new Project({ ...this, is_deleted: true }));
	}

	save() {
		return Project.set(this);
	}
}
