import { apiClient } from "api";

export interface GetSignedUrlRequest {
	filepath: string;
	type: "image" | "file";
	thumbnail?: boolean;
}
export const getSignedUrls = async (data: GetSignedUrlRequest[]) => {
	return await apiClient
		.post<{ [filepath: string]: string }>(`forms/getSignedUrls/CFUrls`, data)
		.then((res) => res.data);
};

export interface CookieResponse {
	cookies: {
		"CloudFront-Key-Pair-Id": string;
		"CloudFront-Signature": string;
		"CloudFront-Expires"?: number;
		"CloudFront-Policy"?: string;
	};
	paths: string[];
}
export const getSignedCookies = async (data: { assetId: string }): Promise<CookieResponse> => {
	return await apiClient.post<CookieResponse>("forms/getSignedCookies/CFCookies", data).then((res) => res.data);
};
