import React from "react";
import { IonItem, IonLabel } from "@ionic/react";

import { FormField } from "models/Form";
import { IDataValue } from "models/FormRecord";

interface ReportCoordinatesProps {
	formField: FormField;
	value: IDataValue;
	highlight?: boolean;
}
export const ReportCoordinates: React.FC<ReportCoordinatesProps> = (props: ReportCoordinatesProps) => {
	const { value, formField, highlight = false } = props;
	const coordinates: [number, number] | undefined =
		typeof value === "object" && value && "coordinates" in value && Array.isArray(value.coordinates)
			? (value.coordinates as [number, number])
			: undefined;
	if (!coordinates) return <React.Fragment />;

	return (
		<div className="no-break" id={`field-${formField.name}`}>
			<IonItem lines="none">
				<IonLabel className="ion-text-wrap" position="stacked" mode="ios">
					<div style={{ color: "var(--ion-color-medium)" }}>{formField.label}</div>
					<div className={highlight ? "highlighted" : undefined}>Latitud: {coordinates[1].toFixed(6)}</div>
					<div className={highlight ? "highlighted" : undefined}>Longitud: {coordinates[0].toFixed(6)}</div>
				</IonLabel>
			</IonItem>
		</div>
	);
};
