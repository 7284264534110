import React, { memo, PropsWithChildren } from "react";

import { IonTextarea, IonItem, IonLabel, IonNote, IonIcon, IonText } from "@ionic/react";
import { Controller, UseFormReturn } from "react-hook-form";

import { FormField } from "../../../../models/Form";
import { FormValues } from "../../../../models/FormRecord";
import useFieldTemplate from "../hooks/useFieldTemplate";
import { Divider } from "components/common/Form/components/Divider";
import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";

interface IProps {
	field: FormField<string>;
	formMethods: UseFormReturn<FormValues>;
}

type PropsType = IProps;
const TextArea: React.FC<PropsType> = (props) => {
	const { field, formMethods } = props;
	const { name, alert, isRelevant, isRequired, isDisabled, isHardRequired, isFrozen } = useFieldTemplate(
		field,
		formMethods.control,
	);

	return (
		<div
			className="Input"
			id={`field-${field.name}`}
			style={{
				backgroundColor: "var(--ion-background-color)",
			}}
		>
			{isRelevant() && (
				<Controller
					name={name}
					control={formMethods.control}
					rules={{
						required: isHardRequired() && isRelevant(),
					}}
					defaultValue={field.defaultValue}
					render={({ field: fieldRenderProps }) => {
						return (
							<IonItem lines="none" disabled={isFrozen || isDisabled()}>
								<IonLabel className="ion-text-wrap" position="stacked" mode="ios">
									{field.label}
									{(isRequired() || isHardRequired()) && <span style={{ color: "red" }}>&nbsp;*</span>}
								</IonLabel>
								<IonTextarea
									aria-label={field.label}
									data-testid={`${field.type}:input`}
									// React Hook Form Managed
									ref={fieldRenderProps.ref}
									name={fieldRenderProps.name}
									value={fieldRenderProps.value as string}
									onIonBlur={fieldRenderProps.onBlur}
									onIonChange={fieldRenderProps.onChange}
									// Other config
									autoGrow={true}
									rows={5}
									autocapitalize="sentences"
									placeholder={field.description}
									enterkeyhint="next"
								/>
								<Divider color="var(--ion-color-medium)" />
								{alert && (
									<IonNote
										data-testid={`${field.type}:note`}
										color={field.alertColor}
										style={{
											display: "flex",
											gap: ".25rem",
											alignItems: "start",
											textAlign: "start",
											margin: ".25rem 0",
											fontSize: ".7rem",
										}}
									>
										<IonIcon
											icon={
												field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
											}
											color={field.alertColor}
											size="small"
										/>
										<IonText style={{ textAlign: "start", lineHeight: 1 }}>{alert}</IonText>
									</IonNote>
								)}
							</IonItem>
						);
					}}
				/>
			)}
		</div>
	);
};

const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(TextArea, propsAreEqual);
export default Memoized;
