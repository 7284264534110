import React, { memo, PropsWithChildren, useCallback, useState } from "react";

import { UseFormReturn } from "react-hook-form";

import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import { useAppSelector } from "store";

import DeleteItemModal from "./DeleteItemModal";
import RepeatableItem from "./RepeatableItem";
import RepeatableHeader from "./RepeatableHeader";
import useRepeatableItems from "./hooks/useRepeatableItems";
import { Virtuoso } from "react-virtuoso";
import { IonContent } from "@ionic/react";
import { applyFilters } from "components/modals/FiltersModal/utils/filterUtils";
import { getEmail } from "utils/getEmail";
import { updateRepeatableAdditionalData } from "components/common/Form/components/FormPage/addRepeatableUpdatedAtUpdatedBy";

interface IProps {
	field: FormField;
	formMethods: UseFormReturn<FormValues>;
	setFilterModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSubmit: (values: FormValues, changes: string[], history: string[], exit?: boolean, stay?: boolean) => Promise<void>;
	handleDuplicateItem: (e: React.MouseEvent, id: string) => Promise<void>;
}

const RepeatableTable: React.FC<IProps> = (props: IProps) => {
	const { field, formMethods, setFilterModalIsOpen, onSubmit, handleDuplicateItem } = props;

	const [deleteModalOpenId, setDeleteModalOpenId] = useState<string | number>();
	const { filterFields, filterValues } = useAppSelector((state) => state.filters);
	const fieldReferenceLibrary = useAppSelector((store) => store.form.fieldReferenceLibrary);
	const historySlice = useAppSelector((state) => state.history);
	const repeatableFullPath = historySlice.list.join(".");

	const isFrozen = useAppSelector((store) => store.form.isFrozen);

	// When browsing an item of the list, omit the number at the end (item id)
	// for the purposes of this component's history
	const repeatableHistory = Number.isNaN(Number(historySlice.active))
		? historySlice.list
		: historySlice.list.slice(0, -1);

	const extendedItems = useRepeatableItems(field, formMethods, repeatableHistory);

	const handleDeleteItem = useCallback(
		async (idOrIdxToDelete: string | number) => {
			const user = await getEmail();
			const currValues = formMethods.getValues(repeatableFullPath) as FormValues[];
			let foundIdx: number;
			if (typeof idOrIdxToDelete === "string") {
				foundIdx = currValues.findIndex((it) => it.id === idOrIdxToDelete);
			} else {
				// This should not happen normally but it's a way to cover us against
				// potential items that might have no ID (some do due to legacy bugs).
				// This should allow us to delete them reliably
				foundIdx = idOrIdxToDelete;
			}
			const deleteFlagPath = [repeatableFullPath, foundIdx, "_is_deleted"].join(".");
			updateRepeatableAdditionalData([`${repeatableFullPath}.${foundIdx}`], fieldReferenceLibrary, formMethods, user);
			formMethods.setValue(deleteFlagPath, true);
			await formMethods.handleSubmit(async (values) => {
				formMethods.resetField(deleteFlagPath, { defaultValue: true });
				await onSubmit(values, [deleteFlagPath], repeatableHistory, false, true);
			}, console.error)();
		},
		[repeatableFullPath, formMethods, extendedItems],
	);

	const filteredIds = Object.values(filterValues).length
		? applyFilters(
				extendedItems.map((it) => it.item),
				filterFields,
				filterValues,
				field.name,
		  )
		: extendedItems.map((it) => it.item.id);

	const filteredItems = extendedItems.filter(({ item }) => filteredIds.includes(item.id as string));

	return (
		<>
			<RepeatableHeader
				field={field}
				filteredCount={filteredItems.length}
				totalCount={extendedItems.length}
				setFilterModalIsOpen={setFilterModalIsOpen}
			/>
			<IonContent forceOverscroll={false}>
				<Virtuoso
					style={{ flex: 1 }}
					data={filteredItems}
					increaseViewportBy={800}
					itemContent={(_, { index, title, subtitle, iconColor, iconSrc, item }) => {
						return (
							<div style={{ height: "3rem", minHeight: "3rem" }}>
								<RepeatableItem
									id={item.id as string}
									idx={index}
									title={title}
									subtitle={subtitle}
									iconColor={iconColor}
									iconSrc={iconSrc}
									field={field}
									formMethods={formMethods}
									key={`${field.name}.${item.id}`}
									setDeleteOpenModalId={setDeleteModalOpenId}
									handleDuplicateItem={handleDuplicateItem}
									isFrozen={isFrozen}
								/>
							</div>
						);
					}}
				/>
			</IonContent>
			<DeleteItemModal
				openId={deleteModalOpenId}
				onClose={() => setDeleteModalOpenId(undefined)}
				handleDelete={handleDeleteItem}
			/>
		</>
	);
};

const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(RepeatableTable, propsAreEqual);
export default Memoized;
