import { apiClient } from "api";
import { FormDeliveryStatus } from "interfaces/FormDeliveryStatus";

import IWithPagination from "../interfaces/IWithPagination";
import FormRecord, { IRawFormRecord } from "../models/FormRecord";

const recursiveGetRecords = async function* (projectRefs?: string[], assetIds?: string[], lastSyncDate?: string) {
	let responseLength = 0;
	let perPage = 0;
	const params: {
		page: number;
		projectRef?: string;
		assetId?: string;
		includeDeleted: boolean;
		fromDateSynced?: string;
	} = {
		page: 1,
		includeDeleted: true,
	};
	if (projectRefs) params["projectRef"] = projectRefs.join(",");
	if (assetIds) params["assetId"] = assetIds?.join(",");
	if (lastSyncDate) params["fromDateSynced"] = lastSyncDate;
	while (perPage === responseLength) {
		const res = await apiClient
			.get<IWithPagination<IRawFormRecord>>(`forms/records`, {
				params,
			})
			.then((res) => res.data);
		yield res.data;
		perPage = res.pagination.perPage;
		responseLength = res.data.length;
		params.page += 1;
	}
	return;
};

export const getRecords = async (projectRef?: string[], assetIds?: string[], lastSyncDate?: string) => {
	const data: IRawFormRecord[] = [];
	for await (const page of recursiveGetRecords(projectRef, assetIds, lastSyncDate)) {
		data.push(...page);
	}
	return data.map((i) => new FormRecord(i));
};

export const getRecordById = async (recordId: string) => {
	const params = { recordId: recordId, includeDeleted: true };

	const res = await apiClient.get<IWithPagination<IRawFormRecord>>(`forms/records`, {
		params,
	});
	if (res.data.data.length !== 1) {
		throw Error("Get record by id failed.");
	}
	return new FormRecord(res.data.data[0]);
};

export interface IRecordSummary {
	id: string;
	project_ref: string;
	asset_id: string;
	form_id: string;
	date_synced: string; //Date;
	is_deleted: boolean;
	delivery_status: FormDeliveryStatus;
}

export interface GetSummariesProps {
	projectRefs?: string[];
	assetIds?: string[];
	excludeProjectRefs?: string[];
	excludeAssetIds?: string[];
	pages?: number;
}

const recursiveGetRecordSummaries = async function* (props: GetSummariesProps) {
	const { projectRefs, assetIds, excludeProjectRefs, excludeAssetIds, pages } = props;

	let responseLength = 0;
	let perPage = 0;
	const params: {
		page: number;
		projectRef?: string;
		assetId?: string;
		excludeProjectRef?: string;
		excludeAssetId?: string;
		includeDeleted: boolean;
		fromDateSynced?: string;
	} = {
		page: 1,
		includeDeleted: true,
	};
	if (projectRefs) params["projectRef"] = projectRefs.join(",");
	if (assetIds) params["assetId"] = assetIds.join(",");
	if (excludeProjectRefs) params["excludeProjectRef"] = excludeProjectRefs.join(",");
	if (excludeAssetIds) params["excludeAssetId"] = excludeAssetIds.join(",");
	while (perPage === responseLength) {
		const res = await apiClient
			.get<IWithPagination<IRecordSummary>>(`forms/records/summary`, {
				params,
			})
			.then((res) => res.data);
		yield res.data;
		if (params.page === pages) return;
		perPage = res.pagination.perPage;
		responseLength = res.data.length;
		params.page += 1;
	}
	return;
};

export const getRecordSummaries = async (props: GetSummariesProps) => {
	const data: IRecordSummary[] = [];
	for await (const page of recursiveGetRecordSummaries(props)) {
		data.push(...page);
	}
	return data;
};

export const putRecords = (records: FormRecord[]) => apiClient.put<FormRecord[]>(`forms/records`, records);

interface updateStatusInput {
	id: string;
	status: FormDeliveryStatus;
}
export const updateStatus = (input: updateStatusInput[]) => apiClient.put(`forms/records/status`, input);
