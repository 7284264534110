import React, { memo, PropsWithChildren } from "react";

import { IonIcon, IonInput, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";
import { Controller, UseFormReturn } from "react-hook-form";

import { FormField } from "../../../../models/Form";
import { FormValues } from "../../../../models/FormRecord";
import useFieldTemplate from "../hooks/useFieldTemplate";
import { alertCircleOutline, informationCircleOutline } from "ionicons/icons";
import { Divider } from "../components/Divider";
import flattenObject from "utils/recursive/flattenObject";

interface IProps {
	field: FormField;
	formMethods: UseFormReturn<FormValues>;
}

type PropsType = IProps;

function dependenciesAreDirty(dependencies: string[], dirtyFields: string[]): boolean {
	return dirtyFields.some((it) => dependencies.includes(it.split(".").slice(-1)[0]));
}

const Calculation: React.FC<PropsType> = (props) => {
	const { field, formMethods } = props;
	const { name, alert, isRelevant, isRequired, valueCalculated, isHardRequired } = useFieldTemplate(
		field,
		formMethods.control,
	);

	const dirtyFields = Object.keys(flattenObject(formMethods.formState.dirtyFields));

	return (
		<div style={{ display: isRelevant() ? undefined : "none" }}>
			<Controller
				name={name}
				control={formMethods.control}
				rules={{
					required: isHardRequired() && isRelevant(),
					// validate: isValid,
				}}
				render={({ field: fieldRenderProps }) => {
					if (valueCalculated !== fieldRenderProps.value && dependenciesAreDirty(field.dependsOn || [], dirtyFields)) {
						fieldRenderProps.onChange(valueCalculated);
					}
					return (
						<IonItem
							lines="none"
							style={{
								backgroundColor: "var(--ion-background-color)",
							}}
						>
							<IonLabel className="ion-text-wrap" position="stacked" mode="ios" color="medium">
								{field.label}
								{(isRequired() || isHardRequired()) && <span style={{ color: "red" }}>&nbsp;*</span>}
							</IonLabel>
							<IonInput
								aria-label={field.label}
								data-testid={`${field.type}:input`}
								// React Hook Form Managed
								ref={fieldRenderProps.ref}
								name={fieldRenderProps.name}
								value={valueCalculated as string}
								style={{ color: "var(--ion-color-medium)" }}
								readonly
							/>
							<Divider color="var(--ion-color-medium)" />
							{alert && (
								<IonNote
									data-testid={`${field.type}:note`}
									color={field.alertColor}
									style={{
										display: "flex",
										gap: ".25rem",
										alignItems: "center",
										textAlign: "center",
										margin: ".25rem 0",
									}}
								>
									<IonIcon
										icon={
											field.alertIcon === "informationCircleOutline" ? informationCircleOutline : alertCircleOutline
										}
										color={field.alertColor}
										size="small"
									/>
									<IonText style={{ textAlign: "start", lineHeight: 1 }}>{alert}</IonText>
								</IonNote>
							)}
						</IonItem>
					);
				}}
			/>
		</div>
	);
};
const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field.name === nextProps.field.name;
const Memoized = memo(Calculation, propsAreEqual);
export default Memoized;
