import FormRecord from "models/FormRecord";
import LocalFile from "models/LocalFile";
import LocalImage from "models/LocalImage";

export const jsonSize = (obj: unknown) => {
	const bytes = new TextEncoder().encode(JSON.stringify(obj)).length;
	return bytes;
};

export const getCacheSize = async (projectRef: string, assetId?: string) => {
	const cache = await caches.open("dhub-forms-images");
	const keys = await cache.keys();
	const sizes = await Promise.all(
		keys
			.filter((key) => key.url.includes(assetId ? `thumbnails/${projectRef}/${assetId}/` : `thumbnails/${projectRef}/`))
			.map(async (key) => {
				const response = await cache.match(key);
				if (!response) return 0;
				const blob = await response.blob();
				return blob.size;
			}),
	);
	return sizes.reduce((a, b) => a + b, 0);
};

export const getIdbSize = async (projectRef: string, assetId?: string) => {
	const records = await (assetId
		? FormRecord.byAssetId(assetId, { includeDeleted: true, includeInactive: true })
		: FormRecord.byProjectRef(projectRef, { includeDeleted: true, includeInactive: true }));
	const localPhotos = (await Promise.all(records.map((rec) => LocalImage.byRecordId(rec.id)))).flat();
	const localFiles = (await Promise.all(records.map((rec) => LocalFile.byRecordId(rec.id)))).flat();

	const recordsSize = jsonSize(records);
	const photosSize = localPhotos.map((it) => it.size || 0).reduce((a, b) => a + b, 0);
	const filesSize = localFiles.map((it) => it.size || 0).reduce((a, b) => a + b, 0);

	return recordsSize + photosSize + filesSize;
};

export const estimatedThumbnailSize = 250 * 1024;
