import { uploadFiles } from "api/files";
import { uploadImages } from "api/images";
import { putRecords } from "api/records";
import FormRecord from "models/FormRecord";
import LocalFile from "models/LocalFile";
import LocalImage from "models/LocalImage";

import { getAllPendingDownload } from "./pendingDownload";
import { jsonSize } from "./size";
import { cacheFloorplans } from "./cacheFloorplans";
import { updatePhotoCache } from "./updatePhotoCache";

const syncFunction = async (
	authorization: string,
	recordsToUpload: FormRecord[],
	photosToUpload: LocalImage[],
	filesToUpload: LocalFile[],
	projectRef: string,
	assetId?: string,
	fetchImages = false,
	sizeEstimation = 1,
	setProgress?: React.Dispatch<React.SetStateAction<number>>,
) => {
	console.log("Synchronizing...");
	try {
		const makeProgress = (bytes: number) => setProgress && setProgress((p) => p + bytes / sizeEstimation);
		// Upload pictures first
		setProgress && setProgress(0);
		await uploadImages(photosToUpload, makeProgress); // Progress from 0 to 50
		await uploadFiles(filesToUpload, makeProgress).then(() => {
			filesToUpload.forEach((file) => file.delete());
		});

		// Then submit records
		console.info("Publishing records:\n", ...recordsToUpload.map((it) => ({ asset_id: it.asset_id, id: it.id })));
		await putRecords(recordsToUpload).then(() => {
			makeProgress(jsonSize(recordsToUpload));
		});

		// Last, re-query for new records as they were merge
		const { records: downloadedRecords, sizes: downloadedSizes } = await getAllPendingDownload(projectRef, assetId);
		console.info(
			"Downloaded remote records:\n",
			downloadedRecords.map((it) => ({ asset_id: it.asset_id, id: it.id })),
		);
		makeProgress(downloadedSizes.data);
		await FormRecord.setMany(downloadedRecords);

		// Cache files we just uploaded
		// TODO: We don't have a good fallback atm to know that a thumbnail is not yet ready,
		// Since the Error Handling in CloudFront returns 200 index.html when it should be a 404
		// in the event that the thumbnail has not yet been generated.
		// In the meantime, we are going to grant a grace period of 2sec for thumbnails to be generated
		// (We know it takes 250ms on average).
		// Ideally we would tell that thumbnail is not available and fall back to caching the image instead
		// in cachePhotosFromIssues
		if (fetchImages) {
			const allRecords = await (assetId ? FormRecord.byAssetId(assetId) : FormRecord.byProjectRef(projectRef));
			await new Promise((resolve) => setTimeout(resolve, 2e3));
			await updatePhotoCache(allRecords, authorization, makeProgress);
			setProgress && setProgress(1); // just in case the Math went wrong, normally with errors
		}

		await cacheFloorplans(assetId, projectRef, authorization);

		console.log("Synchronized");
	} catch (err) {
		console.error(err);
	}
};

export default syncFunction;
