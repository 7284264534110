import React, { useEffect, useState } from "react";

import { modalController } from "@ionic/core";
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { twoSquares } from "assets/icons";
import Header from "components/common/Header";
import Project from "models/Project";
import { useAppDispatch, useAppSelector } from "store";
import { assetFilterSlice } from "store/slices/assetFilter/assetFilterSlice";

const ProjectsView: React.FC = () => {
	const [projects, setProjects] = useState<Project[]>([]);
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const remoteSlice = useAppSelector((state) => state.remote);
	const dispatch = useAppDispatch();

	// Handle modals
	useEffect(() => {
		const dismissModals = async () => {
			const open = await modalController.getTop();
			if (open !== undefined) {
				modalController.dismiss();
			}
		};
		return () => {
			dismissModals();
		};
	}, []);

	// Single time operations on load
	useEffect(() => {
		dispatch(assetFilterSlice.actions.reset());
	}, []);

	// Initialize
	useEffect(() => {
		let isCancelled = false;

		const initialize = async () => {
			const projects = await Project.getAll();
			if (projects.length === 1) history.push(projects[0].ref);
			if (isCancelled) return;
			setProjects(projects);
		};
		initialize();
		return () => {
			isCancelled = true;
		};
	}, [remoteSlice]);

	return (
		<IonPage>
			<Header
				title={
					<React.Fragment>
						<IonLabel
							className="dHubHeader"
							style={{
								color: "var(--ion-color-primary)",
								paddingLeft: "1.5rem",
							}}
						>
							d.Hub&nbsp;
						</IonLabel>
						<IonLabel className="dHubHeader">Forms</IonLabel>
					</React.Fragment>
				}
			/>
			<IonContent forceOverscroll={false}>
				<IonList>
					<IonListHeader lines="full">
						<IonLabel>{i18n.format(t("projects"), "capitalize")}</IonLabel>
					</IonListHeader>
					{projects.length === 0 ? (
						<IonItem lines="full">
							<IonText style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
								{i18n.format(t("sync_projects_msg"), "capitalize")}
							</IonText>
						</IonItem>
					) : (
						projects.map((project) => {
							return (
								<IonItem
									key={project.ref}
									lines="full"
									routerLink={`/${project.ref}`}
									routerDirection="forward"
									button={true}
									detail={true}
								>
									<IonLabel
										className="ion-text-wrap"
										data-testid="projectLabel"
										style={{ display: "flex", alignItems: "center" }}
									>
										<IonIcon icon={twoSquares} />
										&#x2800; {project.name}
									</IonLabel>
								</IonItem>
							);
						})
					)}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default ProjectsView;
