export const groupStyles = (
	variant: "dark" | "gray" | "white" | "red",
): {
	itemStyle: Record<string, string>;
	chevronIconStyle: Record<string, string>;
	statusIconStyle: Record<string, string>;
} => {
	switch (variant) {
		case "dark": {
			const chevronColor = "#fff";
			return {
				itemStyle: {
					color: "white",
					backgroundColor: "var(--ion-color-dark)",
					"--color": "white",
					"--background": "var(--ion-color-dark)",
					"--color-hover": "#ffffff",
					"--ion-text-color-rgb": chevronColor,
				},
				chevronIconStyle: { color: "chevronColor" },
				statusIconStyle: { color: "#718096" },
			};
		}
		case "gray": {
			return {
				itemStyle: {
					color: "black",
					backgroundColor: "var(--ion-color-secondary)",
					"--color": "var(--ion-color-secondary)",
					"--background": "var(--ion-color-secondary)",
					"--color-hover": "#000000",
					"--ion-text-color-rgb": "#4A5568",
				},
				chevronIconStyle: { color: "black" },
				statusIconStyle: { color: "#CBD5E0" },
			};
		}
		case "white": {
			const chevronColor = "#2D3748";
			return {
				itemStyle: {
					color: "black",
					backgroundColor: "var(--ion-background-color)",
					"--background": "var(--ion-background-color)",
					"--color-hover": "#000000",
					"--ion-text-color-rgb": chevronColor,
				},
				chevronIconStyle: { color: chevronColor },
				statusIconStyle: { color: "#CBD5E0" },
			};
		}
		case "red": {
			const chevronColor = "#FFEEEF99";
			return {
				itemStyle: {
					color: "red",
					backgroundColor: "#FFEEEF99",
					"--background": "#FFEEEF99",
					"--color-hover": "#FFEEEF99",
					"--ion-text-color-rgb": chevronColor,
				},
				chevronIconStyle: { color: chevronColor },
				statusIconStyle: { color: "#FFEEEF99" },
			};
		}
	}
};
