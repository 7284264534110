import { apiClient } from "api";

import IWithPagination from "../interfaces/IWithPagination";
import Project, { IRawProject, IConfiguration } from "../models/Project";

const getPageByPage = async function* (projectRefs?: string[]) {
	let responseLength = 0;
	let perPage = 0;
	const params = {
		page: 1,
		moduleRef: "forms",
		excludeModuleRefs: "",
		...(projectRefs && { projectRef: projectRefs.join(",") }),
	};

	while (perPage === responseLength) {
		const projects = await apiClient
			.get<IWithPagination<Omit<IRawProject, "configuration">>>(`conf/projects/myprojects`, {
				params,
			})
			.then((res) => res.data);
		const withConf = await Promise.all(
			projects.data.map(async (prj) => {
				const conf = await apiClient
					.get<IConfiguration>(`forms/configurations/${prj.ref}/`)
					.then((res) => res.data)
					.catch((err) => {
						console.log(err);
						return undefined;
					});
				const tecnicos = await apiClient
					.get<string[]>(`ite/tecnicos/${prj.ref}/`)
					.then((res) => res.data)
					.catch((err) => {
						console.log(err);
						return undefined;
					});
				return { ...prj, configuration: conf, tecnicos };
			}),
		);
		yield withConf.filter((prj): prj is IRawProject => !!prj.configuration);
		perPage = projects.pagination.perPage;
		responseLength = projects.data.length;
		params.page += 1;
	}
	return;
};

export const getProjects = async (projectRefs?: string[]) => {
	const data: IRawProject[] = [];
	for await (const page of getPageByPage(projectRefs)) {
		data.push(...page);
	}
	return data.map((i) => new Project(i));
};
