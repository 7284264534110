import { getRecords } from "../../api/records";
import FormRecord from "../../models/FormRecord";
import { getAllLocalAndRemotePhotosInRecord } from "../photo/photoUtils";
import { estimatedThumbnailSize, jsonSize } from "./size";

export const getAllMissingThumbnails = async (records: FormRecord[]) => {
	const cache = await caches.open("dhub-forms-images");
	const cachedRequests = await cache.keys();
	const recordImages = await Promise.all(records.filter((r) => !r.is_deleted).map(getAllLocalAndRemotePhotosInRecord));
	const missingThumbnails = recordImages
		.flat()
		.filter((ph) => !cachedRequests.some((r) => r.url.includes(ph.filepath)))
		.map((ph) => ({ ...ph, size: estimatedThumbnailSize }));
	return missingThumbnails;
};

export const getAllPendingDownload = async (projectRef: string, assetId?: string) => {
	const allLocalRecords = await (assetId
		? FormRecord.byAssetId(assetId, { includeDeleted: true, includeInactive: true })
		: FormRecord.byProjectRef(projectRef, { includeDeleted: true, includeInactive: true }));

	const localAssetIds = [...new Set(allLocalRecords.map((r) => r.asset_id))];

	const remoteRecords: FormRecord[] = assetId
		? await getRecords([projectRef], [assetId])
		: await getRecords([projectRef], localAssetIds);
	const recordsToDownload = remoteRecords.filter(
		(remoteRecord) => !remoteRecord.is_deleted || allLocalRecords.map((rec) => rec.id).includes(remoteRecord.id),
	);
	const upToDateRecords = allLocalRecords.filter((it) => !recordsToDownload.map((r) => r.id).includes(it.id));

	const missingThumbnails = await getAllMissingThumbnails([...recordsToDownload, ...upToDateRecords]);

	const dataSize = jsonSize(recordsToDownload);
	const photoSize = missingThumbnails.reduce((p, c) => p + (c.size || 0), 0);

	return {
		records: recordsToDownload,
		photos: missingThumbnails,
		sizes: { data: dataSize, photos: photoSize, files: 0 },
	};
};
