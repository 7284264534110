import React from "react";

import { IonModal, IonIcon, IonButton, IonContent } from "@ionic/react";
import { close } from "ionicons/icons";
import { ReactSketchCanvas, ReactSketchCanvasProps, ReactSketchCanvasRef } from "react-sketch-canvas";

import { useAppSelector } from "store";

import { IImage } from "../../../../../interfaces/IImage";
import LocalImage from "../../../../../models/LocalImage";
import { useTranslation } from "react-i18next";
import buildFilePath from "../../utils/buildFilePath";

interface IProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setOnSave: React.Dispatch<React.SetStateAction<IImage | undefined>>;
}

const SketchCanvas: React.FC<IProps> = (props) => {
	const { open, setOpen, setOnSave } = props;
	const { t, i18n } = useTranslation();
	const { projectRef, assetId, recordId } = useAppSelector((state) => state.form);
	const historySlice = useAppSelector((store) => store.history);

	const [canvasProps, setCanvasProps] = React.useState<Partial<ReactSketchCanvasProps>>({
		className: "react-sketch-canvas",
		width: "100%",
		height: "500px",
		preserveBackgroundImageAspectRatio: "none",
		strokeWidth: 4,
		eraserWidth: 5,
		strokeColor: "#000000",
		canvasColor: "#FFFFFF",
		style: { borderRight: "1px solid #CCC" },
		exportWithBackgroundImage: true,
		withTimestamp: true,
		allowOnlyPointerType: "all",
	});

	const canvasRef = React.createRef<ReactSketchCanvasRef>();

	const clearHandler = () => {
		const clearCanvas = canvasRef.current?.clearCanvas;
		if (clearCanvas) {
			clearCanvas();
		}
	};

	const undoHandler = () => {
		const undo = canvasRef.current?.undo;

		if (undo) {
			undo();
		}
	};

	const redoHandler = () => {
		const redo = canvasRef.current?.redo;

		if (redo) {
			redo();
		}
	};

	const penHandler = () => {
		const eraseMode = canvasRef.current?.eraseMode;

		if (eraseMode) {
			eraseMode(false);
		}
	};

	const eraserHandler = () => {
		const eraseMode = canvasRef.current?.eraseMode;

		if (eraseMode) {
			eraseMode(true);
		}
	};

	const saveHandler = async () => {
		const canvas = canvasRef.current;
		if (!canvas) return;
		const filepath = buildFilePath(projectRef, assetId, recordId, 0, "png");
		const [project_ref, asset_id, record_id, filename] = filepath.split("/");
		if (!filename) throw new Error(`Invalid filepath ${filepath}`);
		const png = await canvas.exportImage("png").then(async (base64Url) => {
			const blob = await fetch(base64Url).then((res) => res.blob());
			return new LocalImage({
				filepath,
				formpath: historySlice.absolutePath,
				file: new File([blob], "drawing.png"),
				size: blob.size,
				project_ref,
				asset_id,
				record_id,
			});
		});
		await png.save();
		setOnSave({
			filepath,
			formpath: historySlice.absolutePath,
		});
		setOpen(false);
	};

	const dismissHandler = () => {
		setOpen(false);
	};

	return (
		<IonModal
			isOpen={open}
			canDismiss={true}
			backdropDismiss={true}
			onDidDismiss={dismissHandler}
			onWillDismiss={dismissHandler}
		>
			<IonButton
				// id="iconButton"
				fill="clear"
				onClick={dismissHandler}
				style={{
					width: "4rem",
					marginLeft: "auto",
					zIndex: 100,
				}}
			>
				<IonIcon
					src={close}
					style={{
						fontSize: "2.5rem",
						color: "var(--ion-color-primary)",
					}}
				/>
			</IonButton>
			<IonContent  forceOverscroll={false}>
				<div>
					<ReactSketchCanvas ref={canvasRef} {...canvasProps} />
					<button>{i18n.format(t("get_img"), "capitalizeEveryWord")}</button>
					<div>
						<label htmlFor="strokeColorInput" className="form-label">
							strokeColor
						</label>
						<input
							type="color"
							name="strokeColor"
							className="form-control form-control-color"
							id="strokeColorInput"
							value={canvasProps.strokeColor}
							title="Choose stroke color"
							onChange={(e) => {
								setCanvasProps((prevCanvasProps: Partial<ReactSketchCanvasProps>) => ({
									...prevCanvasProps,
									strokeColor: e.target.value,
								}));
							}}
						></input>
						<IonButton onClick={clearHandler}>{i18n.format(t("clear_canvas"), "capitalize")}</IonButton>
						<IonButton onClick={undoHandler}>{i18n.format(t("undo"), "capitalize")}</IonButton>
						<IonButton onClick={redoHandler}>{i18n.format(t("redo"), "capitalize")}</IonButton>
						<IonButton onClick={penHandler}>{i18n.format(t("pen"), "capitalize")}</IonButton>
						<IonButton onClick={eraserHandler}>{i18n.format(t("erase"), "capitalize")}</IonButton>
						<IonButton onClick={saveHandler}>{i18n.format(t("save"), "capitalize")}</IonButton>
						<IonButton onClick={dismissHandler}>{i18n.format(t("close"), "capitalize")}</IonButton>
					</div>
				</div>
			</IonContent>
		</IonModal>
	);
};
export default SketchCanvas;
