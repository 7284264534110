import FormRecord from "models/FormRecord";
import { evictUrlFromCache } from "utils/imageCache/evictUrlFromCache";
import { getSignedUrl } from "utils/sync/getSignedUrl";
import { getAllLocalAndRemotePhotosInRecord } from "../photo/photoUtils";
import { getAllMissingThumbnails } from "./pendingDownload";

export const updatePhotoCache = async (
	records: FormRecord[],
	authorization: string,
	makeProgress?: (bytes: number) => void,
) => {
	console.log("Updating photo cache");
	const cache = await caches.open("dhub-forms-images");
	// Delete from cache all deleted issues
	const recordPhotos = await Promise.all(records.filter((r) => r.is_deleted).map(getAllLocalAndRemotePhotosInRecord));
	recordPhotos.flat().map((ph) => evictUrlFromCache(ph.filepath, authorization, cache));
	// Add existing record photos to cache
	// New records have just been loaded, so missing thumbnails already accounts for those
	const missingThumbnails = await getAllMissingThumbnails(records);
	console.log("Trying to save in cache:", missingThumbnails);
	const filepathsWithNoThumbnail: string[] = await cacheThumbnailImages(
		missingThumbnails.map((ph) => ph.filepath),
		authorization,
	);
	await cacheRegularImages(filepathsWithNoThumbnail, authorization);
	console.log("Done saving in cache");
	makeProgress && makeProgress(1);
};

async function cacheThumbnailImages(filepaths: string[], authorization: string) {
	console.log("Trying to cache thumbnails:", filepaths);
	if (filepaths.length === 0) return [];
	const promisesForThumbnailSignedUrls = getSignedUrl(
		filepaths.map((it) => ({
			filepath: it,
			thumbnail: true,
			type: "image",
		})),
		authorization,
	);
	const signedUrls = await promisesForThumbnailSignedUrls;
	const filepathsWithNoThumbnail: string[] = [];
	const cache = await caches.open("dhub-forms-images");
	for (const key in signedUrls) {
		const currentSignedUrl = signedUrls[key];
		if (currentSignedUrl) {
			const requestToS3 = fetch(currentSignedUrl);
			requestToS3.then((response) => {
				cache.put(`thumbnails/${key}`, response);
			});
		} else {
			filepathsWithNoThumbnail.push(key);
		}
	}
	return filepathsWithNoThumbnail;
}

async function cacheRegularImages(filepathsWithNoThumbnail: string[], authorization: string) {
	console.log("Trying to cache regular images:", filepathsWithNoThumbnail, filepathsWithNoThumbnail.length);
	if (filepathsWithNoThumbnail.length === 0) return;
	const promisesForSignedUrls = getSignedUrl(
		filepathsWithNoThumbnail.map((it) => ({
			filepath: it,
			thumbnail: false,
			type: "image",
		})),
		authorization,
	);
	const signedUrls = await promisesForSignedUrls;
	const cache = await caches.open("dhub-forms-images");
	for (const key in signedUrls) {
		const currentSignedUrl = signedUrls[key];
		if (currentSignedUrl) {
			const requestToS3 = fetch(currentSignedUrl);
			requestToS3.then((response) => {
				cache.put(`images/${key}`, response);
			});
		}
	}
}
